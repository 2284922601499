const validate = (value) => value
  ? ''
  : 'Поле обязательно для заполнения!'

export default ({
  agentCompanyId,
  companyId,
}) => {
  const companyErrors = validate(agentCompanyId)
  const agentErrors = validate(companyId)
  return Object.assign(
    {},
    companyErrors ? { agentCompanyId: companyErrors } : {},
    agentErrors ? { companyId: agentErrors } : {},
  )
}
