import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import Content from './../Content'
import Icon from './../../../layout/Icons'
import { adaptive_1200 } from '../../../config'

const styles = {
  root: {
    marginBottom: 20,
    padding: [16, 0],
    borderBottom: [1, 'solid', '#eee'],
    [adaptive_1200]: {
      padding: [9, 0],
    },
  },
  title: {
    fontSize: 20,
    lineHeight: 1.6,
    fontWeight: 600,
    position: 'relative',
    [adaptive_1200]: {
      fontSize: 16,
    },
    '&.icon': {
      paddingLeft: 44,
    }
  },
  icon: {
    width: 24,
    height: 24,
    display: 'block',
    position: 'absolute',
    left: 0,
    top: 4,
  },
}

const Head = ({ 
  title,
  iconName,
  className, 
  classes, 
}) =>
  <div className={classes.root}>
    <Content>
      <div className={classnames(classes.title, { 'icon': iconName })}>
        {iconName && 
          <Icon className={classnames(classes.icon, className)} iconName={iconName} />
        }
        {title}
      </div>
    </Content>
  </div>

export default injectSheet(styles)(Head)