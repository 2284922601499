import React from 'react';
import cx from 'classnames';

const EmptyTasksList = () => {
    return (
        <div className={cx('block-list block-list--tasks block-list--empty')}>
            <div className={cx('empty-message')}>
                <span className={cx('empty-message__icon')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="45" viewBox="0 0 46 45">
                        <path fill="#E3E3E3" fillRule="nonzero" d="M23 .321C13.263.321 4.987 6.656 2.107 15.43a1.714 1.714 0 0 0-.75 3.107 22.374 22.374 0 0 0-.321 3.75C1.036 34.413 10.873 44.25 23 44.25c12.127 0 21.964-9.837 21.964-21.964 0-1.273-.114-2.532-.321-3.75a1.714 1.714 0 0 0-.75-3.107C41.013 6.656 32.737.32 23 .32zm0 3.429c8.23 0 15.208 5.37 17.625 12.804-.71.194-1.507.341-2.25.428-.188-1.453-1.433-1.553-7.66-1.553-2.331 0-3.878.16-4.822.75a8.276 8.276 0 0 0-2.947-.536c-1.01 0-1.988.154-2.892.482-.945-.556-2.498-.696-4.768-.696-6.228 0-7.473.1-7.661 1.553a15.702 15.702 0 0 1-2.25-.428C7.792 9.12 14.77 3.75 23 3.75zm-.054 14.946c.65 0 1.233.08 1.768.268.047 4.996 3.188 6.59 6 6.59 2.337 0 6.04-1.052 7.286-5.09.14-.013.228.014.375 0 .93-.093 1.969-.274 3-.535.1.776.16 1.553.16 2.357C41.536 32.504 33.22 40.82 23 40.82S4.464 32.504 4.464 22.286c0-.804.06-1.58.161-2.357 1.031.26 2.07.442 3 .535.147.014.234-.013.375 0 1.246 4.038 4.949 5.09 7.286 5.09 2.812 0 5.953-1.594 6-6.59a5.264 5.264 0 0 1 1.66-.268zm11.947 8.036C31.685 31.28 28.25 31.018 23 31.018c-3.455 0-6.536-1.607-6.536.375 0 2.33 3.054 2.625 6.536 2.625 6.074 0 10.185-2.022 11.893-7.286z" />
                    </svg>
                </span>
                <span className={cx('empty-message__title')}>Список клиентов пуст</span>
                <p>Похоже у вас нет ни одного клиента!</p>
            </div>
        </div>
    );
};

export default EmptyTasksList;
