import React, { Component } from 'react'
import { connect } from 'react-redux'
import { debounce } from 'debounce'
import injectSheet from 'react-jss'
import ClearButton from '../ClearButton'
import ListItem from './blocks/ListItem'
import { searchByString, clearSearchResults } from '../../redux/Search/actions'
import { Label, Error } from '../../layout'

const styles = {
  textFieldWithAutoComplete: {
    '& .error': {
      borderColor: 'red',
    },
  },
  input: {
    '&:disabled': {
      background: 'rgba(11, 31, 53, 0.2) !important',
    },
  },
}

class TextFieldWithAutoComplete extends Component {
  static defaultProps = {
    placeholder: '',
    value: '',
    classNames: {
      container: '',
      input: '',
      error: ''
    },
    meta: {
      touched: false,
      error: ''
    },
    findAll: false,
  }
  
  state = {
    value: this.props.defaultValue ? this.props.defaultValue : this.props.value,
    showResult: false,
  }

  constructor(props) {
    super(props)
    this.onLoadSuggestions = debounce(this.onLoadSuggestions.bind(this), 100)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onOutsideClick)
  }
  
  onClear = () => {
    this.setState({ value: '' })
    this.props.onClear(this.props.name, '')
    this.props.dispatch(clearSearchResults())
  }
  
  onFocus = () => {
    if (!this.props.noClearOnFocus) {
      this.onClear()
    }
    document.addEventListener('click', this.onOutsideClick)
  }

  onBlur = () => {
    this.setState({ value: this.props.value || this.state.value })
  }
  
  onToggleResults = () => {
    this.props.dispatch(clearSearchResults())
    this.setState({ showResult: false })
  }
  
  onChange = (e) => {
    const { value } = e?.target || {}

    const showResult = !!value.length
    const { findAll, dispatch, request, requestPayload } = this.props
    this.setState({ value, showResult })

    if (showResult) {
      this.onLoadSuggestions(value, findAll, request, requestPayload)
    } else {
      dispatch(clearSearchResults())
    }
  }

  onLoadSuggestions = (value, findAll, request, requestPayload) => this.props.dispatch(searchByString(value, findAll, request, requestPayload))
  
  onSelectItem = (payload, text) => {
    this.setState({
      value: text,
      showResult: false,
    })
    this.props.onSelect(payload, this.props.name)
  }
  
  onOutsideClick = ({ target }) => {
    if (this.textField?.contains(target)) {
      return
    }
    if (this.props.value && !this.state.value) {
      this.onClearField()
    } else {
      this.onToggleResults()
    }
  }
  
  renderSearchResults() {
    const {
      list,
      withoutLink,
      payloadKeys = ['id'],
      requestKey = 'displayName',
      requestSubKeys = ['INN'],
    } = this.props
    const { showResult, value } = this.state
    
    if (!(list?.length && showResult)) {
      return null
    }
    return (
      <div className='dropdown-menu show'>
        {list.map((item, index) =>
          <ListItem
            key={index}
            data={
              payloadKeys.reduce((result, i) => {
                result[i] = item[i]
                return result
              }, {})
            }
            id={item.id}
            text={item[requestKey]}
            textToHighlight={
              requestSubKeys
                .map(key => typeof key === 'object'
                  ? key?.key?.toLowerCase().includes('date')
                    ? `${key?.name} ${new Date(item[key?.key]).toLocaleDateString('ru-RU')}`
                    : `${key?.name} ${item[key?.key]}`
                  : key.toLowerCase().includes('inn')
                    ? `ИНН ${item[key]}`
                    : key.toLowerCase().includes('date')
                      ? new Date(item[key]).toLocaleDateString('ru-RU')
                      : item[key]
                )
                .join(', ')
            }
            searchQuery={value}
            onClick={this.onSelectItem}
            withoutLink={withoutLink}
          />
        )}
      </div>
    )
  }

//   static getDerivedStateFromProps(nextProps, prevState){
//     if (nextProps.defaultValue && !prevState.value) {
//        this.setState({ value: nextProps.defaultValue })
//    }
// }
  
  render() {
    const {
      name,
      label,
      placeholder,
      classNames,
      meta,
      icon,
      error,
    } = this.props

    const { value } = this.state

    const _error = (meta?.touched && meta?.error) || error
    
    return (
      <div className={classNames.container} ref={node => { this.textField = node }}>
        {label &&
          <Label htmlFor={name}>{label}</Label>
        }
        <div style={{ position: 'relative' }}>
          {icon && (
            <span className={`icon ${icon}`} />
          )}
          <input
            type='text'
            autoComplete='off'
            className={classNames.input}
            placeholder={placeholder}
            name={name}
            value={value}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={this.onChange}
          />
          <ClearButton onClear={this.onClear} isHidden={!value.length} />
          {this.renderSearchResults()}
        </div>
        {_error &&
          <Error>{_error}</Error>
        }
      </div>
    )
  }
}

const mapStateToProp = ({ Search }) => {
  return {
    list: Search.list,
    isFetching: Search.isFetching,
  }
}

export default connect(mapStateToProp)(injectSheet(styles)(TextFieldWithAutoComplete))