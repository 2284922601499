import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  title: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 36,
    lineHeight: 48/36,
    color: '#0B1F35',
  },
})

export const Title = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.title}>{children}</div>
  )
}