import React from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'
import Overlay from '../../../Overlay'

const useStyles = createUseStyles({
  container: {
    marginTop: 20,
  },
  title: {
    fontSize: 24,
    marginBottom: 24,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  actions: {
    marginTop: 30,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    '& > button': {
      flex: 0,
    },
    '@media (max-width: 500px)': {
      flexDirection: 'column-reverse',
      '& > *': {
        marginLeft: '0 !important',
        minHeight: 35,
      },
      '& > div': {
        marginTop: 10,
      },
    },
  },
  add: {
    minWidth: 200,
    boxSizing: 'border-box',
    position: 'relative',
    '&:hover': {
      opacity: 0.8,
    },
    '&.loading': {
      cursor: 'default',
      '&:hover': {
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
      },
    },
    '& > div': {
      borderRadius: 'inherit',
    },
  },
})

export const ReportTab = ({
  children,
  onOk,
  onCancel,
  okButtonText,
  disabled,
  loading,
  title,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{title}</h1>
      {children}
      <div className={classes.actions}>
        <div className='btn-form btn-form--none' onClick={onCancel}>Отменить</div>
        <button
          type='button'
          className={classnames(classes.add, 'btn-form', 'btn-form--ok', { loading })}
          disabled={disabled}
          onClick={onOk}
        >
          {loading
            ? <Overlay size='small' />
            : okButtonText
          }
        </button>
      </div>
    </div>
  )
}