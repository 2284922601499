import React from 'react'
import { useSelector } from 'react-redux'
import { Modal } from '../../../layout'
import Overlay from '../../Overlay'
import { plural, formattedNumber, formattedDate } from '../../../utils'
import { SHOW_COMMENTS } from '../../../config'

const Content = () => {
  const { order, isFetching } = useSelector(state => state?.Company)
  const { isagent } = useSelector(state => state?.User)
  const {
    orderNumber = '—',
    orderTypeName,
    orderTypeRefId,
    bankguarantee = {},
    bankguaranteeReissue = {},
    bankguaranteeLimit = {}, 
    rko = {},
    principal, 
    documents = [],
    principalDocuments = [],
    comments = [],
    parameters = [],
    emailToken,
    installment,
    rkoTariff = {},
    rkoFilial = {},
    productType = {},
    initiatorChannel,
    eis,
  } = order || {}

  const {
    displayName = '—',
    INN = '—',
    KPP = '—',
    OGRN = '—',
  } = principal || {}

  const isGuarantee = orderTypeRefId === 'bankguarantee' || orderTypeRefId === 'bankguaranteeV2'
  const isReissue = orderTypeRefId === 'bankguaranteeReissue'
  const isLimits = orderTypeRefId === 'bankguaranteeLimit'
  const isRko = orderTypeRefId === 'rko'
  const isKbg = orderNumber ? orderNumber.includes("KBG") : isGuarantee

  const {
    durationDays,
    endDate,
    federalLaw,
    amount = 0,
    // finalAmount = 0,
    maxAmount = 0,
    lot = [],
    purchaseMarketPlace,
    purchaseNumber,
    purchaseSubject,
    purchaseTenderType,
    // requiredExecutionDate,
    startDate,
    type,
    calculatedAmount, 
    finalAmount,
    maxOrderAmount,
    // productRkoCity,
    productRkoEmail,
    productRkoPhone,
    // productRkoTariff,
    bankCommissionAmount,
    defaultBankCommissionAmount,
    workContractNumber,
    workContractDate,
  } = isLimits
    ? bankguaranteeLimit 
    : isReissue
      ? bankguaranteeReissue 
      : isGuarantee
        ? bankguarantee 
        : isRko
          ? rko
          : {}

  const H1 = isLimits
    ? `Заявка на лимит БГ №${orderNumber}`
    : isReissue
      ? `Перевыпуск БГ №${orderNumber}`
      : isGuarantee
        ? `Банковская гарантия №${orderNumber}`
        : isRko
          ? `${orderTypeName} №${orderNumber}`
          : '—'

  const contentRko = {
    info: [
      {
        items: [
          {
            title: 'Тариф',
            value: rkoTariff.name || '—'
          },
          {
            title: 'Продукт',
            value: productType.name || '—'
          },
          {
            title: 'Город',
            value: rkoFilial.city || '—'
          },
          {
            title: 'Офис',
            value: rkoFilial.code
              ? <span>{rkoFilial.code}<br/>{rkoFilial.address}</span>
              : '—'
          },
          {
            title: 'Канал',
            value: initiatorChannel || '—'
          }
        ]
      },
      {
        items: [
          { 
            title: 'Номер телефона', 
            value: productRkoPhone || '—', 
          },
        ]
      },
      {
        items: [
          { 
            title: 'Электронная почта', 
            value: productRkoEmail || '—', 
          },
        ]
      },
    ],
  }
  
  const content = {
    info: [
      { 
        items: [
          { 
            title: 'Вид банковской гарантии', 
            value: type || '—', 
          },
        ],
      },
      { 
        items: [
          { 
            title: 'Реестровый № торгов', 
            value: purchaseNumber || '—', 
          },
        ],
      },
       {
          items: [
                  (isKbg) && {
                      title: 'Номер договора',
                      value: workContractNumber || '-',
                  },
                  (isKbg) && {
                    title: 'Дата заключения договора',
                    value: workContractDate ? formattedDate(workContractDate) : '-',
                    right: true,
                },
            ],
      },
      { 
        items: [
          { 
            title: 'Площадка', 
            value: purchaseMarketPlace || '—', 
          }, 
          { 
            title: 'Закон', 
            value: federalLaw || '—', 
            right: true, 
          },
        ],
      },
      { 
        items: [
          { 
            title: 'Предмет конракта / предмет закупки', 
            value: purchaseSubject || '—', 
          },
        ],
      },
      { 
        items: [
          { 
            title: 
            'Тип закупки', 
            value: purchaseTenderType || '—', 
          }, 
          { 
            title: 'Предложенная цена', 
            value: finalAmount ? `${formattedNumber(finalAmount)} ₽` : '—', 
            right: true, 
          },
        ],
      },
    ],
    data: [
      { 
        title: 'Дата начала',
        value: startDate ? formattedDate(startDate) : '—', 
      },
      { 
        title: 'Дата окончания',
        value: endDate ? formattedDate(endDate) : '—', 
      },
      { 
        title: 'Срок бг',
        value: durationDays ? `${durationDays} ${plural(durationDays, 'день', 'дня', 'дней')}` : '—', 
      },
      { 
        title: 'Итого НМЦ',
        value: maxAmount ? `${formattedNumber(maxAmount)} ₽` : '—', 
      },
      { 
        title: 'Итоговая гарантия',
        value: amount ? `${formattedNumber(amount)} ₽` : '—', 
      },
      {
        title: (bankCommissionAmount && isagent) ? 'Стоимость гарантии' : '',
        value: (bankCommissionAmount && isagent) ? `${formattedNumber(bankCommissionAmount)} ₽` : '',
      },
      {
        title: (defaultBankCommissionAmount && isagent) ? 'Рассчитанная комиссия' : '',
        value: (defaultBankCommissionAmount && isagent) ? `${formattedNumber(defaultBankCommissionAmount)} ₽` : '',
      },
    ],
    lots: lot,
    eis,
    documents: documents.filter(item => item.lot),
  }

  const transactionDocument = () => documents.filter(item => !item.lot)

  const isFactoring = orderTypeRefId === 'factoring'

  return isFetching
    ? <Overlay />
    : <>
        <Modal.H1>{H1}</Modal.H1>
        <Modal.Principal
          name={displayName}
          inn={INN}
          kpp={KPP}
          ogrn={OGRN}
          isFactoring={isFactoring}
        />
        {isLimits && 
          <Modal.Limits
            calculatedAmount={calculatedAmount}
            finalAmount={finalAmount}
            maxOrderAmount={maxOrderAmount}
            endDate={endDate}
          />
        }
        {(isGuarantee || isReissue) &&
          <Modal.Info content={content} title='Сведения о закупке / контракте / заявке' />
        }
        {isRko &&
          <Modal.Info content={contentRko} title='Параметры заявки' />
        }
        {/* {(emailToken && !transactionDocument().length) &&
          <a href={`/sign-docs/?token=${emailToken}`} target='_blank' rel='noopener noreferrer'>
            Ссылка на подписание документов
          </a>
        } */}
        {(emailToken && !transactionDocument().length) &&
          <a href={`/sign-docs-V2/${emailToken}`} target='_blank' rel='noopener noreferrer'>
            Ссылка на подписание документов
          </a>
        }
        <Modal.Installment
          title='Информация о рассрочке'
          installment={installment}
        />
        <Modal.Documents
          title='Документы по сделке'
          documents={transactionDocument()}
          emailToken={emailToken}
        />
        <Modal.Documents
          title={isFactoring
            ? 'Документы поставщика'
            : 'Документы принципала'
          }
          documents={principalDocuments}
        />
        <Modal.Parameters parameters={parameters} />
        {SHOW_COMMENTS &&
          <Modal.Comments items={comments} />
        }
        {/* <Modal.Activities items={tasks} /> */}
      </>
}

export default Content