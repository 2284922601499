import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { adaptive_1200 } from '../../../config'

const styles = {
  root: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 1.8,
    [adaptive_1200]: {
      fontSize: 16,
      lineHeight: 1.5,
    },
  },
}

const Value = ({
  children,
  className,
  classes,
}) =>
  <div className={classnames(classes.root, className)}>
    {children}
  </div>

export default injectSheet(styles)(Value)
