import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import injectSheet from 'react-jss'
import { InputPhone } from '../../../components/InputPhone/InputPhone'
import TextField from '../../../components/TextField'
// import TextFieldWithAutoComplete from '../../../components/TextFieldWithAutoComplete'

const styles = {
  phone: {
    textAlign: 'center !important',
    backgroundColor: '#fff !important',
    '&:focus': {
      borderColor: '#a8a6b6 !important',
      color: '#4a4a4a !important'
    }
  },
  button: {
    borderRadius: 12,
    background: '#fff',
    width: 40,
    height: 40,
    marginLeft: 12,
    outline: 'none',
    border: 'none',
    cursor: 'pointer'
  }
}

class TasksRkoFilter extends PureComponent {
  static propTypes = {
    isDisable: PropTypes.bool,
    filters: PropTypes.object,
    filterAmount: PropTypes.shape({
      from: PropTypes.number,
      to: PropTypes.number
    }),
    onChangeFilter: PropTypes.func.isRequired
  }
  static defaultProps = { isDisable: false }

  state = {
    isFixed: false,
    phone: this.props.filters.phone || '',
    email: this.props.filters.email || '',
    inn: this.props.filters.inn || ''
    // principalCompanyId: this.props.filters.principalCompanyId || undefined
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onScroll)
  }

  getProcessesFilter(processes, orderTypeRefId = '') {
    const { filters, onChangeFilter } = this.props
    let active = 0
    const list = (processes || []).reduce(
      (result, process) => {
        if (orderTypeRefId === process.process_type) {
          active = result.length
        }
        return result.concat([
          {
            key: process.process_type,
            value: process.process_name
          }
        ])
      },
      [{ key: 'all', value: 'Все процессы' }]
    )
    if (list.length === 2) {
      active = 1
      if (filters.orderTypeRefId !== list[1].key) {
        onChangeFilter({ orderTypeRefId: list[1].key })
      }
    }
    return { active, list }
  }

  static getPhaseFilter(processes, activeProcess, phaseId = '') {
    let active = 0
    const { phases = [] } = processes[activeProcess]
    const list = phases.reduce(
      (result, phase) => {
        if (phaseId === phase.phaseId) {
          active = result.length
        }
        return result.concat([
          {
            key: phase.phaseId,
            value: phase.phaseName
          }
        ])
      },
      [{ key: 'all', value: 'Все фазы' }]
    )
    return { active, list }
  }

  onScroll = () => {
    if (!this.state.isFixed && window.pageYOffset > 0) {
      this.setState({ isFixed: true })
      return
    }
    if (this.state.isFixed && window.pageYOffset === 0) {
      this.setState({ isFixed: false })
    }
  }

  onSearchSelect = (name, value) => {
    this.setState({ [name]: value })
  }

  onChangePhone = value => {
    this.setState({ phone: value })
  }

  onChangeInn = ({ target }) => {
    this.setState({ inn: target.value })
  }

  onClearInn = () => {
    this.setState({ inn: '' })
  }

  onChangeEmail = ({ target }) => {
    this.setState({ email: target.value })
  }

  onClearEmail = () => {
    this.setState({ email: '' })
  }

  applyFilter = () => {
    const { onChangeFilter } = this.props
    const { inn, phone, email } = this.state
    onChangeFilter({
      // ...(principalCompanyId ? { principalCompanyId } : {}),
      ...(inn ? { inn } : {}),
      ...(phone ? { phone } : {}),
      ...(email ? { email } : {})
    })
  }

  render() {
    const { isFixed } = this.state
    const {
      isDisable,
      // defaultClientValue,
      classes
    } = this.props

    return (
      <div className={classnames('main-filter', { 'main-filter--fixed': isFixed })}>
        <div className="main-filter__container">
          <div className="main-filter__content">
            <div className={classnames('main-filter__row', { 'main-filter__row--disabled': isDisable })}>
              <InputPhone
                name="phone"
                placeholder="Номер телефона"
                onChange={this.onChangePhone}
                value={this.state.phone}
                inputClassName={this.props.classes.phone}
              />
              <div style={{ width: 20 }} />
              {/* <TextFieldWithAutoComplete
                name="principalCompanyId"
                value={this.state.principalCompanyId}
                onSelect={this.onSearchSelect}
                onClear={this.onSearchSelect}
                placeholder="Клиент"
                classNames={{
                  container: 'main-filter__control',
                  input: 'main-filter__control-field'
                }}
                noClearOnFocus={true}
                defaultValue={defaultClientValue}
              /> */}
              <TextField
                name="inn"
                placeholder="ИНН клиента"
                value={this.state.inn}
                onChange={this.onChangeInn}
                onClear={this.onClearInn}
              />
              <div style={{ width: 14 }} />
              <TextField
                name="email"
                placeholder="Электронная почта"
                value={this.state.email}
                onChange={this.onChangeEmail}
                onClear={this.onClearEmail}
              />
              <button className={classes.button} onClick={this.applyFilter}>
                <svg width="18" height="18" viewBox="0 0 18 18">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.03105 0C10.907 0 14.0622 3.1552 14.0622 7.03117C14.0622 8.50929 13.6051 9.91548 12.7709 11.0891L17.9999 16.318L16.3179 18L11.089 12.7711C9.91536 13.6052 8.50917 14.0623 7.03105 14.0623C3.15508 14.0623 -0.00012207 10.9071 -0.00012207 7.03117C-0.00012207 3.1552 3.15508 0 7.03105 0ZM11.705 7.03118C11.705 4.44243 9.6198 2.35727 7.03106 2.35727C4.44231 2.35727 2.35715 4.44243 2.35715 7.03118C2.35715 9.61992 4.44231 11.7051 7.03106 11.7051C9.6198 11.7051 11.705 9.61992 11.705 7.03118Z"
                    fill="#9B9B9B"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectSheet(styles)(TasksRkoFilter)
