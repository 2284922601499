import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { createFactoringAccount } from '../../../../redux/Factoring/actions'
import { Fieldset, InputText, Textarea, InputWithDadata, RowGrid, Col12 } from '../../../../layout'
import TextFieldWithAutoComplete from '../../../../components/TextFieldWithAutoComplete'
import { Button, ButtonWrapper, Form, H3 } from '../../Layout'

export const AddPaymentForm = ({
  parsedFilters,
  onGetFactoringView,
  onClose,
}) => {
  const dispatch = useDispatch()
  const params = useParams()

  const [externalId, setExternalId] = useState('')
  const [number, setNumber] = useState('')
  const [amount, setAmount] = useState('')
  const [date, setDate] = useState('')
  const [purpose, setPurpose] = useState('')
  const [payerInn, setPayerInn] = useState('')
  const [payerName, setPayerName] = useState('')
  const [payerKpp, setPayerKpp] = useState('')
  const [payerAccountNumber, setPayerAccountNumber] = useState('')
  const [payerBankName, setPayerBankName] = useState('')
  const [payerBic, setPayerBic] = useState('')
  const [receiverInn, setReceiverInn] = useState('')
  const [receiverName, setReceiverName] = useState('')
  const [receiverKpp, setReceiverKpp] = useState('')
  const [receiverAccountNumber, setReceiverAccountNumber] = useState('')
  const [receiverBankName, setReceiverBankName] = useState('')
  const [receiverBic, setReceiverBic] = useState('')
  const [submitted, setSubmitted] = useState(0)

  const payload = {
    externalId,
    number,
    amount,
    date,
    purpose,
    payerInn,
    payerKpp,
    payerName,
    payerAccountNumber,
    payerBic,
    receiverInn,
    receiverKpp,
    receiverName,
    receiverAccountNumber,
    receiverBic,
  }

  const errors = {
    ...(externalId?.trim() ? {} : { externalId: 'Не указан ID платежа' }),
    ...(number?.trim() ? {} : { number: 'Не указан номер' }),
    ...(amount?.trim() ? {} : { amount: 'Не указана сумма' }),
    ...(date?.trim() ? {} : { date: 'Не указана дата' }),
    ...(purpose?.trim() ? {} : { purpose: 'Не указано назначение' }),
    ...(payerInn?.trim() ? {} : { payerInn: 'Не указан плательщик' }),
    ...(payerAccountNumber?.trim() ? {} : { payerAccountNumber: 'Не указан счёт плательщика' }),
    ...(payerBic?.trim() ? {} : { payerBic: 'Не указан банк плательщика' }),
    ...(receiverInn?.trim() ? {} : { receiverInn: 'Не указан получатель' }),
    ...(receiverAccountNumber?.trim() ? {} : { receiverAccountNumber: 'Не указан счёт получателя' }),
    ...(receiverBic?.trim() ? {} : { receiverBic: 'Не указан банк получателя' }),
  }

  const isValid = Object.keys(errors)?.length === 0

  const onChangeDate = (value) => setDate(value)
  const onChangePayerBank = (item) => {
    setPayerBankName(item.bankName)
    setPayerBic(item.bic)
  }
  const onChangeReceiverBank = (item) => {
    setReceiverBankName(item.bankName)
    setReceiverBic(item.bic)
  }

  const onChangeExternalId = (e) => setExternalId(e.target.value)
  const onChangeNumber = (e) => setNumber(e.target.value)
  const onChangeAmount = (e) => setAmount(e.target.value)
  const onChangePurpose = (e) => setPurpose(e.target.value)
  const onChangePayerAccountNumber = (e) => setPayerAccountNumber(e.target.value)
  const onChangeReceiverAccountNumber = (e) => setReceiverAccountNumber(e.target.value)

  const onChangePayer = ({ displayName, INN, KPP }) => {
    setPayerName(displayName)
    setPayerInn(INN)
    setPayerKpp(KPP)
  }
  const onClearPayerName = () => {
    setPayerInn('')
    setPayerName('')
    setPayerKpp('')
  }

  const onChangeReceiver = ({ displayName, INN, KPP }) => {
    setReceiverName(displayName)
    setReceiverInn(INN)
    setReceiverKpp(KPP)
  }
  const onClearReceiver = () => {
    setReceiverInn('')
    setReceiverName('')
    setReceiverKpp('')
  }

  const onSubmit = () => setSubmitted(submitted + 1)

  const onCreateFactoringAccount = () => {
    const onSuccess = () => {
      onGetFactoringView(parsedFilters)
      onClose()
    }
    if (isValid) {
      dispatch(createFactoringAccount(payload, params?.entityType, onSuccess))
    }
  }

  useEffect(() => {
    if (submitted > 0) {
      onCreateFactoringAccount()
    }
  // eslint-disable-next-line
  }, [submitted])

  console.log('payload [CURRENT]:', payload)
  console.log('errors', errors)

  return (
    <>
      <H3>Добавить платёж:</H3>
      <Form>
        <Fieldset>
          <RowGrid>
            <Col12>
              <InputText
                type='text' 
                name='externalId'
                label='ID платежа'
                value={externalId}
                onChange={onChangeExternalId}
                error={submitted > 0 && errors?.externalId}
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <InputText
                type='text' 
                name='number'
                label='Номер'
                value={number}
                onChange={onChangeNumber}
                error={submitted > 0 && errors?.number}
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <InputText
                type='text' 
                name='amount'
                label='Сумма'
                value={amount}
                onChange={onChangeAmount}
                error={submitted > 0 && errors?.amount}
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <InputText
                type='date' 
                name='date'
                label='Дата'
                value={date}
                onChange={onChangeDate}
                error={submitted > 0 && errors?.date}
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <Textarea
                name='purpose'
                label='Назначение'
                value={purpose}
                onChange={onChangePurpose}
                error={submitted > 0 && errors.purpose}
                maxLength={160}
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <TextFieldWithAutoComplete
                classNames={{ container: 'filter-input', input: 'filter-input__input' }}
                name='payerInn'
                placeholder='Плательщик'
                label='Плательщик'
                value={payerName}
                defaultValue={payerName}
                onSelect={onChangePayer}
                onClear={onClearPayerName}
                payloadKeys={['displayName', 'INN', 'KPP']}
                meta={{
                  touched: true,
                  error: submitted > 0 ? errors.payerInn : '',
                }}
                withoutLink
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <InputText
                type='text' 
                name='payerAccountNumber'
                label='Счёт плательщика'
                value={payerAccountNumber}
                onChange={onChangePayerAccountNumber}
                error={submitted > 0 && errors?.payerAccountNumber}
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <InputWithDadata
                type='text'
                service='bank'
                name='payerBankName'
                label='Банк плательщика'
                placeholder='Введите название банка'
                value={payerBankName}
                onSelect={(item) => onChangePayerBank(item)}
                error={submitted > 0 && errors?.payerBic}
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <TextFieldWithAutoComplete
                classNames={{ container: 'filter-input', input: 'filter-input__input' }}
                name='receiverInn'
                placeholder='Получатель'
                label='Получатель'
                value={receiverName}
                defaultValue={receiverName}
                onSelect={onChangeReceiver}
                onClear={onClearReceiver}
                payloadKeys={['displayName', 'INN', 'KPP']}
                meta={{
                  touched: true,
                  error: submitted > 0 ? errors.receiverInn : '',
                }}
                withoutLink
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <InputText
                type='text' 
                name='receiverAccountNumber'
                label='Счёт получателя'
                value={receiverAccountNumber}
                onChange={onChangeReceiverAccountNumber}
                error={submitted > 0 && errors?.receiverAccountNumber}
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <InputWithDadata
                type='text'
                service='bank'
                name='receiverName'
                label='Банк получателя'
                placeholder='Введите название банка'
                value={receiverBankName}
                onSelect={(item) => onChangeReceiverBank(item)}
                error={submitted > 0 && errors?.receiverBic}
              />
            </Col12>
          </RowGrid>
        </Fieldset>
      </Form>
      <ButtonWrapper>
        <Button
          text='Создать'
          disabled={submitted > 0 && !isValid}
          onClick={onSubmit}
        />
      </ButtonWrapper>
    </>
  )
}