import React from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { ErrorState } from '../../containers/ErrorState'

const DEFAULT_ERROR = 'Авторизация не удалась. Обратитесь к администратору.'

const ErrorPage = (props) => {
  const history = useHistory()

  const isErrorPage = /error/.test(window.location.pathname)

  const { message } = queryString.parse(history?.location?.search) || {}

  const errorMessage = isErrorPage && message
    ? message
    : props?.errorMessage || DEFAULT_ERROR
  
  return isErrorPage
    ? <Redirect to={{ pathname: '/', state: { errorMessage } }} />
    : <ErrorState errorMessage={errorMessage} />
}

export default ErrorPage