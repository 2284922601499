import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { adaptive_1200 } from '../../../config'

const styles = {
  root: {
    maxWidth: 1340,
    padding: [0, 30],
    margin: [0, 'auto'],
    [adaptive_1200]: {
      padding: 0,
    },
  },
}

const Container = ({
  children,
  className,
  classes,
}) =>
  <div className={classnames(classes.root, className)}>
    {children}
  </div>

export default injectSheet(styles)(Container)