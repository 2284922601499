import * as actionTypes from './actionTypes'

const initialState = {
  isFetching: false,
  company: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLIENT_FETCH:
      return {
        ...state,
        isFetching: true,
        redirect: false,
      }
    case actionTypes.CLIENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.data,
      }
    case actionTypes.CLIENT_ERROR:
      return {
        ...state,
        isFetching: false,
        redirect: true,
      }
    case actionTypes.CLIENT_CLEAR:
      return {
        ...state,
        company: {},
        redirect: false,
      }
    default:
      return state
  }
}