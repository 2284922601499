import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch } from 'react-redux'
import { getCompanyOrder } from '../../../redux/Company/actions'
import { Modal } from '../../../layout'
import Content from './Content'

const useStyles = createUseStyles({
  root: {
    background: '#fff',
    width: '100%',
    height: '100%',
  },
})

export const ModalDeal = ({
  width,
  orderId,
	onClose,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCompanyOrder(orderId))
  }, [])

  return (
    <Modal className={classes.root} width={width} onClose={onClose}>
      <Content />
    </Modal>
  )
}