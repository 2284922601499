export const WIDGET_STATS_FETCH = 'WIDGET_STATS_FETCH';
export const WIDGET_STATS_SUCCESS = 'WIDGET_STATS_SUCCESS';
export const WIDGET_STATS_ERROR = 'WIDGET_STATS_ERROR';

export const PERIODS_LIST_FETCH = 'PERIODS_LIST_FETCH';
export const PERIODS_LIST_SUCCESS = 'PERIODS_LIST_SUCCESS';
export const PERIODS_LIST_ERROR = 'PERIODS_LIST_ERROR';

export const EMPLOYEE_STATS_FETCH = 'EMPLOYEE_STATS_FETCH';
export const EMPLOYEE_STATS_SUCCESS = 'EMPLOYEE_STATS_SUCCESS';
export const EMPLOYEE_STATS_ERROR = 'EMPLOYEE_STATS_ERROR';

export const COMPANY_STATS_FETCH = 'COMPANY_STATS_FETCH';
export const COMPANY_STATS_SUCCESS = 'COMPANY_STATS_SUCCESS';
export const COMPANY_STATS_ERROR = 'COMPANY_STATS_ERROR';
