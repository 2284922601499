import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { useSelector, useDispatch } from 'react-redux'
import Overlay from '../../../components/Overlay'
import TextFieldWithAutoComplete from '../../../components/TextFieldWithAutoComplete'
import TextField from '../../../components/TextField'
// import Dropdown from '../../../components/Dropdown'
import { getFile } from '../../../redux/Files/actions'
import { createSearchString } from '../../../utils'
import { Fieldset } from '../../../layout'
import { Button, H3 } from '../Layout'
import { Search } from '../../../services/api'

const useStyles = createUseStyles({
  reportContent: {
    paddingTop: 20,
  },
  fields: {
    marginTop: 20,
  },
  error: {
    border: '1px solid red !important',
  },
})

const getErrors = (values, FILTERS, filters) => {
  let errors = {}
  // eslint-disable-next-line no-unused-vars
  for (let key in values) {
    const { required } = filters?.find(item => item?.type === key) || {}
    if (values[key]) {
      delete errors[key]
    } else {
      if (required) {
        errors[key] = `Не заполнено поле "${FILTERS[key]?.label?.toLowerCase()}"`
      }
    }
  }
  return errors
}

export const ReportContent = ({
  description,
  url,
  filters,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [values, setValues] = useState([])
  const [submitted, setSubmitted] = useState(0)

  const { reports } = useSelector(state => state.Settings)
  const { isFetching } = useSelector(state => state.Files)

  const FILTERS = reports?.filters || []

  const errors = getErrors(values, FILTERS, filters)

  const isValid = Object.keys(errors)?.length === 0
  const disabled = submitted > 0 && !isValid

  const getInitialValues = (array = []) => array?.reduce((result, item) => {
    result[item.type] = ''
    return result
  }, {})

  useEffect(() => {
    setValues(getInitialValues(filters))
  }, [filters])

  const onChangeFiltersByAutocomplete = (payload, name, payloadKey, payloadKeyUI) => {
    const newValues = {
      ...values,
      [name]: {
        [payloadKey]: payload[payloadKey], 
        [payloadKeyUI]: payload[payloadKeyUI],
      },
    }
    if (payload[payloadKey] !== values[name]) {
      setValues(newValues)
    }
  }

  const onClearFiltersByAutocomplete = (name, value) => {
    const newValues = { ...values, [name]: value }
    if (value !== values[name]) {
      setValues(newValues)
    }
  }

  // const onChangeFiltersBySelect = (name, value) => {
  //   const {
  //     disabledKey,
  //     name: disabledFilterName,
  //   } = dataFilters.filter(item => item?.disabledKey).find(item => item?.disabledKey === name) || {}
  //   let sideEffectFilters = {}
  //   if (name === disabledKey) {
  //     sideEffectFilters[disabledFilterName] = ''
  //   }
  //   const _value = value === 'all' ? '' : value
  //   const newValues = { ...values, ...sideEffectFilters, [name]: _value }
  //   if (value !== values[name]) {
  //     setValues(newValues)
  //   }
  // }

  const onClearByInput = (name, value) => onChangeByInput({ target: { name, value }})

  const onChangeByInput = (e) => {
    const payload = { [e.target.name]: e.target.value }
    const newValues = { ...values, ...payload }
    if (e.target.value !== values[e.target.name]) {
      setValues(newValues)
    }
  }

  const onSubmitted = () => setSubmitted(submitted + 1)

  const onDownloadFile = () => {
    if (!isFetching) {
      let payload = {}
      // eslint-disable-next-line no-unused-vars
      for (const key in values) {
        const { name, autocompleteKey } = FILTERS[key] || {}
        payload[name] = values[key]?.[autocompleteKey] || values[key]?.[name] || values[key]
      }
      const searchString = createSearchString(payload)
      dispatch(getFile({
        url: `${url}?${searchString}`,
        onSuccess: () => {},
        onError: () => {},
      }))
    }
  }

  useEffect(() => {
    if (submitted > 0 && isValid) {
      onDownloadFile()
    }
  }, [submitted])

  const getComponent = ({
    type,
    name,
    label,
    placeholder,
    autocompleteKey,
    autocompleteKeyName,
    autocompleteSubKeys,
    autocompleteUrl,
    autocompleteParam,
    autocompleteResponsePath,
    // options,
    disabledKey,
    // showKey,
    // showValue,
    filterName,
    error,
  }) => {
    const value = values[filterName]?.[autocompleteKeyName] || values[filterName] || ''
    const fieldProps = {
      name,
      label,
      placeholder,
      value,
      defaultValue: value,
      disabled: (disabledKey && !values[disabledKey]) || false,
      error,
    }
    // const activeOptionIndex = options ? options.map(({ id }) => id).indexOf(value) : 0
    // const isShow = showKey ? values[showKey] === showValue : true
  
    const FIELDS = {
      autocomplete: <TextFieldWithAutoComplete
        classNames={{
          container: 'filter-input',
          input: classnames('filter-input__input', { [classes.error]: error }),
        }}
        onSelect={(payload, name) => onChangeFiltersByAutocomplete(payload, filterName, autocompleteKey, autocompleteKeyName)}
        onClear={(name, value) => onClearFiltersByAutocomplete(filterName, value)}
        payloadKeys={[autocompleteKey, autocompleteKeyName]}
        request={(q) => Search.getSuggestionsByUrl(q, autocompleteUrl, autocompleteParam, autocompleteResponsePath)}
        requestKey={autocompleteKeyName}
        requestSubKeys={autocompleteSubKeys || []}
        withoutLink
        {...fieldProps}
      />,
      text: <TextField
        type='text'
        onChange={onChangeByInput}
        onClear={onClearByInput}
        isMoney={false}
        {...fieldProps}
      />,
      date: <TextField
        type='date'
        onChange={onChangeByInput}
        onClear={onClearByInput}
        isMoney={false}
        {...fieldProps}
      />,
      // select: <Dropdown
      //   defaultActive={activeOptionIndex}
      //   list={options}
      //   onSelectItem={onChangeFiltersBySelect}
      //   {...fieldProps}
      // />
    }
  
    return FIELDS[type]
  }

  // console.log('FILTERS:', filters)
  // console.log('VALUES:', values)
  // console.log('ERRORS:', errors)

  return (
    <div className={classes.reportContent}>
      <H3>{description}</H3>
      <div className={classes.fields}>
        {filters?.map((item, index) => {
          const props = {
            ...FILTERS[item.type] || {},
            filterName: item.type,
            error: submitted > 0 && errors[item.type],
          }
          return (
            <Fieldset key={index}>
              {getComponent(props)}
            </Fieldset>
          )
        })}
        <Fieldset>
          <Button
            text='Получить отчёт'
            onClick={() => onSubmitted(url)}
            disabled={disabled}
          />
        </Fieldset>
      </div>
      {isFetching &&
        <Overlay />
      }
    </div>
  )
}