import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'

const styles = {
  root: {
    '& + &': {
      marginTop: 24,
    },
  },

}

const Block = ({ children, className, classes }) =>
  <div className={classnames(classes.root, className)}>{children}</div>

export default injectSheet(styles)(Block)