import React, { Fragment, useEffect, useState } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { getFactoringTasks, completeFactoringTask } from '../../../redux/Factoring/actions'
import { Toast } from '../../../components/Toast'
import { Checkbox, Icon } from '../../../layout'
import Overlay from '../../../components/Overlay'
import { formattedNumber, formattedDate, plural } from '../../../utils'
import HeaderFilter from '../../../containers/HeaderFilter'
import { Alert } from '../Layout'
import { THEME_COLOR } from '../../../config'

const useStyles = createUseStyles({
  h1: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 36,
    lineHeight: 48/36,
    color: '#0B1F35',
  },
  wrapper: {
    transform: 'translateY(100px)',
    padding: [0, 20],
  },
  container: {
    marginTop: 20,
    border: [1, 'solid', '#B6BCC3'],
    borderTop: 'none',
  },
  tableWrapper: {
    maxHeight: 'calc(100vh - 320px)',
    overflow: 'auto',
  },
  table: {
    marginBottom: 0,
    '& thead': {
      '& th': {
        background: '#eee',
        textAlign: 'left',
        position: 'sticky',
        top: 0,
        zIndex: 2,
        '&:first-child': {
          width: 40,
        },
        '& + th': {
          '& > div': {
            borderLeft: [1, 'solid', '#B6BCC3'],
          },
        },
        '& > div': {
          padding: 10,
          borderTop: [1, 'solid', '#B6BCC3'],
          fontSize: 12,
          lineHeight: 20/12,
          color: '#888',
          fontWeight: 600,
          textTransform: 'uppercase',
          whiteSpace: 'nowrap',
          position: 'relative',
          '&:after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: 1,
            background: '#B6BCC3',
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        '& + tr': {
          borderTop: [1, 'solid', '#B6BCC3'],
        },
      },
      '& td': {
        padding: 0,
        whiteSpace: 'nowrap',
        textAlign: 'left',
        verticalAlign: 'top',
        '&:first-child': {
          background: '#eee',
          padding: 0,
        },
        '& + td': {
          '& > div': {
            borderLeft: [1, 'solid', '#B6BCC3'],
          },
        },
        '&.colspan': {
          background: '#F3F4F5',
          padding: [10, 10, 10, 50],
          color: '#0B1F35',
          fontSize: 14,
          lineHeight: 20/14,
          fontWeight: 600,
        },
        '& > div': {
          minHeight: 40,
          padding: 10,
          fontSize: 14,
          lineHeight: 20/14,
          color: '#0B1F35',
          whiteSpace: 'nowrap',
          '&.verified': {
            background: '#FEF2E6',
            padding: 6,
            color: '#F57C00',
            fontSize: 10,
            lineHeight: 13/10,
            fontWeight: 600,
            textTransform: 'uppercase',
            borderRadius: 999,
            display: 'inline-block',
            verticalAlign: 'top',
          },
          '&.rejected': {
            background: '#FDEAE9',
            padding: 6,
            color: '#FF5C5C',
            fontSize: 10,
            lineHeight: 13/10,
            fontWeight: 600,
            textTransform: 'uppercase',
            borderRadius: 999,
            display: 'inline-block',
            verticalAlign: 'top',
          },
          '&.ellipsis': {
            // maxWidth: 70,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          '& > span': {
            display: 'inline-block',
            verticalAlign: 'top',
          },
          '& > svg': {
            width: 12,
            height: 12,
            marginLeft: 5,
            display: 'inline-block',
            verticalAlign: 'top',
          },
        },
      },
    },
  },
  info: {
    background: '#F3F4F5',
    minHeight: 68,
    padding: 10,
    color: '#0B1F35',
    fontSize: 16,
    lineHeight: 20/16,
    borderTop: [1, 'solid', '#B6BCC3'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span': {
      fontWeight: 600,
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& > div': {
      '&:first-child': {
        '& > div': {
          '& + div': {
            marginTop: 4,
          },
        },
      },
    },
  },
  checkbox: {
    width: 20,
    height: 20,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  decision: {
    background: THEME_COLOR,
    height: 48,
    padding: [13, 30],
    color: '#F3F4F5',
    fontSize: 14,
    lineHeight: 20/14,
    fontWeight: 600,
    border: [1, 'solid', THEME_COLOR],
    borderRadius: 5,
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    '&.reject': {
      background: 'none',
      borderColor: '#6d7986',
      color: '#0b1f35',
    },
    '& + &': {
      marginLeft: 20,
    },
    '& > span': {
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& > svg': {
      width: 20,
      height: 20,
      marginLeft: 15,
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  name: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
})

const HEAD_ITEMS = [
  'Номер',
  'Дата',
  'Статус',
  'Поставщик',
  'Договор ПБ',
  'Договор ПД',
  'Дебитор',
  'Сумма поставки',
  'Срок отсрочки',
  'Комментарий',
  'Результат',
]

const MONITORING_HEAD_ITEMS = [
  'Название задачи',
  'Тип проблемы',
  'Тип субъекта',
  'Субъект',
  'Типа объекта',
  'Объект',
  'Дата регистрации',
]

export const FactoringPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { taskDefinitionKey } = useParams()
  const { location } = useHistory()

  const [fixed, setFixed] = useState(false)
  const [items, setItems] = useState([])
  const [allChecked, setAllChecked] = useState(false)
  const [queue, setQueue] = useState([])
  const [code, setCode] = useState('')
  const [monitoringTaskDefinitionKeySelected, setMonitoringTaskDefinitionKeySelected] = useState('')

  const isMonitoring = location.pathname === '/factoring/monitoring'

  const { tasks, isFetching } = useSelector(state => state?.Factoring || {})
  const { factoring, monitoring } = useSelector(state => state?.Settings || {})

  const { taskName } = factoring?.tasks?.find(item => item?.taskDefinitionKey === taskDefinitionKey) || {}

  const { decisions = [] } = isMonitoring
    ? monitoring?.tasks?.find(item => item.taskDefinitionKey === monitoringTaskDefinitionKeySelected) || {}
    : factoring?.tasks?.find(item => item.taskDefinitionKey === taskDefinitionKey) || {}

  const titles = isMonitoring
    ? MONITORING_HEAD_ITEMS
    : tasks?.length > 0
      ? [...HEAD_ITEMS, ...Object.keys(tasks[0])]
      : HEAD_ITEMS

  const monitoringTasks = tasks.map((item) => ({
    id: item?.id,
    name: item?.name,
    taskDefinitionKey: item?.taskDefinitionKey,
    issueTypeName: item?.data?.issueTypeName,
    subjectTypeName: item?.data?.subjectTypeName,
    subjectName: item?.data?.subjectName,
    objectTypeName: item?.data?.objectTypeName,
    objectName: item?.data?.objectName,
    issueDateTime: item?.data?.issueDateTime,
  }))

  const _tasks = isMonitoring
    ? monitoringTasks
    : tasks.map(item => ({ ...item }))
  
  useEffect(() => {
    if (queue.length === 0 && taskDefinitionKey && !isMonitoring) {
      dispatch(getFactoringTasks({ taskDefinitionKey, type: '' }))
    }
  }, [taskDefinitionKey, queue.length, isMonitoring, dispatch])

  useEffect(() => {
    if (queue.length === 0 && isMonitoring) {
      dispatch(getFactoringTasks({ taskDefinitionKey: '', type: 'MONITORING' }))
    }
  }, [queue.length, isMonitoring, dispatch])

  useEffect(() => {
    if (queue.length > 0) {
      const { id, type, checked } = queue[0]
      const payload = { decisionValue: code }
      const onCallback = () => {
        const newQueue = [...queue]
        newQueue.shift()
        setQueue(newQueue)
        if (newQueue.length === 0) {
          setAllChecked(false)
          setCode('')
        }
      }
      const onSuccess = () => {
        Toast({
          message: `Завершение задачи прошло успешно`,
          type: 'success',
        })
        onCallback()
      }
      const onError = (error) => {
        Toast({
          message: error || `Завершение задачи не удалось`,
          type: 'error',
        })
        onCallback()
      }
      if (checked) {
        dispatch(completeFactoringTask(id, type, payload, onSuccess, onError))
      }
    }
  }, [queue, queue.length, code, dispatch])

  useEffect(() => {
    setItems(tasks.map(({ id, type }) => ({ id, type, checked: false })))
  }, [tasks])

  useEffect(() => {
    document.body.style.backgroundColor = '#fff'

    return () => {
      document.body.style.backgroundColor = 'inherit'
    }
  }, [])

  const onScroll = () => {
    if (!fixed && window.pageYOffset > 0) {
      setFixed(true)
    } else if (fixed && window.pageYOffset === 0) {
      setFixed(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    window.addEventListener('resize', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
      window.removeEventListener('resize', onScroll)
    }
  })

  const onSelectAll = () => {
    const _allChecked = !allChecked
    setAllChecked(_allChecked)
    setItems(items.map(item => {
      if ((_allChecked && !item.checked) || (!_allChecked && item.checked)) {
        item = {
          ...item,
          checked: !item.checked
        }
      }
      return item
    }))
  }

  const isChecked = (id) => {
    const { checked } = items.find(item => item.id === id && item.checked) || {}
    return checked
  }

  const onSelect = (e, taskDefinitionKey) => {
    const { id } = e.target
    const newItems = items.map(item => {
      if (item.id === id) {
        item = {
          ...item,
          ...(e.target.checked ? { taskDefinitionKey } : {}),
          checked: !item.checked,
        }
      }
      return item
    })
    setItems(newItems)
    setMonitoringTaskDefinitionKeySelected(
      newItems.find(({ taskDefinitionKey }) => taskDefinitionKey) ? taskDefinitionKey : ''
    )
  }

  const getTotal = (array) => `${array.length} ${plural(array.length, 'запись', 'записи', 'записей')}`

  const total = getTotal(isMonitoring ? monitoringTasks : tasks)
  const totalSum = _tasks.reduce((result, { data }) => result + data?.financing?.sum, 0)

  const showDecisions = items.some(item => item.checked)

  const onComplete = (code) => {
    const completeTasks = items.filter(item => item.checked)
    setQueue(completeTasks)
    setCode(code)
  }

  // const getStatus = (flag) => flag
  //   ? <div className='verified'>
  //       <span>Верифицировано</span>
  //       <Icon iconName='verified' />
  //     </div>
  //   : <div className='rejected'>
  //       <span>Отклонено</span>
  //       <Icon iconName='rejected' />
  //     </div>

  return isFetching
    ? <Overlay size='big' />
    : <>
        <HeaderFilter>ФИЛЬТРЫ</HeaderFilter>
        <div className={classes.wrapper}>
          <div className={classes.h1}>
            {isMonitoring ? 'Мониторинг' : taskName}
          </div>
          <div className={classes.container}>
            <div className={classes.tableWrapper}>
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th>
                      <div>
                        <Checkbox
                          className={classes.checkbox}
                          checked={allChecked || false}
                          onChange={onSelectAll}
                          title={allChecked ? 'Снять все' : 'Выбрать все'}
                        />
                      </div>
                    </th>
                    {titles.map((item, index) => 
                      <th key={index}><div>{item}</div></th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {isMonitoring && monitoringTasks.map((item, index) =>
                    <tr key={index}>
                      <td>
                        <div>
                          <Checkbox
                            id={item?.id}
                            className={classes.checkbox}
                            checked={isChecked(item?.id)}
                            onChange={(e) => onSelect(e, item?.taskDefinitionKey)}
                            title={isChecked(item?.id) ? 'Снять выбор' : 'Выбрать'}
                          />
                        </div>
                      </td>
                      <td><div className={classes.name}>{item?.name  || '-'}</div></td>
                      <td><div>{item?.issueTypeName || '-'}</div></td>
                      <td><div>{item?.subjectTypeName || '-'}</div></td>
                      <td><div>{item?.subjectName || '-'}</div></td>
                      <td><div>{item?.objectTypeName || '-'}</div></td>
                      <td><div>{item?.objectName || '-'}</div></td>
                      <td><div>{formattedDate(item?.issueDateTime)}</div></td>
                    </tr>
                  )}
                  {!isMonitoring && tasks.map((item, index) =>
                    <tr key={index}>
                      <td>
                        <div>
                          <Checkbox
                            id={item.id}
                            className={classes.checkbox}
                            checked={isChecked(item.id)}
                            onChange={onSelect}
                            title={isChecked(item.id) ? 'Снять выбор' : 'Выбрать'}
                          />  
                        </div>
                      </td>
                      <td><div>{item.data?.financing?.number || '-'}</div></td>
                      <td><div>{formattedDate(item.data?.financing?.financingDate)}</div></td>
                      <td><div><Alert type='success'>{item.data?.financing?.status?.name || '-'}</Alert></div></td>
                      <td><div>{item.data?.supplier?.name || '-'}</div></td>
                      <td><div>{item.data?.factoringContract?.number || '-'}</div></td>
                      <td><div>{item.data?.supplyContract?.number || '-'}</div></td>
                      <td><div>{item.data?.debtor?.name || '-'}</div></td>
                      <td><div>{formattedNumber(item.data?.financing?.sum) || '0'} ₽</div></td>
                      <td><div>{item.data?.supply?.deferDate || '-'}</div></td>
                      <td><div>-</div></td>
                      <td><div>-</div></td>
                      <td><div className='ellipsis'>{item.id}</div></td>
                      <td><div className='ellipsis'>{item.processInstanceId}</div></td>
                      <td><div className='ellipsis'>{item.processDefinitionKey}</div></td>
                      <td><div className='ellipsis'>{item.taskDefinitionKey}</div></td>
                      <td><div className='ellipsis'>{item.name}</div></td>
                      <td><div className='ellipsis'>{item.startTime}</div></td>
                      <td><div className='ellipsis'>{item.assignee}</div></td>
                      <td><div>{item.type}</div></td>
                      <td><div>{JSON.stringify(item.factoringInfo)}</div></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className={classes.info}>
              <div>
                <div><span>Итого:</span> {total}</div>
                {!isMonitoring &&
                  <div><span>На сумму:</span> {formattedNumber(totalSum)} ₽</div>
                }
              </div>
              {showDecisions && decisions?.length > 0 &&
                <div>
                  {decisions.map(({ code, text }, index) => {
                    const reject = code === 'REJECT'
                    const accept = code === 'ACCEPT'
                    return (
                      <Fragment key={index}>
                        <div className={classnames(classes.decision, { reject })} onClick={() => onComplete(code)}>
                          <span>{text}</span>
                          {accept && <Icon iconName='accept' />}
                          {reject && <Icon iconName='reject' />}
                        </div>
                      </Fragment>
                    )
                  })}
                </div>
              }
            </div>
          </div>
        </div>
      </>
}