import { saveAs } from 'file-saver'
import { get } from '../api'

export const downloadZip = async (files, name, onSuccess) => {
  const zip = require('jszip')()
  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    const fileName = `${i + 1}. ${file.name}`
    try {
      const response = await get(file.url)
      const fileBlob = new Blob([response])
      if (response.byteLength > 0) {
        zip.file(fileName, fileBlob)
      }
    } catch (error) {
      console.log(error)
    }
  } 
  zip.generateAsync({ type: 'blob' })
    .then(content => {
      saveAs(content, `Документы ${name}.zip`)
      onSuccess && onSuccess()
    })
}