import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { adaptive_1200 } from '../../../config'

const styles = {
  root: {
    fontSize: 20,
    lineHeight: 1.6,
    fontWeight: 500,
    [adaptive_1200]: {
      fontSize: 16,
    },
  },
}

const Value = ({ 
  children, 
  className, 
  classes, 
}) =>
  <div className={classnames(classes.root, className)}>
    {children}
  </div>

export default injectSheet(styles)(Value)
