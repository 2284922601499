import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ClearButton from '../ClearButton'
import { Label, Error } from '../../layout'

class TextField extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string,
    icon: PropTypes.string,
  }

  static defaultProps = { value: '' }

  state = {
    value: this.props.value || ''
  }

  _onChange = (event) => {
    this.setState({ value: event.target.value })
    this.props.onChange && this.props.onChange(event)
  }

  onClearField = () => {
    this.setState({ value: '' })
    this.props.onClear(this.props.name, '')
  }

  render() {
    const {
      type = 'text',
      name,
      label,
      placeholder,
      icon,
      disabled,
      error,
      onBlur,
    } = this.props
    
    const { value } = this.state

    return (
      <>
        <div className={classnames('main-filter__control', {'main-filter__control--icon-left': !!icon})}>
          {icon &&
            <span className={`icon ${icon}`} />
          }
          {label &&
            <Label>{label}</Label>
          }
          <div style={{position: 'relative'}}>
            <input
              type={type}
              className={classnames('main-filter__control-field', { error })}
              placeholder={placeholder}
              name={name}
              value={value}
              onChange={this._onChange}
              onBlur={onBlur}
              disabled={disabled}
              // {...rest}
            />
            <ClearButton
              onClear={this.onClearField}
              isHidden={!value.length}
            />
          </div>
          {error &&
            <Error>{error}</Error>
          }
        </div>
      </>
    )
  }
}

export default TextField