import React, { useState, useCallback, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams, useHistory } from 'react-router-dom'
import { getFactoringView, getFactoringMessages } from '../../../redux/Factoring/actions'
import { Table } from '../Layout'
import { FactoringWrapper } from '../FactoringWrapper'
import { Card } from './Card'
import { useFactoring } from '../useFactoring'
import { createSearchString } from '../../../utils'
import { FACTORING_VIEWS, IS_FACTORING_ENABLED } from '../../../config'

const useStyles = createUseStyles({
  content: {
    position: 'relative'
  },
  container: {
    marginTop: 20,
    // padding: 20,
    // border: [1, 'solid', '#B6BCC3'],
    borderRadius: 5,
  },
})

export const FactoringViewPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { id, entityType } = useParams()
  const history = useHistory()

  const { dataItems, dataFilters } = useFactoring({ entityType })

  const { views } = useSelector(state => state?.Factoring || {})

  const [filters, setFilters] = useState({})

  const { items = [] } = views

  const isLimit = entityType === 'limit'

  const limitCardColumns = ['displayName', 'debtors', 'factoringContractNumbers', 'freelimitAmount']

  let _dataItems = isLimit
    ? dataItems.reduce((result, item) => {
        if (limitCardColumns.indexOf(item.key) > -1) {
          result.push(item)
        }
        return result
      }, [])
    : dataItems

  const onGetFactoringMessages = useCallback(() => {
    return id
      ? dispatch(getFactoringMessages(entityType, id))
      : {}
  }, [entityType, id, dispatch])

  useEffect(() => {
    onGetFactoringMessages()
  }, [onGetFactoringMessages])

  const onOpen = (id) => id
    ? history.push({
        pathname: `/factoring/${entityType}/${id}`,
        search: createSearchString(filters),
      })
    : alert('Не определено значение поля id')

  const onClose = () => history.push({
    pathname: `/factoring/${entityType}`,
    search: createSearchString(filters),
  })

  const { title, entityName } = FACTORING_VIEWS.find(item => item?.entityType === entityType) || {}
  const { displayName } = items.find(item => item?.id?.toString() === id?.toString()) || {}

  const getFilters = (filters) => setFilters(filters)

  if (!IS_FACTORING_ENABLED) {
    return <Redirect to='/tasks' />
  }

  return (
    <div className='fr-content'>
      <FactoringWrapper
        title={title}
        dataFilters={dataFilters}
        entityType={entityType}
        getFilters={getFilters}
        onRequest={getFactoringView}
        requestPayload={entityType}
      >
        <div className={classes.content}>
          <div className={classes.container}>
            <Table
              id={id}
              dataItems={_dataItems}
              items={items}
              onOpen={onOpen}
            />
          </div>
        </div>
      </FactoringWrapper>
      {id &&
        <Card
          entityName={entityName}
          displayName={displayName}
          onGetFactoringMessages={onGetFactoringMessages}
          onClose={onClose}
        />
      }
    </div>
  )
}