import { styled } from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 700px;
  background-color: #fff;
  height: 100vh;
  margin: 0 auto;
`

export const Styled = {
    Container,
}