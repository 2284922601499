import { useState } from 'react'
import { signAllDocuments } from '../api'
import { IDataToSignItem, ISignDoc } from '../declarations'
import { ERRORS } from '../constants'

export const useSignAllDocument = () => {
  const [isSigningAll, setIsSigningAll] = useState<boolean>(false)
  const [signAllError, setSignAllError] = useState<string | null>(null)
  const [signedData, setSignedData] = useState<IDataToSignItem[] | null>(null)

  const signAllDocument = async (
    certificateThumbprint: string,
    documents: ISignDoc[],
    setDataToSign: React.Dispatch<React.SetStateAction<IDataToSignItem[]>>,
    dataToSign: IDataToSignItem[]
  ) => {
    setIsSigningAll(true)
    setSignAllError(null)

    try {
      const signedData = await signAllDocuments(certificateThumbprint, documents, dataToSign)
      setSignedData(signedData)
      signedData.map(doc => {
        setDataToSign(prevState => [...prevState, doc])
      })
    } catch (err) {
      setSignAllError(ERRORS.SIGN_ERROR + err)
    } finally {
      setIsSigningAll(false)
    }
  }

  return { isSigningAll, signAllError, signedData, signAllDocument }
}
