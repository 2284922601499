import React from 'react'
import injectSheet from 'react-jss'
import { Modal } from './../../index'
import { formattedNumber } from '../../../utils'

const styles = {
	root: {
    background: '#F4F4F4',
    padding: 15,
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
  },
  item: {
    background: '#E4E4E4',
    // minWidth: 316,
    marginRight: 14,
    padding: [16, 20, 17],
    // borderRadius: 12,
    display: 'inline-block',
    '&:first-child': {
      background: '#B060EF',
      color: '#6100DF',
    },
    '&:last-child': {
      background: '#FFBE00',
      color: '#C76202',
      marginRight: 0,
    },
  },
  value: {
    color: 'inherit',
  },
  label: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 18/14,
    // color: '#97969c',
    opacity: 0.7,
  },
}

const Limits = ({ 
  // calculatedAmount,
  finalAmount,
  maxOrderAmount,
  endDate,
  classes, 
}) => {
  // const calculated = !calculatedAmount ? 'НЕ РАССЧИТАН' : `${formattedNumber(calculatedAmount)} ₽`
  const final = !finalAmount ? 'НЕ РАССЧИТАН' : `${formattedNumber(finalAmount)} ₽`
  
  return (
    <Modal.Block iconName='info' title='Информация по лимитам'>
      <Modal.Content>
        <div className={classes.root}>
          {/* <div className={classes.item}>
            <Modal.Value className={classes.value}>{calculated}</Modal.Value>
            <div className={classes.label}>Автоматически рассчитанный лимит</div>
          </div> */}
          <div className={classes.item}>
            <Modal.Value className={classes.value}>{final}</Modal.Value>
            <div className={classes.label}>Итоговый лимит</div>
          </div>
          {maxOrderAmount && maxOrderAmount > 0
            ? <div className={classes.item}>
                <Modal.Value className={classes.value}>{`${formattedNumber(maxOrderAmount)} ₽`}</Modal.Value>
                <div className={classes.label}>Максимальная сумма 1й гарантии</div>
              </div>
            : null
          }
          {endDate
            ? <div className={classes.item}>
                <Modal.Value className={classes.value}>{endDate}</Modal.Value>
                <div className={classes.label}>Дата окончания</div>
              </div>
            : null
          }
        </div>
      </Modal.Content>
    </Modal.Block>
  )
}

export default injectSheet(styles)(Limits)