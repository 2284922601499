import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { THEME_COLOR } from '../../config'

const useStyles = createUseStyles({
  checkbox: {
    position: 'relative',
    '& + &': {
      marginTop: 10,
    },
    '& > input': {
      display: 'none',
      '&:checked': {
        '& + label': {
          '&:before': {
            opacity: 0,
          },
          '& + svg': {
            opacity: 1,
          },
        },
      },
    },
    '& > label': {
      paddingLeft: 30,
      color: '#212529',
      fontSize: 16,
      lineHeight: 20/16,
      letterSpacing: 0.5,
      cursor: 'pointer',
      position: 'relative',
      display: 'block',
      '&:before': {
        content: '""',
        width: 20,
        height: 20,
        borderRadius: 3,
        border: [3, 'solid', '#C4C4C4'],
        position: 'absolute',
        left: 0,
        top: 0,
        display: 'block',
        transition: 'opacity 300ms ease',
        opacity: 1,
      },
      '& + svg': {
        width: 20,
        height: 20,
        position: 'absolute',
        left: 0,
        top: 0,
        opacity: 0,
        fill: THEME_COLOR,
        transition: 'opacity 300ms ease',
        pointerEvents: 'none',
      },
    },
  },
})

export const Checkbox = ({
  id = 'checkbox',
  label,
  className,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.checkbox, className)}>
      <input id={id} type='checkbox' {...rest} />
      <label htmlFor={id}>{label}</label>
      <svg width='27' height='27' viewBox='0 0 27 27' xmlns='http://www.w3.org/2000/svg'>
        <rect width='27' height='27' rx='6'/>
        <path d='M7.39013 11.388C6.92709 10.8274 6.08253 10.7365 5.50374 11.185C4.92495 11.6335 4.83111 12.4516 5.29414 13.0122L10.6625 19.5121C11.2467 20.2195 12.3801 20.1456 12.8613 19.3688L21.8085 8.969C22.1899 8.35336 21.9838 7.55484 21.3482 7.18545C20.7126 6.81607 19.8882 7.0157 19.5069 7.63134L11.5527 16.428L7.39013 11.388Z' fill='white'/>
      </svg>
    </div>
  );
};
