import * as actionTypes from './actionsTypes'

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SETTINGS_SET:
      return {
        ...state, 
        ...action.data,
      }
    default:
      return state
  }
}