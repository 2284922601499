import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { adaptive_1200, COLOR_VALUES } from '../../config'

const styles = {
  banner: {
    pointerEvents: 'none',
    lineHeight: '18px',
    position: 'absolute',
    bottom: '40%',
    margin: [0, 16],
    maxWidth: 240,
    [adaptive_1200]: {
      display: 'none',
    },
    '& div.title': {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 5,
    },
    '& div.message': {
      fontSize: 14,
      color: '#242232',
    },
  },
}

class Banner extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    children: PropTypes.node
  }

  state = {
    title: this.props.title || 'Внимание!',
    text: this.props.children || this.props.text || 'Текст для баннера не задан',
  }

  render() {
    const { title, text } = this.state
    const { classes } = this.props

    return (
      <aside className={classes.banner}>
        <div className='title' style={{ color: COLOR_VALUES.red }}>
          { title }
        </div>
        <div className='message'>
          { text }
        </div>
      </aside>
    )
  }
}

export default injectSheet(styles)(Banner)