import React, { useEffect } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// import { CopyToClipboard } from 'react-copy-to-clipboard'
import { getClientItem, clearClient } from '../../../redux/Client/actions'
import { authenticationUser } from '../../../redux/User/actions'
import { Header, Block, Contacts, Documents, Limits, Orders } from './Layout'

const useStyles = createUseStyles({
  root: {
    maxHeight: 'inherit',
    borderRadius: 16,
    overflowY: 'auto',
    transition: 'filter 300ms ease',
    '&.isFetching': {
      filter: 'blur(4px)',
    },
  },
  wrapper: {
    height: '100%',
    overflowY: 'inherit',
  },
  footer: {
    height: 20,
  },
})

export const ClientDetail = ({
  id,
  onProgrammingRedirect,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  // const [copied, setCopied] = useState(false)

  const { company, redirect, isFetching } = useSelector(state => state?.Client)
  const { settings } = useSelector(state => state?.User)

  const { statusItems } = settings || {}

  const {
    displayName,
    fullName,
    INN,
    OGRN,
    KPP,
    legalAddress,
    contact,
    order: orders,
    stat: stats,
    docs = [],
    limits,
  } = company || {}

  const address = legalAddress?.addressResult || '—'
  const contactPhone = contact?.phone || '—'
  const contactName = contact?.fio || '—'

  const onClearClient = () => dispatch(clearClient())

  useEffect(() => {
    onClearClient()
    dispatch(getClientItem(id))

    return () => onClearClient()
  }, [])

  // const onCopyLink = () => {
  //   setCopied(true)
  //   setTimeout(() => setCopied(false), 200)
  //   alert('Скопировано!')
  // }

  const onOpenTaskDetail = (taskId, taskName) => dispatch(authenticationUser(true))
    .then(() => onProgrammingRedirect(`/tasks/${taskId}`, { title: taskName }))
    .catch(err => console.log(err))

  const headerProps = {
    fullName,
    displayName,
    INN,
    KPP,
    OGRN,
    statusItems,
    stats,
  }

  return redirect
    ? <Redirect to='/tasks' />
    : <div className={classnames(classes.root, { isFetching })}>
        <div className={classes.wrapper}>
          <Header {...headerProps} />
          <Block title='Лимиты'>
            <Limits limits={limits} />
          </Block>
          <Block title='Контактные данные'>
            <Contacts
              address={address}
              contactName={contactName}
              contactPhone={contactPhone}
            />
          </Block>
          <Block title='Документы'>
            <Documents displayName={displayName} documents={docs} />
          </Block>
          <Block title='Заявки'>
            <Orders orders={orders} onOpenTaskDetail={onOpenTaskDetail} />
          </Block>
          
          {/* <div className={classnames('modal-help-block', { 'modal-content--blur': isFetching })}>
            <CopyToClipboard text={window.location.href} onCopy={this.onCopyLink}>
              <button type='button' className='modal-help-block__copy-link btn btn-send'>
                <span>Отправить ссылку</span>
                <i className='icon icon-send' />
              </button>
            </CopyToClipboard>
          </div> */}
          <div className={classes.footer} />
        </div>
      </div>
}