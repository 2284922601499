import * as actionTypes from './actionTypes'

export const setErrorContent = (message) => (dispatch) => {
  dispatch({ 
    type: actionTypes.ERROR_SHOW, 
    message, 
  })
}

export const clearErrorContent = () => (dispatch) => {
  dispatch({ 
    type: actionTypes.ERROR_HIDE, 
  })
}