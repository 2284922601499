import React, { useState } from 'react';
import classnames from 'classnames'

const FilterToggler = ({ children }) => {
    const [isOpened, toggleIsOpened] = useState(false);

    const onClickHandler = () => {
        toggleIsOpened(!isOpened);
    }

    return (
        <>
            <div
                onClick={onClickHandler}

                className={classnames(
                    'main-filter__toggler',
                    { 'main-filter__toggler--opened': isOpened }
                )}
            >
                <div
                    className={classnames(
                        'icon',
                        isOpened ? 'icon-close-s' : 'icon-seacrh-m'
                    )}
                />
            </div>

            {children && children({ isOpened })}
        </>
    )
}

export {
    FilterToggler as default,
    FilterToggler
};

