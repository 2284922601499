import React, { useState } from 'react'
import { Modal } from '../../../layout'
import {
  AddLimitForm,
  AddSupplyRegisterForm,
  AddRateForm,
  AddBlockForm,
  AddAccountForm,
  AddPaymentForm,
} from '../Modals'
import { ButtonPlus } from '../Layout'

export const FactoringModal = ({
  entityType,
  parsedFilters,
  onGetFactoringView,
}) => {
  const [limitModal, setLimitModal] = useState(false)
  const [supplyRegisterModal, setSupplyRegisterModal] = useState(false)
  const [rateModal, setRateModal] = useState(false)
  const [blockModal, setBlockModal] = useState(false)
  const [accountModal, setAccountModal] = useState(false)
  const [paymentModal, setPaymentModal] = useState(false)

  const onOpenLimitModal = () => setLimitModal(true)
  const onCloseLimitModal = () => setLimitModal(false)

  const onOpenSupplyRegisterModal = () => setSupplyRegisterModal(true)
  const onCloseAddSupplyRegisterModal = () => setSupplyRegisterModal(false)

  const onOpenRateModal = () => setRateModal(true)
  const onCloseRateModal = () => setRateModal(false)

  const onOpenBlockModal = () => setBlockModal(true)
  const onCloseBlockModal = () => setBlockModal(false)

  const onOpenAccountModal = () => setAccountModal(true)
  const onCloseAccountModal = () => setAccountModal(false)

  const onOpenPaymentModal = () => setPaymentModal(true)
  const onClosePaymentModal = () => setPaymentModal(false)

  const modals = {
    limit: <>
      <ButtonPlus onClick={onOpenLimitModal}>+</ButtonPlus>
      {limitModal &&
        <Modal width='800px' onClose={onCloseLimitModal}>
          <AddLimitForm
            onGetFactoringView={onGetFactoringView}
            parsedFilters={parsedFilters}
            onClose={onCloseLimitModal}
          />
        </Modal>
      }
    </>,
    financing: <>
      <ButtonPlus onClick={onOpenSupplyRegisterModal}>+</ButtonPlus>
      {supplyRegisterModal &&
        <Modal width='900px' height='100%' onClose={onCloseAddSupplyRegisterModal}>
          <AddSupplyRegisterForm
            parsedFilters={parsedFilters}
            onGetFactoringView={onGetFactoringView}
            onClose={onCloseAddSupplyRegisterModal}
          />
        </Modal>
      }
    </>,
    rate: <>
      <ButtonPlus onClick={onOpenRateModal}>+</ButtonPlus>
      {rateModal &&
        <Modal width='800px' onClose={onCloseRateModal}>
          <AddRateForm
            onGetFactoringView={onGetFactoringView}
            parsedFilters={parsedFilters}
            onClose={onCloseRateModal}
          />
        </Modal>
      }
    </>,
    block: <>
      <ButtonPlus onClick={onOpenBlockModal}>+</ButtonPlus>
      {blockModal &&
        <Modal width='800px' onClose={onCloseBlockModal}>
          <AddBlockForm
            onGetFactoringView={onGetFactoringView}
            parsedFilters={parsedFilters}
            onClose={onCloseBlockModal}
          />
        </Modal>
      }
    </>,
    account: <>
      <ButtonPlus onClick={onOpenAccountModal}>+</ButtonPlus>
      {accountModal &&
        <Modal width='800px' onClose={onCloseAccountModal}>
          <AddAccountForm
            onGetFactoringView={onGetFactoringView}
            parsedFilters={parsedFilters}
            onClose={onCloseAccountModal}
          />
        </Modal>
      }
    </>,
    payment: <>
      <ButtonPlus onClick={onOpenPaymentModal}>+</ButtonPlus>
      {paymentModal &&
        <Modal width='800px' onClose={onClosePaymentModal}>
          <AddPaymentForm
            onGetFactoringView={onGetFactoringView}
            parsedFilters={parsedFilters}
            onClose={onClosePaymentModal}
          />
        </Modal>
      }
    </>,
    supply: <>
      <ButtonPlus onClick={onOpenSupplyRegisterModal}>+</ButtonPlus>
      {supplyRegisterModal &&
        <Modal width='900px' height='100%' onClose={onCloseAddSupplyRegisterModal}>
          <AddSupplyRegisterForm
            parsedFilters={parsedFilters}
            onGetFactoringView={onGetFactoringView}
            onClose={onCloseAddSupplyRegisterModal}
          />
        </Modal>
      }
    </>,
  }
  return modals[entityType] || null
}