import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Dropdown from '../../../components/Dropdown'
import CheckboxList from '../../../components/CheckboxList'
import TextField from '../../../components/TextField'
import DatePicker from '../../../components/DatePicker'
import RangeSlider from '../../../components/RangeSlider'
import TextFieldWithAutoComplete from '../../../components/TextFieldWithAutoComplete'
import { FilterToggler } from '../FilterToggler'
import { SHOW_TASKS_FILTER_SUMM, SHOW_TASKS_FILTER_CLIENT } from '../../../config'

class TasksFilter extends PureComponent {
  static propTypes = {
    isDisable: PropTypes.bool,
    filters: PropTypes.object,
    filterAmount: PropTypes.shape({
      from: PropTypes.number,
      to: PropTypes.number,
    }),
    onChangeFilter: PropTypes.func.isRequired,
  }
  static defaultProps = { isDisable: false }

  state = { isFixed: false }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onScroll)
  }

  getProcessesFilter(processes, orderTypeRefId = '') {
    const { filters, onChangeFilter } = this.props
    let active = 0
    const list = (processes || []).reduce((result, process) => {
      if (orderTypeRefId === process.process_type) {
        active = result.length
      }
      return result.concat([{
        key: process.process_type,
        value: process.process_name,
      }])
    }, [{ key: 'all', value: 'Все процессы' }])
    if (list.length === 2) {
      active = 1
      if (filters.orderTypeRefId !== list[1].key) {
        onChangeFilter({ orderTypeRefId: list[1].key })
      }
    }
    return { active, list }
  }

  static getPhaseFilter(processes, activeProcess, phaseId = '') {
    let active = 0
    const { phases = [] } = processes[activeProcess]
    const list = phases.reduce((result, phase) => {
      if (phaseId === phase.phaseId) {
        active = result.length
      }
      return result.concat([{
        key: phase.phaseId,
        value: phase.phaseName,
      }])
    }, [{ key: 'all', value: 'Все фазы' }])
    return { active, list }
  }

  onScroll = () => {
    if (!this.state.isFixed && window.pageYOffset > 0) {
      this.setState({ isFixed: true })
      return
    }
    if (this.state.isFixed && window.pageYOffset === 0) {
      this.setState({ isFixed: false })
    }
  }

  onClearField = (name, value) => {
    this.props.onChangeFilter({ [name]: value })
  }

  onSelectDropdown = (name, key) => {
    const { onChangeFilter } = this.props
    if (name === 'orderTypeRefId') {
      onChangeFilter({
        [name]: key === 'all' ? '' : key,
        phaseId: '',
      })
      return
    }
    if (key === 'all') {
      onChangeFilter({ [name]: '' })
    } else {
      onChangeFilter({ [name]: key })
    }
  }

  onCheckboxSelect = ({ target }) => {
    if (target.value === 'all') {
      this.onClearField(target.name, '')
    } else {
      this.props.onChangeFilter({ [target.name]: target.value })
    }
  }

  onTypeText = ({ target }) => {
    this.props.onChangeFilter({ [target.name]: target.value })
  }

  onSelectDate = ({ target }) => {
    const values = target.value.split(' - ')
    this.props.onChangeFilter({
      [target.name]: {
        from: values[0],
        to: values[1],
      },
    })
  }

  onEndMovingSlider = (name, value) => {
    this.props.onChangeFilter({
      [name]: {
        from: value[0],
        to: value[1],
      },
    })
  }

  onSearchSelect = (payload, name) => {
    this.props.onChangeFilter({ [name]: payload?.id })
  }

  render() {
    const { isFixed } = this.state
    const { isDisable, filters, filterAmount, processes, statusItems } = this.props

    const processesFilter = this.getProcessesFilter(processes, filters.orderTypeRefId)
    // const phaseFilter = filters.orderTypeRefId
    //   ? TasksFilter.getPhaseFilter(processes, processesFilter.active - 1, filters.phaseId)
    //   : { list: [{ key: 'all', value: 'Все фазы' }] }
    return (
      <FilterToggler>
        {({ isOpened }) => (
          <div className={classnames('main-filter', {
            'main-filter--fixed': isFixed,
            'main-filter--opened' : isOpened
            })}>
            <div className='main-filter__container'>
              <div className='main-filter__content'>
                <div className={classnames('main-filter__row', { 'main-filter__row--disabled': isDisable })}>
                  <div className='main-filter__control main-filter__control--icon-right'>
                    <Dropdown
                      name='orderTypeRefId'
                      toggleClassName='btn btn-dropdown--hidden-border'
                      defaultActive={processesFilter.active}
                      list={processesFilter.list}
                      disabled={processesFilter.list.length < 2}
                      onSelectItem={this.onSelectDropdown}
                    />
                    <i className='icon icon-chevron-down' />
                  </div>
                  {/* <div className='main-filter__control main-filter__control--icon-right'>
                    <Dropdown
                      name='phaseId'
                      toggleClassName='btn btn-dropdown--hidden-border'
                      defaultActive={phaseFilter.active}
                      list={phaseFilter.list}
                      disabled={phaseFilter.list.length < 2}
                      onSelectItem={this.onSelectDropdown}
                    />
                    <i className='icon icon-chevron-down' />
                  </div> */}
                  <DatePicker
                    name='createdDate'
                    defaultActive={filters.createdDate}
                    onSelectDate={this.onSelectDate}
                    onClear={this.onClearField}
                  />
                  <TextField
                    name='orderNumber'
                    placeholder='Номер заявки'
                    value={filters.orderNumber}
                    onChange={this.onTypeText}
                    onClear={this.onClearField}
                    icon='icon-seacrh-m'
                  />
                  {SHOW_TASKS_FILTER_CLIENT &&
                    <TextFieldWithAutoComplete
                      name='principalCompanyId'
                      value={filters.principalCompanyId}
                      onSelect={this.onSearchSelect}
                      onClear={this.onClearField}
                      placeholder='Клиент'
                      classNames={{
                        container: 'main-filter__control main-filter__control--icon-left',
                        input: 'main-filter__control-field',
                      }}
                      icon='icon-seacrh-m'
                      noClearOnFocus
                    />
                  }
                  {/*<div className='main-filter__control main-filter__control--button'>*/}
                  {/*<Link className='btn btn-search' to='?search'>*/}
                  {/*<i className='icon icon-seacrh-m' />*/}
                  {/*</Link>*/}
                  {/*</div>*/}
                </div>
                {SHOW_TASKS_FILTER_SUMM &&
                  <div className={classnames('main-filter__row', { 'main-filter__row--disabled': isDisable })}>
                    <RangeSlider
                      name='amount'
                      defaultActive={filterAmount}
                      onEndChanging={this.onEndMovingSlider}
                    />
                  </div>
                }
                <div className={classnames('main-filter__row', { 'main-filter__row--disabled': isDisable })}>
                  <CheckboxList
                    statusItems={statusItems}
                    activeValue={filters.status}
                    onChangeItem={this.onCheckboxSelect}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </FilterToggler>
    )
  }
}

export default TasksFilter
