import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import injectSheet from 'react-jss'
import { changeTasksUser } from '../../../redux/Tasks/actions'
import { Modal } from '../../../layout'
import Overlay from '../../Overlay'



const styles = {
  action: {
    position: 'absolute',
    bottom: 30,
    right: 30,
    left: 30,
    textAlign: 'right',
  },
  cancel: {
    background: 'none',
    border: 'none',
    color: '#8f8e97',
    fontSize: 16,
    lineHeight: 2.5,
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    '&:hover': {
      color: '#75747e',
    },
  },
  submit: {
    background: '#8f8e97',
    marginLeft: 20,
    padding: [0, 20],
    border: 'none',
    borderRadius: 16,
    color: '#fff',
    fontSize: 16,
    lineHeight: 2.5,
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    '&:hover': {
      background: '#75747e',
    }
  },
  inputBox: {
    marginTop: 20,
    border: '1px solid #cccccc',
    borderRadius: 12
} 
}
let needOverlay = false

const ContentWithLogin = ({
  classes,
  taskId,
  onClose,
}) => {

  const dispatch = useDispatch()
  const [username, setUsername] = useState('')
  
  const LoginInput = 
        <input 
            id="agentLoginInput"
            placeholder="Введите логин агента" 
            class="main-filter__control-field"
            onInput={onInput}>
        </input>

  const onChange = () => {
    const payload = {
        username,
        task_id: taskId,
    }
    dispatch(changeTasksUser(payload,onClose)).then(
      () => {
        needOverlay = false
      }
    )
  }
    

  function onInput(e) {
    setUsername(e.target.value)
  }
    

  return needOverlay ? <Overlay/> :
  <> 
        <Modal.H1>Поменять ответственного</Modal.H1>  
        <div class={classes.inputBox}>
        {LoginInput}
        </div>
        <div className={classes.action}>
          <div className={classes.cancel} onClick={onClose}>Отменить</div>
          <button className={classes.submit} onClick={() => {
            if (username !== ''){
              needOverlay = true
              onChange()
            }
            }}>Поменять</button>
        </div>

    </>
}

export default injectSheet(styles)(ContentWithLogin)