import React from 'react'
import { Error } from '../../layout'

export const FormTextField = ({
  input: {
    value,
    ...inputProps
  },
  meta: {
    touched,
    error,
  },
  label,
  disabled,
  defaultValue,
  placeholder,
  errorCustom,
  ...rest
}) =>
  <div>
    {label
      ? <label>{label}</label>
      : null
    }
    <div className='form-group'>
      <input
        type='text'
        className='form-control'
        style={{ marginTop: 4, marginBottom: 2 }}
        disabled={disabled}
        value={value || defaultValue || ''}
        placeholder={placeholder}
        {...rest}
        {...inputProps}
      />
      {touched && error &&
        <Error>{error}</Error>
      }
      {errorCustom &&
        <Error>{errorCustom}</Error>
      }
    </div>
  </div>