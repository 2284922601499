import * as actionTypes from './actionTypes'
import { Factoring } from '../../services/api'
import { logoutProcess } from '../User/actions'
import { setErrorContent } from '../Error/actions'

export const getFactoringTasks = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_FACTORING_TASKS })
    const {
      isSuccess,
      data,
      message,
      needLogout,
    } = await Factoring.getFactoringTasks(payload)
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({ 
      type: actionTypes.GET_FACTORING_TASKS_SUCCESS, 
      data,
    })
  } catch (error) {
    console.log(error)
    // dispatch(setErrorContent(error.message))
    dispatch({ type: actionTypes.GET_FACTORING_TASKS_ERROR })
  }
}

export const completeFactoringTask = (id, type, payload, onSuccess, onError) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.COMPLETE_FACTORING_TASK })
    const {
      isSuccess,
      data,
      message,
      needLogout,
    } = await Factoring.completeFactoringTask(id, type, payload)
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({ 
      type: actionTypes.COMPLETE_FACTORING_TASK_SUCCESS, 
      data,
    })
    onSuccess && onSuccess()
  } catch (error) {
    console.log(error)
    // dispatch(setErrorContent(error.message))
    dispatch({ type: actionTypes.COMPLETE_FACTORING_TASK_ERROR })
    onError && onError(error.message)
  }
}

export const getFactoringView = (entityType, filters) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_FACTORING_VIEW })
    const {
      isSuccess,
      data,
      message,
      needLogout,
    } = await Factoring.getFactoringView(entityType, filters)
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({ 
      data: {
        ...data,
        entityType,
        filters,
      },
      type: actionTypes.GET_FACTORING_VIEW_SUCCESS,
    })
  } catch (error) {
    console.log(error)
    // dispatch(setErrorContent(error.message))
    dispatch({ type: actionTypes.GET_FACTORING_VIEW_ERROR })
  }
}

export const getFactoringContractsBySupplierInn = (entityType, filters, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_FACTORING_CONTRACTS_BY_SUPPLIER_INN })
    const {
      isSuccess,
      data,
      message,
      needLogout,
    } = await Factoring.getFactoringView(entityType, filters)
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({ type: actionTypes.GET_FACTORING_CONTRACTS_BY_SUPPLIER_INN_SUCCESS })
    onSuccess && onSuccess(data.data)
  } catch (error) {
    console.log(error)
    // dispatch(setErrorContent(error.message))
    dispatch({ type: actionTypes.GET_FACTORING_CONTRACTS_BY_SUPPLIER_INN_ERROR })
  }
}

export const getFactoringShortInfo = (entityType, companyId, showFieldName) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_FACTORING_SHORT_INFO })
    const {
      isSuccess,
      data,
      message,
      needLogout,
    } = await Factoring.getFactoringShortInfo(entityType, companyId)
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({ 
      data,
      showFieldName,
      type: actionTypes.GET_FACTORING_SHORT_INFO_SUCCESS,
    })
  } catch (error) {
    console.log(error)
    // dispatch(setErrorContent(error.message))
    dispatch({ type: actionTypes.GET_FACTORING_SHORT_INFO_ERROR })
  }
}

export const getFactoringMessages = (entityType, entityId) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_FACTORING_MESSAGES })
    const {
      isSuccess,
      needLogout,
      message,
      data,
    } = await Factoring.getFactoringMessages(entityType, entityId)
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({
      type: actionTypes.GET_FACTORING_MESSAGES_SUCCESS,
      data,
    })
  } catch (error) {
    console.log(error)
    // dispatch(setErrorContent(error.message))
    dispatch({ type: actionTypes.GET_FACTORING_MESSAGES_ERROR })
  }
}

export const sendFactoringMessage = (payload, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SEND_FACTORING_MESSAGE })
    const {
      isSuccess,
      needLogout,
      message,
    } = await Factoring.sendFactoringMessage(payload)
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({
      type: actionTypes.SEND_FACTORING_MESSAGE_SUCCESS,
    })
    onSuccess && onSuccess()
  } catch (error) {
    console.log(error)
    // dispatch(setErrorContent(error.message))
    dispatch({ type: actionTypes.SEND_FACTORING_MESSAGE_ERROR })
  }
}

export const startFactoringProcess = (payload, processDefinitionKey, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.START_FACTORING_PROCESS })
    const {
      isSuccess,
      needLogout,
      message,
    } = await Factoring.startFactoringProcess(payload, processDefinitionKey)
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({
      type: actionTypes.START_FACTORING_PROCESS_SUCCESS,
    })
    onSuccess && onSuccess()
  } catch (error) {
    console.log(error)
    dispatch(setErrorContent('Не удалось отправить реестр'))
    dispatch({ type: actionTypes.START_FACTORING_PROCESS_ERROR })
  }
}

export const getFactoringRefDocuments = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_FACTORING_REF_DOCUMENTS })
    const {
      isSuccess,
      needLogout,
      message,
      data,
    } = await Factoring.getFactoringRefDocuments()
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({
      type: actionTypes.GET_FACTORING_REF_DOCUMENTS_SUCCESS,
      data,
    })
  } catch (error) {
    console.log(error)
    dispatch(setErrorContent('Не удалось получить документы'))
    dispatch({ type: actionTypes.GET_FACTORING_REF_DOCUMENTS_ERROR })
  }
}

export const getFactoringDebtors = (factoringContractId, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_FACTORING_DEBTORS })
    const {
      isSuccess,
      needLogout,
      message,
      data,
    } = await Factoring.getFactoringDebtors(factoringContractId)
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({
      type: actionTypes.GET_FACTORING_DEBTORS_SUCCESS,
      // data,
    })
    onSuccess && onSuccess(data)
  } catch (error) {
    console.log(error)
    dispatch(setErrorContent('Не удалось получить дебиторов'))
    dispatch({ type: actionTypes.GET_FACTORING_DEBTORS_ERROR })
  }
}

export const getFactoringDuplicateSettings = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_FACTORING_DUPLICATE_SETTINGS })
    const {
      isSuccess,
      needLogout,
      message,
      data,
    } = await Factoring.getFactoringDuplicateSettings(payload)
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({
      type: actionTypes.GET_FACTORING_DUPLICATE_SETTINGS_SUCCESS,
      data,
    })
  } catch (error) {
    console.log(error)
    dispatch(setErrorContent('Не удалось получить настроек дедупликации'))
    dispatch({ type: actionTypes.GET_FACTORING_DUPLICATE_SETTINGS_ERROR })
  }
}

export const getFactoringPriceMatrix = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_FACTORING_PRICE_MATRIX })
    const {
      isSuccess,
      needLogout,
      message,
      data,
    } = await Factoring.getFactoringPriceMatrix(id)
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({
      type: actionTypes.GET_FACTORING_PRICE_MATRIX_SUCCESS,
      data,
    })
  } catch (error) {
    console.log(error)
    dispatch(setErrorContent('Не удалось получить прайс-матрицы'))
    dispatch({ type: actionTypes.GET_FACTORING_PRICE_MATRIX_ERROR })
  }
}

export const getFactoringAccountEntityTypes = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_FACTORING_ACCOUNT_ENTITY_TYPES })
    const {
      isSuccess,
      needLogout,
      message,
      data,
    } = await Factoring.getFactoringAccountEntityTypes()
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({
      type: actionTypes.GET_FACTORING_ACCOUNT_ENTITY_TYPES_SUCCESS,
      data,
    })
  } catch (error) {
    console.log(error)
    dispatch(setErrorContent('Не удалось получить справочник типов сущностей счетов'))
    dispatch({ type: actionTypes.GET_FACTORING_ACCOUNT_ENTITY_TYPES_ERROR })
  }
}

export const getFactoringRefRateTypes = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_FACTORING_REF_RATE_TYPES })
    const {
      isSuccess,
      needLogout,
      message,
      data,
    } = await Factoring.getFactoringRefRateTypes()
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({
      type: actionTypes.GET_FACTORING_REF_RATE_TYPES_SUCCESS,
      data,
    })
  } catch (error) {
    console.log(error)
    dispatch(setErrorContent('Не удалось получить справочник типа поставок'))
    dispatch({ type: actionTypes.GET_FACTORING_REF_RATE_TYPES_ERROR })
  }
}

export const getFactoringRefBlockTypes = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_FACTORING_REF_BLOCK_TYPES })
    const {
      isSuccess,
      needLogout,
      message,
      data,
    } = await Factoring.getFactoringRefBlockTypes()
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({
      type: actionTypes.GET_FACTORING_REF_BLOCK_TYPES_SUCCESS,
      data,
    })
  } catch (error) {
    console.log(error)
    dispatch(setErrorContent('Не удалось получить справочник типов операций для блокировки'))
    dispatch({ type: actionTypes.GET_FACTORING_REF_BLOCK_TYPES_ERROR })
  }
}

export const getFactoringRefOperationTypes = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_FACTORING_REF_OPERATION_TYPES })
    const {
      isSuccess,
      needLogout,
      message,
      data,
    } = await Factoring.getFactoringRefOperationTypes()
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({
      type: actionTypes.GET_FACTORING_REF_OPERATION_TYPES_SUCCESS,
      data,
    })
  } catch (error) {
    console.log(error)
    dispatch(setErrorContent('Не удалось получить справочник статусов продовок'))
    dispatch({ type: actionTypes.GET_FACTORING_REF_OPERATION_TYPES_ERROR })
  }
}

export const getFactoringRefAccountTypes = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_FACTORING_REF_ACCOUNT_TYPES })
    const {
      isSuccess,
      needLogout,
      message,
      data,
    } = await Factoring.getFactoringRefAccountTypes()
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({
      type: actionTypes.GET_FACTORING_REF_ACCOUNT_TYPES_SUCCESS,
      data,
    })
  } catch (error) {
    console.log(error)
    dispatch(setErrorContent('Не удалось получить справочник типов счетов'))
    dispatch({ type: actionTypes.GET_FACTORING_REF_ACCOUNT_TYPES_ERROR })
  }
}

export const getFactoringRefOfferingKeys = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_FACTORING_REF_OFFERING_KEYS })
    const {
      isSuccess,
      needLogout,
      message,
      data,
    } = await Factoring.getFactoringRefOfferingKeys()
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({
      type: actionTypes.GET_FACTORING_REF_OFFERING_KEYS_SUCCESS,
      data,
    })
  } catch (error) {
    console.log(error)
    dispatch(setErrorContent('Не удалось получить справочник ключей офферинга'))
    dispatch({ type: actionTypes.GET_FACTORING_REF_OFFERING_KEYS_ERROR })
  }
}

export const createFactoringLimit = (payload, entityType, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.CREATE_FACTORING_LIMIT })
    const {
      isSuccess,
      needLogout,
      message,
    } = await Factoring.createFactoringEntity(payload, entityType)
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({
      type: actionTypes.CREATE_FACTORING_LIMIT_SUCCESS,
    })
    onSuccess && onSuccess()
  } catch (error) {
    console.log(error)
    dispatch(setErrorContent('Не удалось создать лимит'))
    dispatch({ type: actionTypes.CREATE_FACTORING_LIMIT_ERROR })
  }
}

export const createFactoringRate = (payload, entityType, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.CREATE_FACTORING_RATE })
    const {
      isSuccess,
      needLogout,
      message,
    } = await Factoring.createFactoringEntity(payload, entityType)
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({
      type: actionTypes.CREATE_FACTORING_RATE_SUCCESS,
    })
    onSuccess && onSuccess()
  } catch (error) {
    console.log(error)
    dispatch(setErrorContent('Не удалось создать ставку'))
    dispatch({ type: actionTypes.CREATE_FACTORING_RATE_ERROR })
  }
}

export const createFactoringBlock = (payload, entityType, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.CREATE_FACTORING_BLOCK })
    const {
      isSuccess,
      needLogout,
      message,
    } = await Factoring.createFactoringEntity(payload, entityType)
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({
      type: actionTypes.CREATE_FACTORING_BLOCK_SUCCESS,
    })
    onSuccess && onSuccess()
  } catch (error) {
    console.log(error)
    dispatch(setErrorContent('Не удалось создать блокировку'))
    dispatch({ type: actionTypes.CREATE_FACTORING_BLOCK_ERROR })
  }
}

export const createFactoringAccount = (payload, entityType, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.CREATE_FACTORING_ACCOUNT })
    const {
      isSuccess,
      needLogout,
      message,
    } = await Factoring.createFactoringEntity(payload, entityType)
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({
      type: actionTypes.CREATE_FACTORING_ACCOUNT_SUCCESS,
    })
    onSuccess && onSuccess()
  } catch (error) {
    console.log(error)
    dispatch(setErrorContent('Не удалось создать счёт'))
    dispatch({ type: actionTypes.CREATE_FACTORING_ACCOUNT_ERROR })
  }
}

export const getFactoringPaymentOrders = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_FACTORING_PAYMENT_ORDERS })
    const {
      isSuccess,
      data,
      message,
      needLogout,
    } = await Factoring.getFactoringPaymentOrders(payload)
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({ 
      type: actionTypes.GET_FACTORING_PAYMENT_ORDERS_SUCCESS, 
      data,
      isPaymentOrders: payload.orderType.length === 1 && payload.orderType[0] === 'supply',
    })
  } catch (error) {
    console.log(error)
    // dispatch(setErrorContent(error.message))
    dispatch({ type: actionTypes.GET_FACTORING_PAYMENT_ORDERS_ERROR })
  }
}

export const getFactoringMatchingsByTaskId = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_FACTORING_MATCHINGS_BY_TASK_ID })
    const {
      isSuccess,
      data,
      message,
      needLogout,
    } = await Factoring.getFactoringMatchingsByTaskId(id)
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({ 
      type: actionTypes.GET_FACTORING_MATCHINGS_BY_TASK_ID_SUCCESS, 
      data,
    })
  } catch (error) {
    console.log(error)
    // dispatch(setErrorContent(error.message))
    dispatch({ type: actionTypes.GET_FACTORING_MATCHINGS_BY_TASK_ID_ERROR })
  }
}

export const getFactoringMatchingsByPaymentId = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_FACTORING_MATCHINGS_BY_PAYMENT_ID })
    const {
      isSuccess,
      data,
      message,
      needLogout,
    } = await Factoring.getFactoringMatchingsByPaymentId(id)
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({ 
      type: actionTypes.GET_FACTORING_MATCHINGS_BY_PAYMENT_ID_SUCCESS, 
      data,
    })
  } catch (error) {
    console.log(error)
    // dispatch(setErrorContent(error.message))
    dispatch({ type: actionTypes.GET_FACTORING_MATCHINGS_BY_PAYMENT_ID_ERROR })
  }
}

export const getFactoringSuppliers = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_FACTORING_SUPPLIERS })
    const {
      isSuccess,
      data,
      message,
      needLogout,
    } = await Factoring.getFactoringSuppliers()
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({ 
      type: actionTypes.GET_FACTORING_SUPPLIERS_SUCCESS, 
      data,
    })
  } catch (error) {
    console.log(error)
    // dispatch(setErrorContent(error.message))
    dispatch({ type: actionTypes.GET_FACTORING_SUPPLIERS_ERROR })
  }
}

export const getFactoringSupplyRegistrySupplyTab = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB })
    const {
      isSuccess,
      data,
      message,
      needLogout,
    } = await Factoring.getFactoringSupplyRegistrySupplyTab(id)
    if (!isSuccess) {
      if (needLogout) {
        dispatch(logoutProcess(message))
        return
      }
      throw new Error(message)
    }
    dispatch({ 
      type: actionTypes.GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB_SUCCESS, 
      data,
    })
  } catch (error) {
    console.log(error)
    // dispatch(setErrorContent(error.message))
    dispatch({ type: actionTypes.GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB_ERROR })
  }
}