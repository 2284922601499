import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  h3: {
    fontSize: 18,
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

export const H3 = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.h3}>{children}</div>
  )
}