import React, { FC, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Divider, Flex, Icon, Spacer, Spinner, Text } from '@mtsbank/ui-kit'

import { Styled } from './styled'
import { CompanyInfo, Description, Document, PhoneCheckModal } from './components'
import { IDataToSignItem } from './declarations'
import { BUTTONS, ERRORS, TEXT } from './constants'
import { useSubmitTask, useTask } from './api/task'
import { useSignDocument } from './hooks'
import { useSignAllDocument } from './hooks/useSiganAllDocuments'
import { useSignAllDocWithoutCert } from './api'
import { LoadingScreen } from '../../components/LoadingScreen/LoadingScreen'

const modal = document.createElement('div')
modal.setAttribute('id', 'modal')
document.body.append(modal)

export const SignDocsV2: FC = () => {
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false)
  const { token } = useParams<{ token: string }>()

  const { data, isLoading: isTaskLoading, error } = useTask(token)
  const { isFetching, submitTask } = useSubmitTask(token)
  const { isSigningAll, signAllError, signAllDocument } = useSignAllDocument()

  const [dataToSign, setDataToSign] = useState<IDataToSignItem[]>([])
  const isSendEnabled = useMemo(() => {
    return dataToSign.length === (data?.task.signDocList.length || -1)
  }, [data, dataToSign])

  const onConfirmCode = async (phone: string) => {
    try {
      await submitTask('SUBMITTED', phone, dataToSign)
      alert('Документы успешно отправлены, форма автоматически закроется через 10 секунд')
      setTimeout(() => window.close(), 10000)
    } catch (error) {
      console.error('Произошла ошибки при отправке документов', error)
    }
  }

  const handleSignButtonClick = () => {
    if (!isSendEnabled) return
    data?.task.phone ? onConfirmCode(data.task.phone) : setIsPhoneModalOpen(true)
  }

  const handleCancelButtonClick = async () => {
    await submitTask('REJECTED')
    setTimeout(() => window.close(), 10000)
  }

  const handleSignAllDocuments = (selectedCert: string) => {
    if (!selectedCert) {
      console.error(ERRORS.CHOOSE_SERTIFICATE_ERROR)
      return
    }
    if (isSigningAll) {
      return
    }
    if (signAllError) {
      return console.error(signAllError)
    }
    if (!data) {
      return console.error(ERRORS.NO_DOCUMENTS_TO_SIGN)
    }
    signAllDocument(selectedCert, data.task.signDocList, setDataToSign, dataToSign)
  }

  if (isSigningAll || isFetching) {
    return <LoadingScreen title={TEXT.IS_SIGNING} description={TEXT.IS_SIGNING_DESC} />
  }

  return (
    <Styled.Wrapper>
      {!data?.isSuccess ? (
        <>
          <section>
            <Styled.Title>
              <CompanyInfo data={data?.task} loading={isTaskLoading} error={error || undefined} />
            </Styled.Title>
          </section>
          <section>
            <Styled.Content>
              <Description />
              <Spacer />
              <Divider />
              <Document
                data={data?.task.signDocList}
                loading={isTaskLoading}
                error={error ? ERRORS.FETCH_DATA_ERRORS : ''}
                dataToSign={dataToSign}
                setDataToSign={setDataToSign}
                onSignAllDocs={handleSignAllDocuments}
              />
              <Spacer />
              <Button onClick={handleCancelButtonClick}>{BUTTONS.REFUSE}</Button>
              <Button disabled={!isSendEnabled} onClick={handleSignButtonClick}>
                {BUTTONS.SEND}
              </Button>
            </Styled.Content>
          </section>
        </>
      ) : (
        <section>
          <Styled.Title>
            <h1>{TEXT.TASK_DONE}</h1>
          </Styled.Title>
        </section>
      )}
      <PhoneCheckModal
        isOpen={isPhoneModalOpen}
        setIsOpen={setIsPhoneModalOpen}
        onConfirmCode={onConfirmCode}
        token={token}
      />
    </Styled.Wrapper>
  )
}
