import * as actionTypes from './actionTypes'

const initialState = {
  list: [],
  isFetching: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH:
      return {
        ...state,
        isFetching: true,
      }
    case actionTypes.SEARCH_SUCCESS:
      return {
        ...state,
        list: action.result,
        isFetching: false,
      }
    case actionTypes.SEARCH_ERROR:
      return {
        ...initialState,
        isFetching: false,
      }
    case actionTypes.SEARCH_CLEAR:
      return { 
        ...initialState, 
      }
    default:
      return state
  }
}