export const TASKS_FETCH = 'TASKS_FETCH'
export const TASKS_SUCCESS = 'TASKS_SUCCESS'
export const TASKS_ERROR = 'TASKS_ERROR'

export const NEXT_TASKS_FETCH = 'NEXT_TASKS_FETCH'
export const NEXT_TASKS_SUCCESS = 'NEXT_TASKS_SUCCESS'
export const NEXT_TASKS_ERROR = 'NEXT_TASKS_ERROR'

export const TASKS_SET_FILTER = 'TASKS_SET_FILTER'
export const TASKS_CLEAR_FILTERS = 'TASKS_CLEAR_FILTERS'
export const TASKS_SET_FILTER_HARD = 'TASKS_SET_FILTER_HARD'

export const TASK_SET_ACTIVE = 'TASK_SET_ACTIVE'
export const TASK_CLEAR_ACTIVE = 'TASK_CLEAR_ACTIVE'
export const INSERT_TASK = 'INSERT_TASK'

export const GET_TASK_USERS = 'GET_TASK_USERS'
export const GET_TASK_USERS_SUCCESS = 'GET_TASK_USERS_SUCCESS'
export const GET_TASK_USERS_ERROR = 'GET_TASK_USERS_ERROR'

export const CHANGE_TASK_USERS = 'CHANGE_TASK_USERS'
export const CHANGE_TASK_USERS_SUCCESS = 'CHANGE_TASK_USERS_SUCCESS'
export const CHANGE_TASK_USERS_ERROR = 'CHANGE_TASK_USERS_ERROR'

export const TASKS_SET_FILTER_CLIENT_NAME = 'TASKS_SET_FILTER_CLIENT_NAME'
