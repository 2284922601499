import React, { useState } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Collapse } from 'reactstrap'
import { Icon } from '../../../../../layout'

const useStyles = createUseStyles({
  root: {
    marginTop: 14,
    padding: [0, 20],
  },
  title: {
    fontSize: 18,
    lineHeight: 32/18,
    fontWeight: 600,
    color: '#000',
    position: 'relative',
    cursor: 'pointer',
  },
  icon: {
    width: 24,
    height: 24,
    display: 'inline-block',
    verticalAlign: 'middle',
    transition: 'transform 300ms ease-in-out',
    position: 'absolute',
    top: '50%',
    right: 10,
    transform: 'translateY(-50%) rotate(180deg)',
    '&.open': {
      transform: 'translateY(-50%) rotate(0deg)',
    },
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
  content: {
    marginTop: 10,
    position: 'relative',
  },
})

export const Block = ({
  title,
  children,
}) => {
  const classes = useStyles()

  const [open, setOpen] = useState(true)

  const makeOpen = () => setOpen(!open)

  return (
    <div className={classes.root}>
      <div className={classes.title} onClick={makeOpen}>
        <span>{title}</span>
        <div className={classnames(classes.icon, { open })}>
          <Icon iconName='arrow-collapse' />
        </div>
      </div>
      <div className={classes.content}>
        <Collapse isOpen={open}>{children}</Collapse>
      </div>
    </div>
  )
}