import * as actionTypes from './actionTypes'
import { registerAgent, registerToken } from '../../services/api/register'
import { toast } from 'react-toastify'

import { setErrorContent } from '../Error/actions'

export const registerAgentAction = (payload, onSuccess, onError) => async dispatch => {
  try {
    dispatch({ type: actionTypes.REGISTER_AGENT })
    const { isSuccess, ...response } = await registerAgent(payload)
    if (isSuccess) {
      dispatch({
        type: actionTypes.REGISTER_AGENT_SUCCESS,
        data: response.order
      })
      onSuccess && onSuccess()
    } else {
      dispatch({
        type: actionTypes.REGISTER_AGENT_ERROR,
        error: response.error
      })
      // toast.error(response.error, { containerId: 'register' })
      onError && onError(response.error)
    }
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({
      type: actionTypes.REGISTER_AGENT_ERROR,
      error: err.message
    })
    toast.error(err.message, { containerId: 'register' })
    // onError && onError(err.message)
  }
}

export const registerTokenAction = (payload, onSuccess, onError) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.REGISTER_TOKEN })
    const { isSuccess, ...response } = await registerToken(payload)
    if (isSuccess) {
      dispatch({ type: actionTypes.REGISTER_TOKEN_SUCCESS })
      onSuccess && onSuccess()
    } else {
      dispatch({
        type: actionTypes.REGISTER_TOKEN_ERROR,
        error: response.error,
      })
      onError && onError()
    }
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ 
      type: actionTypes.REGISTER_TOKEN_ERROR,
      error: err.message,
    })
    onError && onError()
  }
}
