import React from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { ReportContent } from './ReportContent'
import { Title } from '../Layout'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

const useStyles = createUseStyles({
  reports: {
    padding: [20, 0, 250, 0],
  },
  title: {
    color: '#0B1F35',
    fontSize: 36,
    lineHeight: 48/36,
    fontWeight: 600,
  },
  tabs: {
    marginTop: 20,
  },
})

export const FactoringReportsPages = () => {
  const classes = useStyles()
  const { reports } = useSelector(state => state.Settings)
  const ITEMS = reports?.reports || []
  return (
    <section className='fr-content'>
      <div className={classes.reports}>
        <Title>Отчёты</Title>
        <Tabs className={classes.tabs}>
          <TabList>
            {ITEMS.map(({ name }, index) =>
              <Tab key={index}>{name}</Tab>
            )}
          </TabList>
          {ITEMS.map(({ description, url, filters }, index) =>
            <TabPanel key={index}>
              <ReportContent
                description={description}
                url={url}
                filters={filters}
              />
            </TabPanel>
          )}
        </Tabs>
      </div>
    </section>
  )
}