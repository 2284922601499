import React from 'react'
import injectSheet from 'react-jss'
import { adaptive_1200, adaptive_1400 } from '../../../config'

const styles = {
  root: {
    width: 'calc(25% - 10px)',
    [adaptive_1400]: {
      width: 'calc(50% - 11px)',
      marginBottom: 30,
    },
    [adaptive_1200]: {
      width: '100%',
      marginBottom: 20,
    },
  },
}

const Col_md_3 = ({
  children,
  classes,
}) =>
  <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Col_md_3)
