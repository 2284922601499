export const LOGIN_FETCH = 'LOGIN_FETCH';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const AUTH_FETCH = 'AUTH_FETCH';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';

export const REFRESH_TOKEN_FETCH = 'REFRESH_TOKEN_FETCH'
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS'
export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR'

export const LOGOUT_FETCH = 'LOGOUT_FETCH';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const SET_KEYCLOAK = 'SET_KEYCLOAK';

export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_ERROR = 'GET_SETTINGS_ERROR';

export const SET_JWT_OCCURRED = 'SET_JWT_OCCURRED'