import styled from 'styled-components'
import { Icon } from '@mtsbank/ui-kit'

const Select = styled.select`
  width: 100%;
  max-width: 200px;
  min-width: 100px;
`

const Options = styled.option`
  white-space: normal;
  width: 100%;
`

const DocumentButton = styled(Icon)`
  cursor: pointer;
`

export const Styled = { DocumentButton, Options, Select }
