export const allCountries = [
  ['Russia (Россия)', 'ru', '7', '+0 000 000-00-00', 0],
]

export const allCountriesMask = []
allCountries.forEach(item => {
  if (item[5] && Array.isArray(item[5])) {
    item[5].forEach(subItem => {
      allCountriesMask.push({
        mask: `${item[3]}`,
        country: `${item[1]}`,
        start: `${item[2]}${subItem}`,
      })
    })
  } else {
    allCountriesMask.push({
      mask: `${item[3]}`,
      country: `${item[1]}`,
      start: `${item[2]}`,
    })
  }
})

const multivariateCodes = ['1', '7']

const ruMask = {
  mask: '+0 000 000-00-00',
  country: 'ru',
  start: '7',
}

export const getMask = (value, mask, initMask) => {
  if (!value) {
    return ruMask
  }

  if (value && (!(value.indexOf(mask.start) === 0) || multivariateCodes.indexOf(mask.start) !== -1)) {
    return (
      allCountriesMask.find(item => value.indexOf(item.start) === 0) || initMask
    )
  }

  return mask
}

export const isPhone = (phone) => {
  if (phone.length !== 11) {
    return false
  }
  if (phone != null) {
    const digitsArray = phone.match(/\d/g)
    if (digitsArray != null) {
      const digitsString = digitsArray.reduce((result, item) => `${result}${item}`, '')
      const availableCountries = allCountries.filter(
        item =>
          item[2] &&
          item[5] == null &&
          item[2].toString().length <= digitsString.length &&
          digitsString.substr(0, item[2].toString().length) === item[2]
      )

      if (availableCountries.length === 0) {
        return digitsArray && digitsArray.length === 11 // for Russia
      }

      const country = availableCountries.reduce((result, item) =>
        item[2].toString().length > result[2].toString().length
          ? item
          : result,
        availableCountries[0]
      )

      const template = country[3].toString().match(/\d/g)

      return template != null
        ? template.length === digitsArray.length
        : false
    } else {
      return false
    }
  }

  return true // if empty, its maybe email
}
