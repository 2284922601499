import React, { PureComponent } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { KeycloakProvider } from 'react-keycloak'
import keycloak from './keycloak'
import './static/scss/style.scss'
import Theme from './themes'
import { OldBrowser } from './components/OldBrowser'
import Overlay from './components/Overlay'
import { Routes } from './routes.js'
import store from './redux/configureStore'
import { getAppSettings, authenticationUser } from './redux/User/actions'
// import { User } from './services/api'
// import { SET_JWT_OCCURRED } from './redux/User/actionTypes'
import { KeycloakAuthWrapper } from './KeycloakAuthWrapper'
import { isIE } from './utils'
import {
  // getTokenParsed,
  isValidToken,
  onLogin,
  onLogout,
  setToken,
} from './utils/tokenStorage'
import { IS_NEW_AUTH, IS_SECURED_JWT, KEYCLOAK_AUTH_TYPE } from './config'
import { history } from './history'
import { ErrorBoundary } from './components/ErrorBoundary'

class App extends PureComponent {
  state = {
    settingsFetch: true,
    settings: {},
    error: false,
    sessionIsSet: false,
  }

  componentDidMount() {
    const { settings, settingsFetch } = store.getState().User

    this.setState({
      settings, 
      settingsFetch,
    })

    this.unsubscribe = store.subscribe(this.onUpdateAppSettings)
    store.dispatch(getAppSettings())


    this.isStaticPage = 
      /verify/.test(window.location.pathname) ||
      /register/.test(window.location.pathname) ||
      /error/.test(window.location.pathname) ||
      /sign-docs-V2/.test(window.location.pathname)
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  onUpdateAppSettings = () => {
    const { settingsFetch: prevSettingsFetch } = this.state
    const { settings, settingsFetch } = store.getState().User

    if (prevSettingsFetch !== settingsFetch && !settingsFetch) {
      this.setState({ 
        settings, 
        settingsFetch, 
      })
      this.unsubscribe()
    }
  }

  onAuthError = () => this.setState({ error: true })

  onError = (props) => console.log(props)

  onToken = async (token) => {
    await setToken(token)
    if (!this.state.sessionIsSet) {
      store.dispatch(authenticationUser({
        onSuccess: () => this.setState({ sessionIsSet: true }),
        onError: this.onAuthError,
      }))
    }
  }
  
  onEvent = async (event) => {
    console.log(event)
    const LOGIN_EVENTS_DEFAULT = ['onAuthError', 'onAuthRefreshError']
    const LOGIN_EVENTS = IS_SECURED_JWT
      ? LOGIN_EVENTS_DEFAULT
      : [...LOGIN_EVENTS_DEFAULT, 'onReady']
    const LOGOUT_EVENTS = ['onAuthLogout']
    if (LOGIN_EVENTS.indexOf(event) > -1 && !isValidToken()) {
      onLogin()
    }
    if (LOGOUT_EVENTS.indexOf(event) > -1) {
      onLogout()
    }
    if (event === 'onAuthSuccess') {
      // if (!IS_NEW_AUTH) {
      //   await User.setJWT(keycloak.token)
      //   store.dispatch({ type: SET_JWT_OCCURRED })
      // }
    }
  }

  renderApp = (isKeycloakAuthType) => {
    const { error } = this.state
    return (
      <Provider store={store}>
        <Theme>
          <KeycloakAuthWrapper
            isKeycloakAuthType={isKeycloakAuthType}
            error={error}
            onError={this.onAuthError}
          >
            <Router history={history}>
              <ErrorBoundary>
                <Routes isKeycloakAuthType={isKeycloakAuthType}/>
              </ErrorBoundary>
            </Router>
          </KeycloakAuthWrapper>
        </Theme>
      </Provider>
    )
  }

  render() {
    const { settings, settingsFetch } = this.state

    if (isIE) {
      return <OldBrowser />
    }

    if (settings.authType === 'standard' || this.isStaticPage) {
      return this.renderApp(false)
    }

    if (settings.authType === 'keycloak') {
      return IS_NEW_AUTH
        ? <KeycloakProvider
            keycloak={keycloak}
            initConfig={{
              // onLoad: 'login-required',
              onLoad: KEYCLOAK_AUTH_TYPE,
              silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
            }}
            onError={this.onError}
            onToken={this.onToken}
            onEvent={this.onEvent}
          >
            {this.renderApp(true)}
          </KeycloakProvider>
        : this.renderApp(true)
    }

    if (!settingsFetch) {
      return (
        <div
          style={{
            margin: '0 auto',
            width: '80%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            fontSize: '72px',
            fontWeight: 500,
            textAlign: 'center',
          }}
        >
          Ошибка загрузки настроек приложения!
        </div>
      )
    }

    return <Overlay size='big'/>
  }
}

render(<App />, document.getElementById('root'))
