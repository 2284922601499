import * as actionTypes from './actionTypes'
import { setErrorContent } from '../Error/actions'
import { get } from '../../api'

export const getFile = ({
  url,
  name,
  type,
  onSuccess,
  onError,
}) => async (dispatch) => {
  const onErrorCase = (err = {}) => {
    const defaultError = 'Файл недоступен'
    const error = err.message || err.error || defaultError
    dispatch(setErrorContent(error))
    dispatch({
      error,
      type: actionTypes.DOWNLOAD_FILE_ERROR,
    })
    onError && onError()
  }
  dispatch({ type: actionTypes.DOWNLOAD_FILE })
  try {
    const response = await get(url)
    const header = response?.headers?.get('Content-Disposition')
    const parts = header?.split(';')
    const filename = parts && parts?.length > 0 && parts[1]?.split('=')[1]?.replace(/"/g, '')
    if (!response?.error) {
      const arrayBuffer = await response?.arrayBuffer()
      if (arrayBuffer.byteLength > 0) {
        const url = window.URL.createObjectURL(new Blob([arrayBuffer], type ? { type } : {}))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name || filename)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
        dispatch({
          // data: response,
          type: actionTypes.DOWNLOAD_FILE_SUCCESS,
        })
        onSuccess && onSuccess()
      } else {
        onErrorCase()
      }
    } else {
      onErrorCase(response)
    }
  } catch (error) {
    onErrorCase(error)
  }
}