import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { adaptive_1200 } from '../../../config'

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [adaptive_1200]: {
      flexWrap: 'wrap',
    },
  },
}

const Justify = ({ 
  children, 
  className, 
  classes, 
}) => 
  <div className={classnames(classes.root, className)}>
    {children}
  </div>

export default injectSheet(styles)(Justify)
