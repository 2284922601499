import * as actionTypes from './actionTypes'
import { Company } from '../../services/api'

import { logoutProcess } from '../User/actions'
import { setErrorContent } from '../Error/actions'

export const getCompanyOrder = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_COMPANY_ORDER })
    const { isSuccess, ...res } = await Company.getCompanyOrder(id)
    if (!isSuccess) {
      if (res.needLogout) {
        dispatch(logoutProcess(res.message))
        return
      }
      throw new Error(res.message)
    }
    dispatch({ 
      type: actionTypes.GET_COMPANY_ORDER_SUCCESS, 
      data: res, 
    })
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ type: actionTypes.GET_COMPANY_ORDER_ERROR })
  }
}