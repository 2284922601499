import React, { Fragment } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Icon } from '../../../../../layout'
import { formatMoney  } from '../../../../../utils'

const useStyles = createUseStyles({
  root: {
    background: 'rgba(238, 238, 238, 0.7)',
    padding: 30,
    borderRadius: 16,
  },
  block: {
    position: 'relative',
    '& + &': {
      marginTop: 20,
    },
  },
  item: {
    display: 'inline-block',
    verticalAlign: 'middle',
    alignSelf: 'center',
    '& + &': {
      paddingLeft: 20,
    },
    '& svg': {
      width: 24,
      height: 24,
      display: 'block',
    },
  },
  input: {
    background: '#fff',
    height: 38,
    padding: [10, 30],
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 14,
    lineHeight: 1.2,
    fontWeight: 500,
    border: [1, 'dashed', '#ddd'],
    borderRadius: 16,
    textAlign: 'center',
    outline: 'none',
  },
  title: {
    paddingBottom: 10,
    color: '#504e5b',
    fontSize: 18,
    lineHeight: 1.8,
    fontWeight: 500,
  },
  subtitle: {
    minWidth: 110,
    maxWidth: 220,
    color: '#000',
    fontSize: 10,
    lineHeight: 1.8,
    fontWeight: 500,
    letterSpacing: 1,
    textTransform: 'uppercase',
    opacity: 0.5,
  },
  barWrapper: {
    width: '100%',
    height: 60,
    borderRadius: 30,
    position: 'relative',
  },
  bar: {
    height: '100%',
    position: 'absolute',
    borderRadius: 'inherit',
    transition: 'width 1000ms ease',
    zIndex: 2,
    '&.total': {
      width: '100%',
    },
  },
  legend: {
    padding: [17, 50, 14],
    border: [1, 'solid', '#ddd'],
    borderRadius: 16,
    display: 'flex',
    justifyContent: 'space-between',
  },
  value: {
    minHeight: 32,
    marginTop: 3,
    color: '#000',
    fontSize: 20,
    lineHeight: 1.6,
    fontWeight: 500,
    position: 'relative',
    '& span': {
      display: 'block',
      width: 10,
      height: 10,
      borderRadius: '50%',
      position: 'absolute',
      left: -20,
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  constraint: {
    background: '#E4E4E4',
    padding: 25,
    borderRadius: 16,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  gap: {
    height: 10,
  },
})

const COLORS = {
  used: '#8338EC',
  frozen: '#3A86FF',
  total: '#22DBAE',
}

export const Limits = ({ limits }) => {
  const classes = useStyles()

  const {
    totalAmount,
    utilizedAmount,
    frozenAmount,
    remainAmount,
    createdDate,
    endDate,
    maxOrderAmount,
    constraints = [],
  } = limits || {}

  const VALUES = [
    { title: 'Дата установки', value: createdDate },
    { title: 'Дата окончания', value: endDate },
    { title: 'Расчётный лимит', value: formatMoney(totalAmount) },
    maxOrderAmount
      ? { title: 'Максимальная сумма 1й гарантии', value: formatMoney(maxOrderAmount) }
      : null,
  ]

  const LEGEND = [
    { key: 'used', text: 'Использовано', color: COLORS.used, value: formatMoney(utilizedAmount) },
    { key: 'frozen', text: 'Заморожено', color: COLORS.frozen, value: formatMoney(frozenAmount) },
    { key: 'total', text: 'Доступно', color: COLORS.total, value: formatMoney(remainAmount) },
  ]

  const used = utilizedAmount && totalAmount ? (utilizedAmount / totalAmount) * 100 : 0
  const frozen = frozenAmount && totalAmount ? (frozenAmount / totalAmount) * 100 : 0

  return (
    <div className={classes.root}>
      {limits
        ? <>
            <div className={classes.block}>
              {VALUES.map((item, index) => item 
                ? <div className={classes.item} key={index}>
                    <div className={classes.subtitle}>{item.title}</div>
                    <div className={classes.input}>{item.value}</div>
                  </div>
                : null
              )}
            </div>
            <div className={classes.block}>
              <div className={classes.barWrapper}>
                <div className={classnames(classes.bar, 'total')} style={{ backgroundColor: COLORS.total }} />
                <div className={classes.bar} style={{ backgroundColor: COLORS.frozen, width: `${used + frozen}%` }} />
                <div className={classes.bar} style={{ backgroundColor: COLORS.used, width: `${used}%` }} />
              </div>
            </div>
            <div className={classes.block}>
              <div className={classes.legend}>
                {LEGEND.map(({ key, text, color, value }) =>
                  <div key={key}>
                    <div className={classes.subtitle}>{text}</div>
                    <div className={classes.value}>
                      <span style={{ background: color }} />
                      {value}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {constraints?.length > 0
              ? <div className={classes.block}>
                  <div className={classes.title}>Условия</div>
                  {constraints.map(({
                    limitAmount,
                    maxOrderAmount,
                    prepaidPercent,
                    startMaxOrderAmount,
                  }, index) => {
                    const items = [
                      startMaxOrderAmount ? { title: 'НМЦК', value: formatMoney(startMaxOrderAmount) } : null,
                      startMaxOrderAmount ? { icon: 'plus' } : null,
                      maxOrderAmount ? { title: 'Максимальная сумма 1й гарантии', value: formatMoney(maxOrderAmount) } : null,
                      prepaidPercent ? { icon: 'plus' } : null,
                      prepaidPercent ? { title: 'Размер аванса', value: `${prepaidPercent}%` } : null,
                      { icon: 'arrowRight' },
                      limitAmount ? { title: 'Доступный лимит для выдачи гарантии в рамках условий', value: formatMoney(limitAmount) } : null,
                    ]
                    return (
                      <Fragment key={index}>
                        {index > 0 &&
                          <div className={classes.gap} />
                        }
                        <div className={classes.constraint} key={index}>
                          {items.map((item, idx) => item
                            ? <div className={classes.item} key={idx}>
                                {item.icon
                                  ? <Icon iconName={item.icon} />
                                  : <>
                                      <div className={classes.subtitle}>{item.title}</div>
                                      <div className={classes.value}>{item.value}</div>
                                    </>
                                }
                              </div>
                            : null
                          )}
                        </div>
                      </Fragment>
                    )
                  })}
                </div>
            : null
            }
          </>
        : 'Лимиты не установлены'
      }
    </div>
  )
}