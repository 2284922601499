import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Link } from 'react-router-dom'
import _get from 'lodash/get'
import { Icon } from '../../../layout'
import { formatNumber, plural } from '../../../utils'
import { COLOR_VALUES, COLOR_VALUES_MIDDLE, COLOR_VALUES_LIGHT, adaptive_1200 } from '../../../config'

const styles = {
  root: {
    width: '100%',
    marginTop: 8,
    position: 'relative',
  },
  link: {
    background: '#fff',
    padding: 20,
    borderRadius: 8,
    display: 'block',
    [adaptive_1200]: {
      padding: 16,
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 8,
    fill: '#bbb',
    display: 'inline-block',
    verticalAlign: 'top',
  },
  displayName: {
    color: '#242232',
    fontSize: 20,
    lineHeight: 1.2,
    fontWeight: 600,
  },
  inn: {
    color: '#4a4a4a',
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: 600,
    position: 'absolute',
    top: 20,
    right: 20,
    [adaptive_1200]: {
      marginTop: 8,
      top: 'auto',
      right: 'auto',
      position: 'relative',
      display: 'block',
    },
  },
  type: {
    marginTop: 8,
    color: '#bbb',
    fontSize: 11,
    lineHeight: 1.2,
    fontWeight: 600,
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    display: 'block',
  },
  list: {
    marginTop: 8,
    display: 'block',
  },
  item: {
    display: 'inline-block',
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
    [adaptive_1200]: {
      display: 'block',
    },
    '& + &': {
      marginLeft: 4,
      [adaptive_1200]: {
        marginLeft: 0,
        marginTop: 4,
      },
    },
    '& > span': {
      '&:first-child': {
        height: 22,
        padding: [3, 10],
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 8,
        '& > svg': {
          width: 14,
          height: 14,
          marginRight: 4,
          display: 'inline-block',
          verticalAlign: 'top',
        },
      },
      fontSize: 11,
      lineHeight: 1.4,
      fontWeight: 600,
      letterSpacing: '0.03em',
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  amount: {
    color: '#bbb',
    fontSize: 12,
    lineHeight: 1.4,
    fontWeight: 600,
    position: 'absolute',
    bottom: 20,
    right: 20,
    [adaptive_1200]: {
      marginTop: 8,
      bottom: 'auto',
      right: 'auto',
      position: 'relative',
      display: 'block',
    },
  },
}

const ClientCard = ({
  classes,
  id,
  displayName,
  INN,
  // KPP,
  // OGRN,
  stats,
  statusItems,
}) => {
  const showTotalAmount = stats && stats.total && stats.total.amount > 0
  const isIP = displayName.slice(0,2).toLocaleLowerCase() === 'ип'
  return (
    <div className={classes.root}>
      <Link className={classes.link} to={`/clients/${id}`}>
        <Icon className={classes.icon} iconName={isIP ? 'user3' : 'company'} />
        <span className={classes.displayName} dangerouslySetInnerHTML={{ __html: displayName }} />
        <span className={classes.inn}>
          <span>ИНН:</span>
          {' '}
          <span dangerouslySetInnerHTML={{ __html: INN }} />
        </span>
        <span className={classes.type}>Заявки</span>
        <span className={classes.list}>
          {statusItems.map(({ key, className }) => key === 'total'
            ? null
            : stats[key] && stats[key].count
              ? <span className={classes.item} key={key}>
                  <span style={{
                    color: COLOR_VALUES[className],
                    borderColor: COLOR_VALUES_MIDDLE[className],
                    background: COLOR_VALUES_LIGHT[className],
                  }}>
                    <Icon iconName='ok' style={{ fill: COLOR_VALUES[className] }} />
                    <span>
                      {formatNumber(stats[key].count)}
                      {className === 'green' &&
                        `
                          ${plural(stats[key].count, 'успешная сделка', 'успешные сделки', 'успешных сделок')}
                          ${' '}
                          на общую сумму ${formatNumber(stats[key].amount)} ₽
                        `
                      }
                    </span>
                  </span>
                </span>
              : null
          )}
        </span>
        {showTotalAmount &&
          <span className={classes.amount}>
            Общая сумма: {' '}
            {`${formatNumber(_get(stats, 'total.amount', 0), true)} ₽`}
          </span>
        }
      </Link>
    </div>
  )
}

ClientCard.propTypes = {
  id: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  INN: PropTypes.string,
  KPP: PropTypes.string,
  OGRN: PropTypes.string,
  stats: PropTypes.shape({
    amount: PropTypes.number,
    assigned: PropTypes.number,
    inprogress: PropTypes.number,
    lost: PropTypes.number,
    sold: PropTypes.number,
  }).isRequired,
  statusItems: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    className: PropTypes.string
  })),
}

ClientCard.defaultProps = {
  displayName: '<i style="font-style: italic; color: #ccc;">Название не указано</i>',
  INN: '<i style="font-style: italic; color: #ccc;">Не указано</i>',
  KPP: '<i style="font-style: italic; color: #ccc;">Не указано</i>',
  OGRN: '<i style="font-style: italic; color: #ccc;">Не указано</i>',
  statusItems: [],
}

export default injectSheet(styles)(ClientCard)