import axios from 'axios'
import { SERVER } from '../constants'

export const onVerify = async (token) => {
  try {
    const { data: { error_code: status, ...rest } } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/verify/${token}`,
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 5,
      error: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
   }
  }
}