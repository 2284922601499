import * as actionTypes from './actionTypes'

const initialState = {
  data: [],
  isFetching: false,
  error: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DOWNLOAD_FILE:
      return {
        ...state,
        isFetching: true,
      }
    case actionTypes.DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      }
    case actionTypes.DOWNLOAD_FILE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error.message,
      }
    default:
      return state
  }
}