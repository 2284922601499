import axios from 'axios'
import { SERVER } from '../constants'

function createHash(string) {
  let hash = 0
  if (string.length === 0) {
    return hash
  }

  for (let i = 0; i < string.length; i++) {
    const chr   = string.charCodeAt(i)
    hash  = ((hash << 5) - hash) + chr
    hash |= 0 // Convert to 32bit integer
  }

  return `${-hash}`
}

export const findByString = async (query) => {
  try {
    const {data: {error_code, ...rest}} = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.SPA_ENDPOINT}/company/filter?q=${query}`,
    })
    if (error_code === 0) {
      return {
        isSuccess: true,
        list: rest.company,
      }
    }
    return {
      isSuccess: false,
      needLogout: error_code === 5,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const selectByString = async (query) => {
  try {
    const {data: {error_code, ...rest }} = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/company/select?q=${query}`,
    })
    if (error_code === 0) {
      const preparedOther = rest.other.map((item) => ({
        ...item,
        id: {
          companyTypeRefId: item.companyTypeRefId,
          INN: item.INN,
          displayName: item.displayName,
          fullName: item.fullName,
          OGRN: item.OGRN,
        },
        key: createHash(new Date().getTime().toString())
      }))
      return {
        isSuccess: true,
        list: [
          ...rest.elastic,
          ...preparedOther,
        ],
      }
    }
    return {
      isSuccess: false,
      needLogout: error_code === 5,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringContractSuggestions = async (query) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/view/grid/FACTORING_CONTRACT?numberLike=${query}`,
    })
    return {
      isSuccess: true,
      list: data.data,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getSupplyContractSuggestions = async (query) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/view/grid/SUPPLY_CONTRACT?numberLike=${query}`,
    })
    return {
      isSuccess: true,
      list: data.data,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getDebtorSuggestions = async (query) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/view/grid/DEBTOR?q=${query}`,
    })
    return {
      isSuccess: true,
      list: data?.data || [],
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getSupplierSuggestions = async (query) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/view/grid/SUPPLIER?q=${query}`,
    })
    return {
      isSuccess: true,
      list: data?.data || [],
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getSuggestionsByUrl = async (query, url, autocompleteParam, autocompleteResponsePath) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${url}?${autocompleteParam}=${query}`,
    })
    return {
      isSuccess: true,
      list: autocompleteResponsePath
        ? data[autocompleteResponsePath] || []
        : data?.data || [],
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}