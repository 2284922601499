export const GET_FACTORING_TASKS = 'GET_FACTORING_TASKS'
export const GET_FACTORING_TASKS_SUCCESS = 'GET_FACTORING_TASKS_SUCCESS'
export const GET_FACTORING_TASKS_ERROR = 'GET_FACTORING_TASKS_ERROR'

export const COMPLETE_FACTORING_TASK = 'COMPLETE_FACTORING_TASK'
export const COMPLETE_FACTORING_TASK_SUCCESS = 'COMPLETE_FACTORING_TASK_SUCCESS'
export const COMPLETE_FACTORING_TASK_ERROR = 'COMPLETE_FACTORING_TASK_ERROR'

export const GET_FACTORING_VIEW = 'GET_FACTORING_VIEW'
export const GET_FACTORING_VIEW_SUCCESS = 'GET_FACTORING_VIEW_SUCCESS'
export const GET_FACTORING_VIEW_ERROR = 'GET_FACTORING_VIEW_ERROR'

export const GET_FACTORING_CONTRACTS_BY_SUPPLIER_INN = 'GET_FACTORING_CONTRACTS_BY_SUPPLIER_INN'
export const GET_FACTORING_CONTRACTS_BY_SUPPLIER_INN_SUCCESS = 'GET_FACTORING_CONTRACTS_BY_SUPPLIER_INN_SUCCESS'
export const GET_FACTORING_CONTRACTS_BY_SUPPLIER_INN_ERROR = 'GET_FACTORING_CONTRACTS_BY_SUPPLIER_INN_ERROR'

export const GET_FACTORING_SHORT_INFO = 'GET_FACTORING_SHORT_INFO'
export const GET_FACTORING_SHORT_INFO_SUCCESS = 'GET_FACTORING_SHORT_INFO_SUCCESS'
export const GET_FACTORING_SHORT_INFO_ERROR = 'GET_FACTORING_SHORT_INFO_ERROR'

export const SEND_FACTORING_MESSAGE = 'SEND_FACTORING_MESSAGE'
export const SEND_FACTORING_MESSAGE_SUCCESS = 'SEND_FACTORING_MESSAGE_SUCCESS'
export const SEND_FACTORING_MESSAGE_ERROR = 'SEND_FACTORING_MESSAGE_ERROR'

export const GET_FACTORING_MESSAGES = 'GET_FACTORING_MESSAGES'
export const GET_FACTORING_MESSAGES_SUCCESS = 'GET_FACTORING_MESSAGES_SUCCESS'
export const GET_FACTORING_MESSAGES_ERROR = 'GET_FACTORING_MESSAGES_ERROR'

export const CREATE_FACTORING_LIMIT = 'CREATE_FACTORING_LIMIT'
export const CREATE_FACTORING_LIMIT_SUCCESS = 'CREATE_FACTORING_LIMIT_SUCCESS'
export const CREATE_FACTORING_LIMIT_ERROR = 'CREATE_FACTORING_LIMIT_ERROR'

export const CREATE_FACTORING_RATE = 'CREATE_FACTORING_RATE'
export const CREATE_FACTORING_RATE_SUCCESS = 'CREATE_FACTORING_RATE_SUCCESS'
export const CREATE_FACTORING_RATE_ERROR = 'CREATE_FACTORING_RATE_ERROR'

export const CREATE_FACTORING_BLOCK = 'CREATE_FACTORING_BLOCK'
export const CREATE_FACTORING_BLOCK_SUCCESS = 'CREATE_FACTORING_BLOCK_SUCCESS'
export const CREATE_FACTORING_BLOCK_ERROR = 'CREATE_FACTORING_BLOCK_ERROR'

export const CREATE_FACTORING_ACCOUNT = 'CREATE_FACTORING_ACCOUNT'
export const CREATE_FACTORING_ACCOUNT_SUCCESS = 'CREATE_FACTORING_ACCOUNT_SUCCESS'
export const CREATE_FACTORING_ACCOUNT_ERROR = 'CREATE_FACTORING_ACCOUNT_ERROR'

export const START_FACTORING_PROCESS = 'START_FACTORING_PROCESS'
export const START_FACTORING_PROCESS_SUCCESS = 'START_FACTORING_PROCESS_SUCCESS'
export const START_FACTORING_PROCESS_ERROR = 'START_FACTORING_PROCESS_ERROR'

export const GET_FACTORING_REF_DOCUMENTS = 'GET_FACTORING_REF_DOCUMENTS'
export const GET_FACTORING_REF_DOCUMENTS_SUCCESS = 'GET_FACTORING_REF_DOCUMENTS_SUCCESS'
export const GET_FACTORING_REF_DOCUMENTS_ERROR = 'GET_FACTORING_REF_DOCUMENTS_ERROR'

export const GET_FACTORING_DEBTORS = 'GET_FACTORING_DEBTORS'
export const GET_FACTORING_DEBTORS_SUCCESS = 'GET_FACTORING_DEBTORS_SUCCESS'
export const GET_FACTORING_DEBTORS_ERROR = 'GET_FACTORING_DEBTORS_ERROR'

export const GET_FACTORING_DUPLICATE_SETTINGS = 'GET_FACTORING_DUPLICATE_SETTINGS'
export const GET_FACTORING_DUPLICATE_SETTINGS_SUCCESS = 'GET_FACTORING_DUPLICATE_SETTINGS_SUCCESS'
export const GET_FACTORING_DUPLICATE_SETTINGS_ERROR = 'GET_FACTORING_DUPLICATE_SETTINGS_ERROR'

export const GET_FACTORING_PRICE_MATRIX = 'GET_FACTORING_PRICE_MATRIX'
export const GET_FACTORING_PRICE_MATRIX_SUCCESS = 'GET_FACTORING_PRICE_MATRIX_SUCCESS'
export const GET_FACTORING_PRICE_MATRIX_ERROR = 'GET_FACTORING_PRICE_MATRIX_ERROR'

export const GET_FACTORING_ACCOUNT_ENTITY_TYPES = 'GET_FACTORING_ACCOUNT_ENTITY_TYPES'
export const GET_FACTORING_ACCOUNT_ENTITY_TYPES_SUCCESS = 'GET_FACTORING_ACCOUNT_ENTITY_TYPES_SUCCESS'
export const GET_FACTORING_ACCOUNT_ENTITY_TYPES_ERROR = 'GET_FACTORING_ACCOUNT_ENTITY_TYPES_ERROR'

export const GET_FACTORING_REF_RATE_TYPES = 'GET_FACTORING_REF_RATE_TYPES'
export const GET_FACTORING_REF_RATE_TYPES_SUCCESS = 'GET_FACTORING_REF_RATE_TYPES_SUCCESS'
export const GET_FACTORING_REF_RATE_TYPES_ERROR = 'GET_FACTORING_REF_RATE_TYPES_ERROR'

export const GET_FACTORING_REF_BLOCK_TYPES = 'GET_FACTORING_REF_BLOCK_TYPES'
export const GET_FACTORING_REF_BLOCK_TYPES_SUCCESS = 'GET_FACTORING_REF_BLOCK_TYPES_SUCCESS'
export const GET_FACTORING_REF_BLOCK_TYPES_ERROR = 'GET_FACTORING_REF_BLOCK_TYPES_ERROR'

export const GET_FACTORING_REF_OPERATION_TYPES = 'GET_FACTORING_REF_OPERATION_TYPES'
export const GET_FACTORING_REF_OPERATION_TYPES_SUCCESS = 'GET_FACTORING_REF_OPERATION_TYPES_SUCCESS'
export const GET_FACTORING_REF_OPERATION_TYPES_ERROR = 'GET_FACTORING_REF_OPERATION_TYPES_ERROR'

export const GET_FACTORING_REF_ACCOUNT_TYPES = 'GET_FACTORING_REF_ACCOUNT_TYPES'
export const GET_FACTORING_REF_ACCOUNT_TYPES_SUCCESS = 'GET_FACTORING_REF_ACCOUNT_TYPES_SUCCESS'
export const GET_FACTORING_REF_ACCOUNT_TYPES_ERROR = 'GET_FACTORING_REF_ACCOUNT_TYPES_ERROR'

export const GET_FACTORING_REF_OFFERING_KEYS = 'GET_FACTORING_REF_OFFERING_KEYS'
export const GET_FACTORING_REF_OFFERING_KEYS_SUCCESS = 'GET_FACTORING_REF_OFFERING_KEYS_SUCCESS'
export const GET_FACTORING_REF_OFFERING_KEYS_ERROR = 'GET_FACTORING_REF_OFFERING_KEYS_ERROR'

export const GET_FACTORING_PAYMENT_ORDERS = 'GET_FACTORING_PAYMENT_ORDERS'
export const GET_FACTORING_PAYMENT_ORDERS_SUCCESS = 'GET_FACTORING_PAYMENT_ORDERS_SUCCESS'
export const GET_FACTORING_PAYMENT_ORDERS_ERROR = 'GET_FACTORING_PAYMENT_ORDERS_ERROR'

export const GET_FACTORING_MATCHINGS_BY_TASK_ID = 'GET_FACTORING_MATCHINGS_BY_TASK_ID'
export const GET_FACTORING_MATCHINGS_BY_TASK_ID_SUCCESS = 'GET_FACTORING_MATCHINGS_BY_TASK_ID_SUCCESS'
export const GET_FACTORING_MATCHINGS_BY_TASK_ID_ERROR = 'GET_FACTORING_MATCHINGS_BY_TASK_ID_ERROR'

export const GET_FACTORING_MATCHINGS_BY_PAYMENT_ID = 'GET_FACTORING_MATCHINGS_BY_PAYMENT_ID'
export const GET_FACTORING_MATCHINGS_BY_PAYMENT_ID_SUCCESS = 'GET_FACTORING_MATCHINGS_BY_PAYMENT_ID_SUCCESS'
export const GET_FACTORING_MATCHINGS_BY_PAYMENT_ID_ERROR = 'GET_FACTORING_MATCHINGS_BY_PAYMENT_ID_ERROR'

export const GET_FACTORING_SUPPLIERS = 'GET_FACTORING_SUPPLIERS'
export const GET_FACTORING_SUPPLIERS_SUCCESS = 'GET_FACTORING_SUPPLIERS_SUCCESS'
export const GET_FACTORING_SUPPLIERS_ERROR = 'GET_FACTORING_SUPPLIERS_ERROR'

export const GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB = 'GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB'
export const GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB_SUCCESS = 'GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB_SUCCESS'
export const GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB_ERROR = 'GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB_ERROR'