export const isOGRN = (ogrn, error) => {
  let result = false
  if (typeof ogrn === 'number') {
    ogrn = ogrn.toString()
  } else if (typeof ogrn !== 'string') {
    ogrn = ''
  }
  if (!ogrn.length) {
    error.code = 1
    error.message = 'ОГРН пуст'
  } else if (/[^0-9]/.test(ogrn)) {
    error.code = 2
    error.message = 'ОГРН может состоять только из цифр'
  } else if ([13, 15].indexOf(ogrn.length) === -1) {
    error.code = 3
    error.message = 'ОГРН может состоять только из 13 или 15 цифр'
  } else if (ogrn.length === 13) {
    let n13 = parseInt((parseInt(ogrn.slice(0, -1)) % 11).toString().slice(-1))
    result = n13 === parseInt(ogrn[12])
  } else if (ogrn.length === 15) {
    let n15 = parseInt((parseInt(ogrn.slice(0, -1)) % 13).toString().slice(-1))
    result = n15 === parseInt(ogrn[14])
  }
  if (!result) {
    error.code = 4
    error.message = 'Неправильное контрольное число'
  }
  return result;
}