import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Select2 } from '../../layout'
import { getPaginationSize, setPaginationSize } from '../../utils/paginationStorage'

const useStyles = createUseStyles({
  pagination: {
    marginTop: 20,
    position: 'relative',
  },
  item: {
    display: 'inline-block',
    verticalAlign: 'top',
    '& + &': {
      marginLeft: 10,
    },
  },
  link: {
    background: '#fff',
    width: 36,
    height: 36,
    padding: [11, 0],
    color: '#242232',
    fontSize: 13,
    lineHeight: 1,
    fontWeight: 600,
    border: [1, 'solid', '#B6BCC3'],
    textAlign: 'center',
    display: 'block',
    cursor: 'pointer',
    '&:hover': {
      background: '#fff',
      color: '#242232',
      textDecoration: 'none',
    },
    '&.break': {

    },
    '&.prev': {
      backgroundImage: `url(${require('./arrow-left.svg')})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    },
    '&.next': {
      backgroundImage: `url(${require('./arrow-right.svg')})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    },
  },
  active: {
    background: '#0b1f35',
    color: '#fff',
    '&:hover': {
      background: '#0b1f35',
      color: '#fff',
    },
  },
  disabled: {
    opacity: 0.5,
    '& a': {
      cursor: 'default',
    },
  },
  legend: {
    marginTop: 10,
    fontSize: 10,
  },
  size: {
    width: 150,
    position: 'absolute',
    top: 0,
    right: 0,
    '& select': {
      height: 36,
      padding: [0, 20, 0, 10],
      color: '#242232',
      fontSize: 13,
      lineHeight: 1,
      fontWeight: 600,
      border: [1, 'solid', '#B6BCC3'],
    },
  },
  select: {
    height: 36,
    '& svg': {
      top: 6,
      right: 6,
    },
  },
})

export const Pagination = ({
  page,
  pages,
  total,
  onGetItems,
}) => {
  const classes = useStyles()

  const SIZE = getPaginationSize()

  const [currentPage, setCurrentPage] = useState(page)
  const [size, setSize] = useState(SIZE)

  useEffect(() => {
    if (currentPage > pages) {
      setCurrentPage(pages)
    }
  }, [currentPage, pages])

  if (!currentPage || !pages || !total) {
    return null
  }

  const start = (currentPage - 1) * size + 1
  const stop = pages === currentPage ? total : currentPage * size

  const sizes = [
    { name: 'Показать: 10', value: 10, disabled: false },
    { name: 'Показать: 20', value: 20, disabled: false },
    { name: 'Показать: 30', value: 30, disabled: false },
    { name: 'Показать: 40', value: 40, disabled: false },
    { name: 'Показать: 50', value: 50, disabled: false },
  ]

  const onChangeSize = (e) => {
    const size = e.target.value
    setPaginationSize(size)
    const page = currentPage * size > total
      ? Math.ceil(total / size)
      : currentPage
    onGetItems(page, size)
  }

  const onPageChange = ({ selected }) => {
    let page = selected + 1
    setCurrentPage(page)
    setSize(SIZE)
    onGetItems(page, size)
  }

  if (pages <= 1) {
    return null
  }

  return (
    <div className={classes.pagination}>
      <ReactPaginate
        previousLabel=''
        nextLabel=''
        breakLabel={'...'}
        pageCount={pages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={onPageChange}
        containerClassName={classes.paginate}
        pageClassName={classes.item}
        pageLinkClassName={classes.link}
        breakClassName={classes.item}
        breakLinkClassName={classnames(classes.link, 'break')}
        previousClassName={classes.item}
        previousLinkClassName={classnames(classes.link, 'prev')}
        nextClassName={classes.item}
        nextLinkClassName={classnames(classes.link, 'next')}
        activeLinkClassName={classes.active}
        disabledClassName={classes.disabled}
        forcePage={currentPage - 1}
      />
      <div className={classes.legend}>Показано с {start} по {stop} из {total}</div>
      <div className={classes.size}>
        <Select2
          className={classes.select}
          label=''
          id='size'
          name='size'
          defaultValue={size}
          options={sizes}
          onChange={onChangeSize}
        />
      </div>
    </div>
  )
}