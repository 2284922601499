import React from 'react'
import { Styled } from './styled'
import { DESCRIPTION } from '../../constants'

export const Description = () => {
  return (
    <Styled.DescriptionWrapper>
      <Styled.DescriptionTitle>{DESCRIPTION.TITLE}</Styled.DescriptionTitle>
      <Styled.DescriptionList>
        <Styled.DescriptionItem>{DESCRIPTION.CHECK_DOC}</Styled.DescriptionItem>
        <Styled.DescriptionItem>{DESCRIPTION.SIGN_IT}</Styled.DescriptionItem>
        <Styled.DescriptionItem>{DESCRIPTION.SEND_DOC}</Styled.DescriptionItem>
      </Styled.DescriptionList>
    </Styled.DescriptionWrapper>
  )
}
