import React, { Fragment } from 'react'
import Modal from '../index'

const Documents = ({ 
  title,
  documents, 
  className,
  emailToken,
}) => {
  const documentsIsset = documents && documents.length

  if (!documentsIsset) {
    return null
  }

  return (
    <Modal.Block iconName='document' title={title} emailToken={emailToken}>
      <Modal.Content className={className}>
        <Fragment>
          {documents.map(({ name, files }, index) =>
            <Modal.Item
              key={index}
              name={name} 
              files={files}
            />
          )}
        </Fragment>
        {/* <Modal.DownloadAllButton file={{ name: 'Имя файла.doc', url: '#' }} /> */}
      </Modal.Content>
    </Modal.Block>
  )
}

export default Documents