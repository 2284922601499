import React from 'react'
import PropTypes from 'prop-types'

import Overlay from '../../Overlay'

const Skelet = ({ showLoader }) => (
  <div className="block-list__item">
    <div className="block-list__row">
      <div className="stages-progress">
        <span className="preloader-field preloader-field--circle">
          <span className="preloader-field__bar" />
        </span>
        <span className="preloader-field preloader-field--circle">
          <span className="preloader-field__bar" />
        </span>
        <span className="preloader-field preloader-field--circle">
          <span className="preloader-field__bar" />
        </span>
        <span className="preloader-field preloader-field--circle">
          <span className="preloader-field__bar" />
        </span>
        <span className="preloader-field preloader-field--circle">
          <span className="preloader-field__bar" />
        </span>
      </div>
      <div className="block-list__posted-time block-list__posted-time--active">
        <span>
          <span className="preloader-field">
            <span className="preloader-field__bar" style={{ width: '300px' }} />
          </span>
        </span>
      </div>
    </div>
    <div className="block-list__row">
      <div>
        <div className="block-list__info block-list__info--with-icon">
          <span className="icon icon-user" />
          <span>
            <span className="preloader-field">
              <span className="preloader-field__bar" style={{ width: '300px' }} />
            </span>
          </span>
        </div>
        <div className="block-list__info">
          <span className="preloader-field">
            <span className="preloader-field__bar" style={{ width: '240px' }} />
          </span>
        </div>
      </div>
      <div>
        <span className="preloader-field preloader-field--big">
          <span className="preloader-field__bar" style={{ width: '300px' }} />
        </span>
      </div>
    </div>
    <div className="block-list__row">
      <div className="block-list__desc">
        <span className="preloader-field">
          <span className="preloader-field__bar" style={{ width: '300px' }} />
        </span>
      </div>
      {/* <button className='btn btn-primary btn-primary--with-icon'>
        Проверить и отправить
        <i className='icon icon-chevron-right' />
      </button> */}
    </div>
    {showLoader && <Overlay />}
  </div>
)

Skelet.propTypes = { showLoader: PropTypes.bool }
Skelet.defaultProps = { showLoader: false }

export default Skelet
