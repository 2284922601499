import axios from 'axios'
import { getUserCertificates, createDetachedSignature, Certificate, createHash, createXMLSignature } from 'crypto-pro'
import { IDataToSignItem, ISignDoc } from '../../declarations'

export const fetchUserCertificates = async (): Promise<Certificate[]> => {
  const certificates = await getUserCertificates()
  return certificates
}

export const signDocument = async (
  certificateThumbprint: string,
  documentUrl: string,
  documentName: string
): Promise<string> => {
  const dataToSignHash = await axios.get(documentUrl, { responseType: 'arraybuffer' }).then(res => createHash(res.data))
  if (documentName.endsWith('.XML')) {
    return await createXMLSignature(certificateThumbprint, dataToSignHash)
  } else {
    return await createDetachedSignature(certificateThumbprint, dataToSignHash)
  }
}

export const signAllDocuments = async (
  certificateThumbprint: string,
  documents: ISignDoc[],
  dataToSign: IDataToSignItem[]
) => {
  const signedDocuments = []
  for (const doc of documents) {
    const isAlreadySigned = dataToSign.some(signedDoc => signedDoc.filename === doc.name)
    if (isAlreadySigned) {
      continue
    }
    let signedData = ''
    const dataToSignHash = await axios.get(doc.url, { responseType: 'arraybuffer' }).then(res => createHash(res.data))
    if (doc.name.endsWith('.XML')) {
      signedData = await createXMLSignature(certificateThumbprint, dataToSignHash)
      signedDocuments.push({
        content: signedData,
        filename: doc.name + '.sig',
        fileId: doc.id
      })
    } else {
      signedData = await createDetachedSignature(certificateThumbprint, dataToSignHash)
      signedDocuments.push({
        content: signedData,
        filename: doc.name + '.sig',
        fileId: doc.id
      })
    }
  }
  alert('Документы успешно подписаны')
  return signedDocuments
}
