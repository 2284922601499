import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'
import Overlay from '../../../../../components/Overlay'
import { Icon } from '../../../../../layout'
import { downloadZip } from '../../../../../utils/downloadZip'

const useStyles = createUseStyles({
  root: {
    background: '#eee',
    padding: 20,
    borderRadius: 16,
    position: 'relative',
  },
  item: {
    width: 'calc(33.33% - 20px)',
    margin: 10,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  link: {
    background: '#E4E4E4',
    height: 160,
    padding: [14, 20, 19, 20],
    borderRadius: 16,
    display: 'block',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  name: {
    paddingLeft: 30,
    fontSize: 18,
    lineHeight: 24/18,
    fontWeight: 500,
    color: '#000',
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
  },
  file: {
    marginTop: 12,
    fontSize: 14,
    lineHeight: 18/14,
    fontWeight: 500,
    opacity: 0.5,
    color: '#000',
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
  },
  icon: {
    fill: '#504E5B',
    width: 20,
    height: 20,
    display: 'block',
    position: 'absolute',
    top: 4,
    left: 0,
  },
  download: {
    background: '#818088',
    width: 260,
    height: 40,
    margin: 10,
    padding: [8, 24],
    borderRadius: 8,
    textAlign: 'center',
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    position: 'relative',
    '&.downloading': {
      cursor: 'default',
    },
    '& > svg': {
      width: 24,
      height: 24,
      marginRight: 10,
      fill: '#fff',
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& > span': {
      color: '#fff',
      fontSize: 16,
      lineHeight: 24/16,
      fontWeight: 600,
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& .preloader': {
      borderRadius: 'inherit',
    },
  },
})

export const Documents = ({
  displayName,
  documents = [],
}) => {
  const classes = useStyles()

  const [downloading, setDownloading] = useState(false)

  let files = documents.reduce((result, doc) => {
    (doc?.files || []).forEach(file => result.push(file))
    return result
  }, [])

  const onDownloadStart = () => setDownloading(true)
  const onDownloadEnd = () => setDownloading(false)

  const onDownloadZip = () => {
    if (!downloading) {
      onDownloadStart()
      downloadZip(files, displayName, onDownloadEnd)
    }
  }

  return (
    <div className={classes.root}>
      {documents?.length > 0
        ? <>
            {documents.map(doc => (doc?.files || []).map(({ name, url }) =>
              <div className={classes.item} key={name}>
                <a className={classes.link} href={url} target='_blank' rel='noopener noreferrer' download>
                  <span className={classes.name}>
                    <Icon className={classes.icon} iconName='document2' />
                    {doc.name}
                  </span>
                  <span className={classes.file}>{name}</span>
                </a>
              </div>
            ))}
            <div>
              <div className={classnames(classes.download, { downloading })} onClick={onDownloadZip}>
                {downloading
                  ? <Overlay size='small' />
                  : <>
                      <Icon iconName='download2' />
                      <span>Скачать документы</span>
                    </>
                  }
              </div>
            </div>
          </>
        : 'Документы отсутствуют'
      }
    </div>
  )
}