import { styled } from 'styled-components'

const DescriptionWrapper = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
`

const DescriptionTitle = styled.h1`
  color: #333;
  font-size: 24px;
  margin: 0 0 20px;
`

const DescriptionList = styled.ul`
  padding: 0;
  margin-left: 10px;
`

const DescriptionItem = styled.li`
  font-size: 16px;
  font-weight: lighter;
  margin: 10px 0 0 10px;
  list-style-type: circle;
`

export const Styled = { DescriptionWrapper, DescriptionTitle, DescriptionItem, DescriptionList }
