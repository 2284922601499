import axios from 'axios'
import { SERVER } from '../constants'
import { REGISTER_ERROR_MESSAGE } from '../../config'

export const registerAgent = async (payload) => {
  try {
    const { status, data: { error_code, ...rest } } = await axios({
      method: 'POST',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/bg-agent-registration/submit`,
      data: payload,
    })

    if (status === 200) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: error_code === 5,
      error: rest.message ? rest.message : REGISTER_ERROR_MESSAGE,
    }
  } catch (err) {
    return {
      isSuccess: false,
      error: REGISTER_ERROR_MESSAGE,
    }
  }
}

export const registerToken = async (payload) => {
  try {
    const { status, data: { error_code, ...rest } } = await axios({
      method: 'POST',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/bg-agent-registration/email-confirmation`,
      data: payload,
    })

    if (status === 200) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: error_code === 5,
      error: rest.message ? rest.message : REGISTER_ERROR_MESSAGE,
    }
  } catch (err) {
    return {
      isSuccess: false,
      error: REGISTER_ERROR_MESSAGE,
    }
  }
}