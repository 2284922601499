import * as actionTypes from './actionTypes'
import { history } from './../../history'

const user = store => next => (action) => {
  if (action.error && action.error.status) {
    switch (action.error.status) {
      case 403:
        // do something
        break
      case 404:
        // do something
        break
      default:
        // do something
    }
  }

  const result = next(action)

  switch (action.type) {
    case actionTypes.AUTH_ERROR:
      if (history.location.pathname !== '/') {
        history.push('/')
      }
      break
    default:
      // do something
  }

  return result
}

export default user