import React from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'
import { Icon } from '../../../../../layout'

const useStyles = createUseStyles({
  root: {
    background: '#eee',
    padding: 30,
    borderRadius: 16,
  },
  item: {
    width: '50%',
    marginTop: 4,
    display: 'inline-block',
    verticalAlign: 'top',
    '&.address': {
      width: '100%',
    },
  },
  value: {
    fontSize: 18,
    lineHeight: 32/18,
    fontWeight: 500,
    color: '#000',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
  },
  label: {
    fontSize: 14,
    lineHeight: 18/14,
    fontWeight: 500,
    color: '#000',
    opacity: 0.5,
  },
  whatsapp: {
    width: 20,
    height: 20,
    display: 'block',
    position: 'absolute',
    top: 0,
    right: -28,
  },
})

export const Contacts = ({
  address,
  contactPhone,
  contactName,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classnames(classes.item, 'address')}>
        <div className={classes.value}>{address}</div>
        <div className={classes.label}>Адрес</div>
      </div>
      <div className={classes.item}>
        <div className={classes.value}>{contactName}</div>
        <div className={classes.label}>Контактное лицо</div>
      </div>
      <div className={classes.item}>
        <div className={classes.value}>
          {contactPhone}
          {contactPhone !== '—' &&
            <Icon className={classes.whatsapp} iconName='whatsapp' />
          }
        </div>
        <div className={classes.label}>Телефон</div>
      </div>
    </div>
  )
}