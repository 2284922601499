export const ERRORS = {
  21: 'Некорректный код. Повторите попытку',
  15: 'Срок действия кода истек',
  SIGN_ERROR: 'Ошибка подписания ',
  FETCH_DATA_ERRORS: 'Ошибка при получении данных',
  FETCH_CERTIFICATE_ERROR: 'Ошибка получения сертификатов',
  LOADING_DATA_ERROR: 'Ошибка загрузки данных',
  LOADING_ERROS: 'Ошибка загрузки: ',
  CHOOSE_SERTIFICATE_ERROR: 'Пожалуйста, выберите сертификат для подписи',
  NO_DOCUMENTS_TO_SIGN: 'Нет документов для подписи',
  CHECK_CODE_ERROR: 'Ошбика проверки кода: ',
  SEND_CODE_ERROR: 'Ошибка при отправке SMS кода: '
}
