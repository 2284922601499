import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'

const styles = {
  root: {
    color: '#000',
    fontSize: 18,
    lineHeight: 32/18,
    fontWeight: 500,
    position: 'relative',
    '& p': {
      maxWidth: 570,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: '#000',
      fontSize: 14,
      lineHeight: 18/14,
      fontWeight: 500,
    },
  },
}

const Value = ({
  children,
  className,
  classes,
  onClick,
}) => <div className={classnames(classes.root, className)} onClick={onClick}>{children}</div>

export default injectSheet(styles)(Value)