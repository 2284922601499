import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { onCalculate, onResetCalculate } from './../../redux/Widgets/actions'
import DatePicker from '../../components/DatePicker'
import Nouislider from 'nouislider-react'
import 'nouislider/distribute/nouislider.css'

const useStyles = createUseStyles({
  root: {
    width: 928,
    margin: [0, 'auto'],
    padding: [30, 0],
    transform: 'translateX(120px)',
  },
  title: {
    color: '#242232',
    fontSize: 20,
    lineHeight: 1.2,
    fontWeight: 600,
  },
	wrapper: {
    background: '#fff',
    width: '100%',
    marginTop: 28,
    padding: 30,
    borderRadius: 12,
    position: 'relative',
  },
  form: {
    width: 525,
    paddingRight: 50,
    borderRight: [1, 'solid', '#EAE7E7'],
  },
  result: {
    position: 'absolute',
    left: 605,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  tabs: {
    background: '#EAECF5',
    borderRadius: 8,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tab: {
    margin: 2,
    padding: [8, 24],
    color: '#000',
    fontSize: 14,
    lineHeight: 1.4,
    fontWeight: 500,
    borderRadius: 8,
    textAlign: 'center',
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    position: 'relative',
    '&:not(.active) + &:not(.active)': {
      '&:after': {
        content: '""',
        background: '#C4C7D6',
        width: 1,
        height: 20,
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)',
      },
    },
    '&.active': {
      background: '#fff',
      color: '#00B2A9',
      cursor: 'default',
      boxShadow: [0, 3, 8, 'rgba(0, 0, 0, 0.12)'],
    },
  },
  inputs: {
    marginTop: 35,
    position: 'relative',
    '& .noUi-target': {
      background: '#00B2A9',
      height: 3,
      margin: [0, 10],
      borderRadius: 12,
      border: 'none',
      boxShadow: 'none',
      cursor: 'pointer',
      transform: 'translateY(-3px)',
    },
    '& .noUi-horizontal .noUi-handle': {
      background: '#00B2A9',
      width: 13,
      height: 13,
      border: [3, 'solid', '#fff'],
      borderRadius: '50%',
      boxShadow: 'none',
      cursor: 'pointer',
      outline: 'none',
      right: -7,
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
  },
  fieldset: {
    width: 300,
    position: 'relative',
    '& + &': {
      marginTop: 20,
    },
    '&:first-child': {
      '& > input': {
        paddingRight: 60,
        paddingLeft: 60,
      },
      '&:after': {
        content: '"₽"',
        position: 'absolute',
        padding: [0, 20],
        top: 40,
        right: 0,
        display: 'block',
        color: '#000',
        fontSize: 15,
        lineHeight: 1.8,
        fontWeight: 500,
      },
    },
    '& > label': {
      paddingBottom: 8,
      color: '#000',
      fontSize: 14,
      lineHeight: 1.8,
      fontWeight: 600,
      display: 'block',
      position: 'relative',
      '& > span': {
        color: '#b8b8b8',
        fontSize: 13,
        lineHeight: 1.4,
        fontWeight: 500,
        position: 'absolute',
        top: 3,
        right: 0,
        display: 'inline-block',
        verticalAlign: 'top',
      },
    },
    '& > input': {
      width: '100%',
      height: 40,
      padding: [8, 12],
      color: '#000',
      fontSize: 15,
      lineHeight: 1.5,
      fontWeight: 600,
      textAlign: 'center',
      outline: 'none',
      border: [1, 'solid', '#ddd'],
      borderRadius: 12,
    },
  },
  datepicker: {
    width: 300,
    '& > div': {
      width: '100% !important',
      height: 40,
      '& > input': {
        height: 'inherit',
        paddingLeft: '40px !important',
        paddingRight: '40px !important',
        color: '#000',
        fontSize: 15,
        lineHeight: 1.8,
        fontWeight: 500,
        border: [1, 'solid', '#ddd'],
      },
    },
    '& i': {
      fontSize: 18,
      color: '#b8b8b8',
    },
  },
  checkboxes: {
    marginTop: 20,
    position: 'relative',
  },
  checkbox: {
    '& + &': {
      marginTop: 12,
    },
    '& > input': {
      display: 'none',
      '&:checked': {
        '& + label': {
          '&:before': {
            opacity: 0,
          },
          '&:after': {
            opacity: 1,
          },
        },
      },
    },
    '& > label': {
      paddingLeft: 30,
      color: '#151B3D',
      fontSize: 12,
      lineHeight: 2,
      fontWeight: 600,
      letterSpacing: 0.5,
      cursor: 'pointer',
      position: 'relative',
      '&:before, &:after': {
        content: '""',
        width: 20,
        height: 20,
        margin: 2,
        borderRadius: 3,
        position: 'absolute',
        left: 0,
        top: 0,
        display: 'block',
        transition: 'opacity 300ms ease',
      },
      '&:before': {
        border: [3, 'solid', '#C4C4C4'],
        opacity: 1,
      },
      '&:after': {
        backgroundImage: `url(${require('./checked.svg')})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: [20, 20],
        opacity: 0,
      },
    },
  },
  submit: {
    background: '#00B2A9',
    width: 227,
    marginTop: 24,
    padding: [5, 10],
    borderRadius: 8,
    textAlign: 'center',
    color: '#fff',
    fontSize: 14,
    lineHeight: 2,
    fontWeight: 600,
    cursor: 'pointer',
  },
  value: {
    '& + &': {
      marginTop: 24,
    },
    '& > div': {
      color: '#000',
      fontSize: 24,
      lineHeight: 1.25,
      letterSpacing: 0.5,
      fontWeight: 600,
      '& > span': {
        color: '#b8b8b8', 
      },
    },
    '& > p': {
      color: '#b8b8b8',
      fontSize: 13,
      lineHeight: 1.4,
    },
  },
  error: {
    margin: [6, 10, 0],
    color: 'red',
    fontSize: 14,
    lineHeight: 1.2,
    fontWeight: 500,
  },
})

const TABS = ['Участие', 'Исполнение', 'Гарантийный период']

const BANK_GUARANTEE_TYPE = ['participation', 'execution', 'period']

const CHECKBOXES = [
  { name: 'isCooperation', value: 'Конкурентное предложение', checked: false },
  { name: 'isAgentExpense', value: 'Снижение за счёт агента', checked: false },
  { name: 'prepayment', value: 'Аванс', checked: false },
  { name: 'isRequiredSecurityForGuaranteePeriod', value: 'Гарантийный период', checked: false },
]

const ERRORS = {
  price: 'Не введена сумма гарантии', 
  startDate: 'Не указана дата начала и окончания', 
  endDate: 'Не указана дата окончания', 
  limit: 'Превышен лимит по сроку',
}

const getDaysDiff = (start, end) => {
  const getDate = (string) => {
    if (!string) {
      return new Date()
    }
    const array = string.split('.')
    const day = array[0]
    const month = array[1]
    const year = array[2]
    return new Date(`${month}-${day}-${year}`)
  }
  const startDate = getDate(start)
  const endDate = getDate(end)
  const days = (endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24
  return days
}

const formattedValue = (value) => (Number(value).toFixed(0)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')

const formattedPrice = (value) => String(value).length >= 6 
  ? `${(value / 1000000)} млн` 
  : `${(value / 1000)} тыс`

export const Calculator = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { widgets } = useSelector(state => state?.Settings)
  const { data, errors } = useSelector(state => state?.Widgets)
  const calcResult = data?.bgtc || {}

  const [active, setActive] = useState(0)
  const [checkboxes, setCheckboxes] = useState(CHECKBOXES)
  const [price, setPrice] = useState('0')
  const [period, setPeriod] = useState({})
  const [errorsState, setErrorsState] = useState({})
  const [hasClick, setHasClick] = useState(false)
  const [isCooperation, setIsCooperation] = useState('')
  const [isAgentExpense, setIsAgentExpense] = useState('')
  const [prepayment, setPrepayment] = useState('')
  const [isRequiredSecurityForGuaranteePeriod, setIsRequiredSecurityForGuaranteePeriod] = useState('')
  const [ref, setRef] = useState(null)

  const _onCalculate = (payload) => dispatch(onCalculate(payload))
  const _onResetCalculate = () => dispatch(onResetCalculate())

  const isExecution = active === 1

  const onTab = (index) => setActive(index)
  const onClearDate = (name, value) => setPeriod({ [name]: value })
  const onUpdateSlider = (render, handle, value, un, percent) => setPrice(value[0].toFixed(0))
  const onChangeSlider = (e) => ref?.noUiSlider && ref?.noUiSlider.set(e.target.value.replace(/ /g, ''))
  
  const onChangeCheckbox = (name, idx) => {
    let updatedCheckboxes = checkboxes.map(item => item)
    const checked = !updatedCheckboxes[idx]['checked']
    const value = checked ? 'on' : ''
    updatedCheckboxes[idx] = {
      ...updatedCheckboxes[idx],
      checked,
    }
    setCheckboxes(updatedCheckboxes)
    switch(name) {
      case 'isCooperation': return setIsCooperation(value)
      case 'isAgentExpense': return setIsAgentExpense(value)
      case 'prepayment': return setPrepayment(value)
      case 'isRequiredSecurityForGuaranteePeriod': return setIsRequiredSecurityForGuaranteePeriod(value)
      default: 
    }
  }

  const onSelectDate = ({ target }) => {
    const values = target.value.split(' - ')
    setPeriod({
      startDate: values[0],
      endDate: values[1],
    })
  }

  const onUpdateErrors = () => {
    const days = getDaysDiff(period.startDate, period.endDate)
    setErrorsState({
      ...errorsState, 
      price: price === '0' ? ERRORS.price : '',
      period: !period.startDate ? ERRORS.startDate : !period.endDate ? ERRORS.endDate : '',
      limit: days > LIMITS[active] ? ERRORS.limit: '',
    })
  }

  const onSubmit = () => {
    setHasClick(true)
    if (!errorsState.price && !errorsState.period && !errorsState.limit) {
      const payload = {
        isCooperation: !!isCooperation,
        isAgentExpense: !!isAgentExpense,
        bankGuaranteeType: BANK_GUARANTEE_TYPE[active],
        amount: parseInt(price),
        startDate: period.startDate,
        endDate: period.endDate,
      }

      if (isExecution) {
        payload.prepayment = !!prepayment
        payload.isRequiredSecurityForGuaranteePeriod = !!isRequiredSecurityForGuaranteePeriod
      }

      _onCalculate(payload)
    }
  }

  useEffect(_onResetCalculate, [])
  useEffect(onUpdateErrors, [price, period, active])

  const checkboxesByTab = isExecution ? checkboxes : checkboxes.slice(0, 2)

  const bgtc = widgets?.bgtc

  const maxBgAmount = bgtc?.maxBgAmount
  const maxDurationDaysParticipation = bgtc?.maxDurationDaysParticipation
  const maxDurationDaysExecution = bgtc?.maxDurationDaysExecution
  const maxDurationDaysPeriod = bgtc?.maxDurationDaysPeriod

  const LIMITS = [maxDurationDaysParticipation, maxDurationDaysExecution, maxDurationDaysPeriod]
  
  const {
    commissionAmount,
    minCommissionAmount,
    commissionPercent,
    minCommissionPercent
  } = calcResult || {}

  const INFO = [
    { key: 'Стандартный тариф', value: formattedValue(commissionAmount), percent: commissionPercent },
    { key: 'Минимальный тариф', value: formattedValue(minCommissionAmount), percent: minCommissionPercent },
    // { key: 'Бонус', value: '500 ₽' },
    // { key: 'Базовая комиссия', value: '10 000 ₽' },
  ]

  const canRenderCalcResults =
    commissionAmount &&
    Object.keys(errors)?.length === 0 &&
    !errorsState.price &&
    !errorsState.period &&
    !errorsState.limit

  if (!bgtc) {
    return <Redirect to='/tasks' />
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>Гарантийный калькулятор</div>
      <div className={classes.wrapper}>
        <div className={classes.form}>
          <div className={classes.tabs}>
            {TABS.map((item, index) =>
              <div 
                key={index} 
                className={classnames(classes.tab, { active: active === index })} 
                onClick={() => onTab(index)}
              >
                {item}
              </div>
            )}
          </div>
          <div className={classes.inputs}>
            <div className={classes.fieldset}>
              <label htmlFor='summ'>
                Сумма гарантии
                <span>макс. {formattedPrice(maxBgAmount)} ₽</span>
              </label>
              <input 
                id='summ'
                type='text'
                value={formattedValue(price)}
                // value={price}
                onChange={onChangeSlider} 
              />
              <Nouislider
                instanceRef={instance => instance && !ref && setRef(instance)}
                range={{ 
                  min: 0, 
                  max: maxBgAmount, 
                }} 
                start={0}
                step={1}
                onUpdate={onUpdateSlider}
              />
              {(errors?.price || (errorsState.price && hasClick)) &&
                <div className={classes.error}>{errorsState.price}</div>
              }
            </div>
            <div className={classes.fieldset}>
              <label htmlFor='period'>Дата начала и окончания</label>
              <DatePicker
                name='createdDate'
                // defaultActive={filters.createdDate}
                className={classes.datepicker}
                placeholder=' '
                onSelectDate={onSelectDate}
                onClear={onClearDate}
              />
              {errorsState.period && hasClick && 
                <div className={classes.error}>{errorsState.period}</div>
              }
              {errorsState.limit && hasClick && 
                <div className={classes.error}>{errorsState.limit}</div>
              }
              {(errors?.startDate || errors?.endDate) &&
                <div className={classes.error}>{errors?.startDate || errors?.endDate}</div>
              }
            </div>
          </div>
          <div className={classes.checkboxes}>
            {checkboxesByTab.map(({ name, value, checked }, index) =>
              <div className={classes.checkbox} key={index}>
                <input 
                  id={name} 
                  type='checkbox' 
                  onChange={() => onChangeCheckbox(name, index)} 
                  checked={checked} 
                />
                <label htmlFor={name}>{value}</label>
              </div>
            )}
          </div>
          <div className={classes.submit} onClick={onSubmit}>Рассчитать</div>
        </div>
        <div className={classes.result}>
          <div className={classes.value}>
            <div>{LIMITS[active]} дней</div>
            <p>максимум</p>
          </div>
          {canRenderCalcResults && INFO.map(({ key, value, percent }, index) =>
            <div className={classes.value} key={index}>
              <div>{value} <span>₽</span> — {percent} <span>%</span></div>
              <p>{key}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}