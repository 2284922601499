import React from 'react'
import injectSheet from 'react-jss'
import { adaptive_1400 } from '../../../config'

const styles = {
  root: {
    width: 'calc(50% - 20px)',
    [adaptive_1400]: {
      '-webkit-box-ordinal-group': 4,
      '-webkit-order': 3,
      '-ms-flex-order': 3,
      order: 3,
      width: '100%',
    },
  },
}

const Col_md_6 = ({ children, classes }) =>
  <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Col_md_6)