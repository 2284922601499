import useSwr from 'swr'
import { otpService } from '../services/OtpService'

export const useOtp = (phone: string | null, token: string | null) => {
  return useSwr(phone ? ['phone-otp', phone] : null, () => otpService.sendCode(phone!, token!))
}

export const useResendOtp = (phone: string | null, token: string | null) => {
  const { mutate } = useOtp(phone, token)
  return () => mutate()
}

export const useConfirmOtp = (phone: string | null, code: string | null, token: string | null) => {
  return useSwr(!phone || !code ? null : ['phone-otp-confirm', code], () =>
    otpService.confirmCode(phone!, code!, token!)
  )
}
