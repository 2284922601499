import * as actionTypes from './actionTypes'
import * as Widgets from '../../services/api/widgets'

import { setErrorContent } from '../Error/actions'

export const onCalculate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.CALCULATE })
    const { isSuccess, ...response } = await Widgets.onCalculate(payload)
    if (isSuccess) {
      dispatch({ 
        type: actionTypes.CALCULATE_SUCCESS, 
        data: response?.result, 
      })
    } else {
      dispatch({ 
        type: actionTypes.CALCULATE_ERROR,
        error: response?.error,
        errors: response?.errors,
      })
    }
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err?.message))
    dispatch({ 
      type: actionTypes.CALCULATE_ERROR,
      error: err?.message,
    })
  }
}

export const onResetCalculate = () => (dispatch) => {
  dispatch({ type: actionTypes.RESET_CALCULATE })
}