import React from 'react'
import injectSheet from 'react-jss'
import classnames from 'classnames'

const styles = {
  error: {
    marginTop: 4,
    fontSize: 12,
    letterSpacing: '0.5px',
    color: '#FF9494',
    textAlign: 'center',
  },
}

const Error = ({
  classes,
  className,
  children, 
}) =>
  <div className={classnames(classes.error, className)}>{children}</div>

export default injectSheet(styles)(Error)