import * as actionTypes from './actionTypes'

const initialState = {
  isFetching: false,
  wasFetching: false,
  getUsersFetching: false,
  addUserFetching: false,
  addUserStatus: false,
  ids: [],
  list: {},
  rootAgents: [],
  agents: [],
  stat: {},
  users: [],
  changingUser: '',
  addAgent: {
    fetching: false,
    status: false,
    error: false,
  },
  addUser: {
    fetching: false,
    status: false,
    error: false,
  },
  editUser: {
    fetching: false,
    status: false,
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AGENTS_FETCH:
      return { 
        ...state, 
        isFetching: true, 
        wasFetching: false, 
      }
    case actionTypes.AGENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        wasFetching: true,
        ...action.data,
      }
    case actionTypes.AGENTS_ERROR:
      return { 
        ...state, 
        isFetching: false, 
        wasFetching: true, 
      }
    case actionTypes.AGENT_USERS_FETCH:
      return {
        ...state,
        getUsersFetching: true,
        users: [],
      }
    case actionTypes.AGENT_USERS_SUCCESS:
      return {
        ...state,
        getUsersFetching: false,
        ...action.data,
      }
    case actionTypes.AGENT_USERS_ERROR:
      return { 
        ...state, 
        getUsersFetching: false, 
      }
    case actionTypes.AGENT_USER_CHANGE_STATUS_FETCH:
      return { 
        ...state, 
        changingUser: action.user, 
      }
    case actionTypes.AGENT_USER_CHANGE_STATUS_SUCCESS:
      const updatedUsers = state.users.map(user => {
        return user.username !== state.changingUser
          ? user
          : Object.assign({}, user, { enabled: !user.enabled })
      })
      return {
        ...state,
        changingUser: '',
        users: [...updatedUsers],
      }
    case actionTypes.AGENT_USER_CHANGE_STATUS_ERROR:
      return {
        ...state,
        changingUser: '',
      }
    case actionTypes.AGENT_USER_ADD_FETCH:
      return {
        ...state,
        addUser: { 
          ...state.addUser, 
          fetching: true, 
          error: false, 
        },
      }
    case actionTypes.AGENT_USER_ADD_SUCCESS:
      return {
        ...state,
        addUser: { 
          ...state.addUser, 
          fetching: false, 
          status: true, 
          error: false,
        },
      }
    case actionTypes.AGENT_USER_ADD_ERROR:
      return {
        ...state,
        addUser: { 
          ...state.addUser, 
          fetching: false,
          error: action.error, 
        },
      }
    case actionTypes.AGENT_USER_ADD_RESET:
      return {
        ...state,
        addUser: { 
          ...initialState.addUser, 
        },
      }
    case actionTypes.AGENT_USER_EDIT_FETCH:
      return {
        ...state,
        editUser: { 
          ...state.editUser, 
          fetching: true, 
        },
      }
    case actionTypes.AGENT_USER_EDIT_SUCCESS:
      return {
        ...state,
        editUser: { 
          ...state.editUser, 
          fetching: false, 
          status: true, 
        },
      }
    case actionTypes.AGENT_USER_EDIT_ERROR:
      return {
        ...state,
        editUser: { 
          ...state.editUser, 
          fetching: false, 
        },
      }
    case actionTypes.AGENT_USER_EDIT_RESET:
      return {
        ...state,
        editUser: { 
          ...initialState.editUser, 
        },
      }
    case actionTypes.AGENT_SUB_NEW_FETCH:
      return {
        ...state,
        addAgent: { 
          ...state.addAgent, 
          fetching: true, 
        },
      }
    case actionTypes.AGENT_SUB_NEW_SUCCESS:
      return {
        ...state,
        addAgent: { 
          ...state.addAgent, 
          fetching: false, 
          status: true, 
        },
      }
    case actionTypes.AGENT_SUB_NEW_ERROR:
      return {
        ...state,
        addAgent: { 
          ...state.addAgent, 
          fetching: false, 
        },
      }
    case actionTypes.AGENT_SUB_NEW_RESET:
      return {
        ...state,
        addAgent: { 
          ...initialState.addAgent, 
        },
      }
    case actionTypes.RESET_ADD_USER_ERROR:
      return {
        ...state,
        addUser: { 
          ...state.addUser, 
          error: {
            ...state.addUser.error,
            [action.name]: '',
          },
        },
      }
    default:
      return state
  }
}