import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col } from 'reactstrap'
import { getFile } from '../../../../redux/Files/actions'
import { ReportTab } from '../../Layout/ReportTab'
import Dropdown from '../../../Dropdown'
import { reformatingDateForRequest } from './reportHelper'
import { fitPeriodsForDropdawn, getLastMonths, YYYYMMDD } from '../../../../utils'

export const StatisticsReport = ({
  onCancel,
  settings,
}) => {
  const periods = getLastMonths(12)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [chosenItem, setChosenItem] = useState(0)

  const { username } = useSelector(state => state.Settings.user)

  const now = new Date()
  const currentDate = YYYYMMDD(now)
  
  const chosenPeriod = fitPeriodsForDropdawn(periods, chosenItem)

  const reformatedPeriod = reformatingDateForRequest(chosenPeriod.list.map(el => el.value)[chosenItem])

  const valid = currentDate

  const onSelectItem = (name, key, index) => setChosenItem(index)

  const onDownloadFile = () => {
    if (valid && !loading) {
      setLoading(true)
      const url = `${settings.url}?period=${reformatedPeriod}&agentLogin=${username}`
      const onSuccess = () => setLoading(false)
      const onError = () => setLoading(false)
      dispatch(getFile({ url, onSuccess, onError }))
    }
  }

  return (
    <ReportTab
      onOk={onDownloadFile}
      onCancel={onCancel}
      okButtonText='Получить отчёт'
      disabled={!valid}
      loading={loading}
      title={settings.description}
    >
      <Col md={{ size: 8, offset: 2 }}>
        <div className='main-filter__control main-filter__control--icon-right'>
          <Dropdown
            // name='orderTypeRefId'
            toggleClassName='btn btn-dropdown-reports'
            defaultActive={chosenItem}
            list={chosenPeriod.list}
            onSelectItem={onSelectItem}
          />
          <i className='icon icon-chevron-down' />
        </div>
      </Col>
    </ReportTab>
  )
}
