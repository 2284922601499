import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import { InputPhone } from '../../components/InputPhone/InputPhone'
import TextField from '../../components/TextField'
// import TextFieldWithAutoComplete from '../../components/TextFieldWithAutoComplete'
import { setTasksFilter } from '../../redux/Tasks/actions'
import Tasks from '../Tasks'
// import { TASKS_SET_FILTER_CLIENT_NAME } from '../../redux/Tasks/actionTypes'

const useStyles = createUseStyles({
  section: {
    padding: '20px !important'
  },
  containerOuter: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    '& > :not(:last-child)': {
      maxWidth: 650,
      width: '100%',
      marginBottom: 20,
      marginRight: 0,
    }
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#242232',
    padding: [12, 32],
    color: '#fff',
    fontSize: 16,
    lineHeight: 24/16,
    outline: 'none',
    border: 'none',
    borderRadius: 16,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.9,
      outline: 'none'
    },
    '&:focus': {
      outline: 'none'
    },
    '&:active': {
      opacity: 1,
      outline: 'none'
    },
    '&.disabled': {
      backgroundColor: '#999',
      cursor: 'not-allowed',
      '&:hover': {
        opacity: 1
      }
    }
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 14,
    fill: '#fff',
  },
  phone: {
    textAlign: 'center !important',
    backgroundColor: '#fff !important',
    '&:focus': {
      borderColor: '#a8a6b6 !important',
      color: '#4a4a4a !important'
    }
  },
  info: {
    color: 'rgb(33, 37, 41)',
    fontSize: 32,
    lineHeight: 38/32,
    textAlign: 'center'
  }
})

export const TasksRko = connect(({ Tasks }) => ({ filters: Tasks.filters }))(({ filters, dispatch }) => {
  const classes = useStyles()
  const [showList, setShowList] = useState(false)
  const [inn, setInn] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')

  useEffect(() => {
    onChangeFilter('inn', inn)
    onChangeFilter('phone', phone)
    onChangeFilter('email', email)
  }, [])

  const onChangeFilter = (name, value) => {
    const { [name]: oldValue, ...restFilters } = filters
    const diff = !!value ? { [name]: value } : {}
    dispatch(setTasksFilter({ ...restFilters, ...diff }))
  }

  const onBlurInn = () => onChangeFilter('inn', inn)
  const onBlurPhone = () => onChangeFilter('phone', phone)
  const onBlurEmail = () => onChangeFilter('email', email)

  const onChangeInn = ({ target: { value } }) => setInn(value)
  const onChangeEmail = ({ target: { value } }) => setEmail(value)

  const onClearInn = () => setInn('')
  const onClearEmail = () => setEmail('')

  // const onChangeCompany = (name, value, inputValue) => {
  //   onChangeFilter(name, value)
  //   dispatch({ type: TASKS_SET_FILTER_CLIENT_NAME, data: { name: inputValue } })
  // }

  const onSearch = () => setShowList(true)

  // const client = filters.principalCompanyId
  const isValid = !!inn && (!!phone || !!email)
  const buttonDisabled = !isValid // (!phone && !client) || (!phone && !email) || (!client && !email)

  return showList
    ? <Tasks />
    : <section className={`fr-content fr-content--tasks ${classes.section}`}>
        <div className={classes.containerOuter}>
          <div className={classes.container}>
            <div className={classes.info}>
              Для поиска задач необходимо заполнить ИНН клиента и его телефон, либо адрес электронной почты
            </div>
            <TextField
              name='inn'
              placeholder='ИНН клиента'
              value={inn}
              onChange={onChangeInn}
              onClear={onClearInn}
              onBlur={onBlurInn}
            />
            <InputPhone
              name='phone'
              placeholder='Номер телефона'
              onChange={setPhone}
              value={phone}
              inputClassName={classes.phone}
              onBlur={onBlurPhone}
            />
            {/* <TextFieldWithAutoComplete
              name='principalCompanyId'
              value={filters.principalCompanyId}
              onSelect={onChangeCompany}
              onClear={onChangeFilter}
              placeholder='Клиент'
              classNames={{
                container: 'main-filter__control',
                input: 'main-filter__control-field'
              }}
            /> */}
            <TextField
              name='email'
              placeholder='Электронная почта'
              value={email}
              onChange={onChangeEmail}
              onClear={onClearEmail}
              onBlur={onBlurEmail}
            />
            <button
              className={classnames(classes.button, { disabled: buttonDisabled })}
              onClick={onSearch}
              disabled={buttonDisabled}
            >
              <svg className={classes.icon} id='search' viewBox='0 0 16 16'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M6.24993 0C9.69524 0 12.4999 2.80462 12.4999 6.24993C12.4999 7.56381 12.0936 8.81376 11.3521 9.85695L16 14.5049L14.5049 16L9.85695 11.3521C8.81376 12.0936 7.56381 12.4999 6.24993 12.4999C2.80462 12.4999 9.53674e-07 9.69524 9.53674e-07 6.24993C9.53674e-07 2.80462 2.80462 0 6.24993 0ZM10.4045 6.24993C10.4045 3.94882 8.55107 2.09535 6.24996 2.09535C3.94885 2.09535 2.09538 3.94882 2.09538 6.24993C2.09538 8.55104 3.94885 10.4045 6.24996 10.4045C8.55107 10.4045 10.4045 8.55104 10.4045 6.24993Z'
                  fill='white'
                />
              </svg>
              Найти заявку
            </button>
          </div>
        </div>
      </section>
})