import React from 'react'
import injectSheet from 'react-jss'
import { Icon } from '../../layout'

const styles = {
  closeAlternative: {
    width: 50,
    height: 50,
    // position: 'fixed',
    // top: 6,
    // left: '50%',
    // transform: 'translateX(595px)',
    cursor: 'pointer',
    marginLeft: 8,
    zIndex: 5000,
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
}

export const CloseAlternative = injectSheet(styles)(({
  classes,
  style,
  onClose,
}) =>
  <div
    className={classes.closeAlternative}
    onClick={onClose}
    style={style}
  >
    <Icon iconName='close' />
  </div>
)
