import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ProgressStatistic = ({
  count,
  sum,
  text,
  className,
}) => {
  const barWidth = ((count / sum) * 100).toFixed(1)

  return (
    <div className={classnames('progress-statistic__item', {
      [`progress-statistic__item--${className}`]: className,
    })}>
      <span className='progress-statistic__counter'>{count}</span>
      <span className='progress-statistic__status'>{text}</span>
      <span className='progress-statistic__bar' style={{
        width: `${barWidth}%`,
        borderRadius: barWidth >= 97 ? '10px' : '10px 0 0 10px',
      }} />
    </div>
  )
}

ProgressStatistic.propTypes = {
  count: PropTypes.number.isRequired,
  sum: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
}

ProgressStatistic.defaultProps = {
  className: '',
}

export default ProgressStatistic
