import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Modal } from './../../index'

const useStyles = createUseStyles({
	root: {
    paddingBottom: 20,
    borderBottom: [1, 'solid', '#ddd'],
    position: 'relative',
  },
  item: {
    paddingRight: 230,
    position: 'relative',
    '& + &': {
      marginTop: 20,
    },
  },
  right: {
    position: 'absolute',
    top: 0,
    right: 0,
    textAlign: 'right',
  },
  lots: {
    padding: [30, 0],
    position: 'relative',
  },
  info: {
    // background: '#E4E4E4',
    // width: 'calc(100% + 144px)',
    // marginLeft: -74,
    // marginTop: 35,
    // padding: [0, 75],
    position: 'relative',
  },
  infoItem: {
    background: '#ECECEC',
    width: 170,
    padding: [25, 0],
    textAlign: 'center',
    display: 'inline-block',
    position: 'relative',
    '& + &': {
      borderLeft: [1, 'solid', '#E4E4E4'],
    },
  },
})

export const Info = ({ 
  content: { 
    info = [], 
    data = [],
    lots = [],
    documents = [],
    eis = ''
  },
  title
}) => {
  const classes = useStyles()
  return (
    <Modal.Block iconName='file' title={title}>
      <Modal.Content>
        <div className={classes.root}>
          {info.map(({ items }, index) =>
            <div className={classes.item} key={`info-${index}`}>
              {items.map(({ title, value, right }, subIndex) => 
                <div 
                  className={classnames({ [classes.right]: right })} 
                  key={`info-${index}-${subIndex}`}
                >
                  <Modal.Value>{value}</Modal.Value>
                  <Modal.Title>{title}</Modal.Title>
                </div>
              )}
            </div>
          )}
        </div>
        {lots?.length > 0 &&
          <div className={classes.lots}>
            {lots.map((lot, index) =>
              <Modal.Lot
                {...lot}
                documents={documents}
                key={`lot-${index}`}
                eis={eis}
              />
            )}
          </div>
        }
        {data?.length > 0 && 
          <ul className={classes.info}>
            {data.map(({ title, value }, index) => {
              if(!title?.length || !value?.length) return null;
                return <li className={classes.infoItem} key={`data-${index}`}>
                  <Modal.Title>{title}</Modal.Title>
                  <Modal.Value>{value}</Modal.Value>
                </li>
              }
            )}
          </ul>
        }
      </Modal.Content>
    </Modal.Block>
  )
}