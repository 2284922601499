import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Label, Error } from '../../layout'

const useStyles = createUseStyles({
  selectWrapper: {
    width: '100%',
    position: 'relative',
    '&.required': {
      background: 'yellow',
    },
  },
  select: {
    backgroundColor: 'transparent',
    width: '100%',
    padding: [28, 20, 10],
    border: 'none',
    fontSize: 14,
    lineHeight: 1.25,
    color: 'rgba(11, 31, 53, 0.95)',
    display: 'block',
    borderRadius: 'inherit',
    cursor: 'pointer',
    '&:disabled': {
      background: 'rgba(11, 31, 53, 0.2)',
      cursor: 'default',
      '&:hover': {
        background: 'rgba(11, 31, 53, 0.2)',
      },
    },
    '&.error': {
      borderColor: 'red',
    },
  },
  icon: {
    width: 24,
    height: 24,
    display: 'block',
    position: 'absolute',
    top: 18,
    right: 24,
    fill: '#242232',
    pointerEvents: 'none',
  },
})

export const Select2 = ({
  label,
  id,
  name,
  defaultValue,
  options = [],
  required,
  className,
  onChange,
  value,
  error,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <>
      <div className={classnames(classes.selectWrapper, { required }, className)}>
        {label &&
          <Label htmlFor={id}>{label}</Label>
        }
        <select
          value={value}
          id={id}
          name={name}
          className={classnames(classes.select, { error })}
          onChange={onChange}
          {...rest}
        >
          {options.map(({ name, value, disabled }, index) => (
            <option value={value} disabled={disabled} key={index}>
              {name}
            </option>
          ))}
        </select>
        {/* <Icon className={classes.icon} iconName='arrow' /> */}
      </div>
      {error &&
        <Error>{error}</Error>
      }
    </>
  )
}