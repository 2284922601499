import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import { createPortal } from 'react-dom'
import injectSheet from 'react-jss'
import Close from './Close'
import { isPressEscape } from '../../../utils'

const styles = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 5000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:before': {
      content: '""',
      display: 'block',
      background: '#1e1e1e',
      opacity: 0.9,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 5000,
    },
  },
  wrapper: {
    width: 1210,
    position: 'fixed',
    top: 6,
    bottom: 6,
    borderRadius: 16,
    overflowY: 'auto',
    zIndex: 5000,
    '&.fixWidth': {
      '& > div': {
        width: '100%',
      },
    },
    '&.fixHeight': {
      top: '50%',
      left: '50%',
      transform: 'translate(calc(-50% - 25px), -50%)',
      '& > div': {
        overflowY: 'visible',
      },
    },
  },
  modal: {
    background: '#fff',
    maxHeight: '100%',
    maxWidth: ' 100%',
    height: 'auto',
    padding: 30,
    overflowY: 'auto',
    borderRadius: 16,
  },
}

const Modal = ({ 
  children,
  classes,
  className,
  width,
  height,
  onClose,
}) => {
  const [position, setPosition] = useState({})

  const modalNode = document.createElement('div')
  const root = document.getElementById('root')
  modalNode.classList.add(classes.root)

  useEffect(() => {
    document.body.insertBefore(modalNode, root)

    return () => document.body.removeChild(modalNode)
  })

  const CSS = { 
    width,
    height, 
  }

  const modalRef = useRef(null)

  useEffect(() => {
    const { top, left, width } = modalRef.current.getBoundingClientRect()
    setPosition({ 
      top: `${top}px`, 
      left: `${left + width + 15}px`,
      transform: 'none', 
    })
  }, [modalRef])

  const onEscape = (e) => {
    if (isPressEscape(e)) {
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', onEscape, false)
    return () => {
      document.removeEventListener('keyup', onEscape, false)
    }
  }, [])
  
  return createPortal(
    <>
      <div className={classnames(classes.wrapper, {'fixWidth': width, 'fixHeight': height})} style={CSS}>
        <div ref={modalRef} className={classnames(classes.modal, className)}>
          {children}
        </div>
      </div>
      <Close onClose={onClose} style={position} />
    </>, modalNode)
}

export default injectSheet(styles)(Modal)