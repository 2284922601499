import React from 'react'
import PropTypes from 'prop-types'
import ClientCard from '../../../components/Card/Client'
import Skelet from '../../../components/Card/Skelet'

const ClientsList = ({
  list,
  statusItems,
  isLoading,
  isLoadingNext,
}) => {
  return (
    <div className='block-list block-list--clients'>
      {isLoading
        ? [0, 1, 2, 3].map((_, index) => <Skelet key={index} />)
        : list.map((item, index) =>
            <ClientCard
              key={index}
              id={item?.id}
              displayName={item?.displayName || item?.shortName || item?.fullName || ''}
              INN={item?.INN}
              KPP={item?.KPP}
              OGRN={item?.OGRN}
              stats={item?.stat}
              statusItems={statusItems}
            />
          )}
      {isLoadingNext &&
        <Skelet key={list.length + 10} showLoader />
      }
    </div>
  )
}

ClientsList.propTypes = {
  list: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isLoadingNext: PropTypes.bool.isRequired,
}

export default ClientsList