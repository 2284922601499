import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { authenticationUser } from '../../../redux/User/actions'
import { isPressEscape } from '../../../utils'

class AddModalSelect extends Component {
  static propTypes = {
    processDefinitionKeys: PropTypes.array,
    onCloseModal: PropTypes.func.isRequired,
    onProgrammingRedirect: PropTypes.func.isRequired
  }

  componentDidMount() {
    document.body.classList.add('modal-open')
    this.modal.classList.add('show')
    document.addEventListener('click', this.onOutsideClick, false)
    document.addEventListener('keyup', this.onEscape, false)
  }

  componentWillUnmount() {
    this.modal.classList.remove('show')
    document.body.classList.remove('modal-open')
    document.removeEventListener('click', this.onOutsideClick, false)
    document.removeEventListener('keyup', this.onEscape, false)
  }

  onEscape = (e) => {
    if (isPressEscape(e)) {
      this.props.onCloseModal()
    }
  }

  onOutsideClick = (event) => {
    if (this.content && this.content.contains(event.target)) return     
    this.props.onCloseModal()
  }

  onOpenForm = ({ currentTarget }) => {
    const { processKey } = currentTarget.dataset
    const { onProgrammingRedirect, dispatch } = this.props
    dispatch(authenticationUser(true))
      .then(() => onProgrammingRedirect(`?add-task=${processKey}`))
      .catch(err => console.log(err))
  }

  setComponentRef = (item, node) => (this[item] = node)

  render() {
    const { processDefinitionKeys } = this.props

    return [
      <div key={0} className='modal-custom'>
        <div
          ref={this.setComponentRef.bind(this, 'modal')}
          className='modal bd-example-modal-lg fade'
          id='optionsModal'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='optionsModalLabel'
          aria-hidden='true'
        >
          <div className='modal-menu' ref={this.setComponentRef.bind(this, 'content')}>
            {(processDefinitionKeys || []).filter(p => p.isStartable).map(process => (
              <button
                key={process.process_definition_key}
                type='button'
                className='modal-menu__item'
                data-process-key={process.process_definition_key}
                onClick={this.onOpenForm}
              >
                {process.process_type === 'bankguarantee'
                  ? <img src='static/media/new-garantee.svg' alt='' />
                  : <img src='static/media/rewrite-garantee.svg' alt='' />
                }
                <span>{process.process_name}</span>
              </button>
            ))}
          </div>
          <button type='button' className='close modal-menu__close' data-dismiss='modal' aria-label='Close'>
            <span aria-hidden='true'>×</span>
            <div className="close__line" />
            <div className="close__line" />
          </button>
        </div>
      </div>,
      <div key={1} className='modal-backdrop fade show' />
    ]
  }
}

const mapStateToProps = ({ User }) => {
  return {
    processDefinitionKeys: User.processDefinitionKeys,
  }
}

export default connect(mapStateToProps)(AddModalSelect)
