import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import classnames from 'classnames'
import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
// import { isPressEscape } from '../../../../utils'
import { CloseAlternative } from './CloseAlternative'
import { modalAlternativeStyles } from './styles'

export const ModalAlternative = modalAlternativeStyles(
  ({
    children,
    classes,
    className,
    width,
    height,
    onClose=null,
    noPadding,
    hideOverflow,
  }) => {
    const root = document.getElementById('root')

    const CSS = {
      width,
      height,
    }

    const modalRef = useRef(null)
    useEffect(() => {
      disableBodyScroll(modalRef)

      return () => enableBodyScroll(modalRef)
    }, [])

    const onEscape = e => {
      // if (isPressEscape(e)) {
      //   onClose()
      // }
    }

    useEffect(() => {
      document.addEventListener('keyup', onEscape, false)
      return () => {
        document.removeEventListener('keyup', onEscape, false)
      }
    })

    return createPortal(
      <div className={classes.modalAlternative}>
        <div className={classes.overlay} />
        <div className={classes.wrapper} style={CSS}>
          <div
            ref={modalRef}
            className={classnames(classes.modal, className, {
              noPadding,
              hideOverflow,
            })}
          >
            {children}
          </div>
          {onClose &&
            <CloseAlternative
              onClose={onClose}
            />
          }
        </div>
      </div>,
      root
    )
  }
)
