import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { createFactoringLimit } from '../../../../redux/Factoring/actions'
import { InputText, Checkbox, Radio, Select2, Fieldset, Label, Error, RowGrid, Col1, Col6, Col11, Col12 } from '../../../../layout'
import TextFieldWithAutoComplete from '../../../../components/TextFieldWithAutoComplete'
import { Search } from '../../../../services/api'
import { Button, ButtonWrapper, ButtonAdd, ButtonRemove, Form, H3 } from '../../Layout'
import styles from '../../styles'

const useStyles = createUseStyles({
  ...styles,
  addBtn: {
    marginTop: 10,
  },
})

const limitTypeOptions = [
  { value: '', name: '' },
  { value: 'legalEntityLimit', name: 'Лимит на юридическое лицо' },
  { value: 'factoringAgreementLimit', name: 'Лимит на договор факторинга' },
  { value: 'bundleLimit', name: 'Лимит на связку' },
  { value: 'documentLimit', name: 'Лимит на документы' },
]

const indicativeOptions = [
  { value: true, label: 'Да' },
  { value: false, label: 'Нет' },
]

const roleOptions = [
  { value: 'SUPPLIER', label: 'Поставщик' },
  { value: 'DEBTOR', label: 'Дебитор' },
]

const productsOptions = [
  { value: 'REGRESS', label: 'Регресс' },
  { value: 'WO_REGRESS', label: 'Безрегресс' },
]

const originalsOptions = [
  { value: 'ORIGINALS', label: 'Оригиналы документов' },
  { value: 'COPIES', label: 'Копии документов' },
]

export const AddLimitForm = ({
  parsedFilters,
  onGetFactoringView,
  onClose,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { entityType } = useParams()

  const [limitType, setLimitType] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [inn, setInn] = useState('')
  const [indicative, setIndicative] = useState(indicativeOptions[0].value)
  const [role, setRole] = useState(roleOptions[0].value)
  const [products, setProducts] = useState([])
  const [originals, setOriginals] = useState([])
  const [limitAmount, setLimitAmount] = useState('')
  const [beginDate, setBeginDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [suppliers, setSuppliers] = useState([{ inn: '', ogrn: '' }])
  const [debtors, setDebtors] = useState([{ inn: '', ogrn: '' }])
  const [contractPbIds, setContractPbIds] = useState([{ id: '', number: '' }])
  const [submitted, setSubmitted] = useState(0)

  const isRegress = products.find(item => item === 'REGRESS')
  const isDebtor = role === 'DEBTOR'
  const isSupplier = role === 'SUPPLIER'

  const isFactoringAgreementLimit = limitType === 'factoringAgreementLimit'
  const isBundleLimit = limitType === 'bundleLimit'
  const isDocumentLimit = limitType === 'documentLimit'

  const showContractPbIds = isFactoringAgreementLimit || isBundleLimit || isDocumentLimit
  const showSuppliers = isBundleLimit || isDocumentLimit
  const showDebtors = isBundleLimit || isDocumentLimit
  const showOriginals = isDocumentLimit

  const getPayload = () => {
    let payload = {
      inn,
      role,
      products,
      limitAmount,
      beginDate,
      endDate,
    }

    if (isRegress) {
      payload = {
        ...payload,
        subProducts: ['SECURITY', 'NON_SECURITY'],
      }
    }

    if (isSupplier && showDebtors) {
      const isEmptyDebtors = debtors?.length === 1 && debtors[0]?.inn === '' && debtors[0]?.ogrn === ''
      payload = {
        ...payload,
        indicative,
        debtors: isEmptyDebtors
          ? []
          : debtors.map(({ INN = '', OGRN ='' }) => ({ inn: INN, ogrn: OGRN })),
      }
    }

    if (isDebtor && showSuppliers) {
      const isEmptySuppliers = suppliers?.length === 1 && suppliers[0]?.inn === '' && suppliers[0]?.ogrn === ''
      payload = {
        ...payload,
        suppliers: isEmptySuppliers
          ? []
          : suppliers.map(({ INN = '', OGRN ='' }) => ({ inn: INN, ogrn: OGRN })),
      }
    }

    if (showContractPbIds) {
      const isEmptyContractPbIds = contractPbIds?.length === 1 && contractPbIds[0]?.id === ''
      payload = {
        ...payload,
        contractPbIds: isEmptyContractPbIds
          ? []
          : contractPbIds.map(({ id = '' }) => id),
      }
    }

    if (showOriginals) {
      payload = {
        ...payload,
        originals,
      }
    }

    return payload
  }

  const errors = {
    ...(limitType?.trim() ? {} : { limitType: 'Не выбран вид лимита' }),
    ...(inn?.trim() ? {} : { inn: 'Не выбран владелец лимита' }),
    ...(products?.length > 0 ? {} : { products: 'Не выбран продукт' }),
    ...(originals?.length === 0 && showOriginals ? { originals: 'Не выбрано значение' } : {}),
    ...(limitAmount?.trim() ? {} : { limitAmount: 'Не указана сумма' }),
    ...(beginDate?.trim() ? {} : { beginDate: 'Не указана дата окончания действия' }),
    ...(endDate?.trim() ? {} : { endDate: 'Не указана дата начала действия' }),
  }

  const isValid = Object.keys(errors)?.length === 0

  const onChangeLimitType = (e) => setLimitType(e.target.value)

  const onChangeInn = ({ INN, displayName }) => {
    setInn(INN)
    setCompanyName(displayName)
  }
  const onClearInn = () => {
    setInn('')
    setCompanyName('')
  }

  const onChangeSuppliers = (payload, index) => setSuppliers(
    suppliers.map((item, idx) => index === idx
      ? Object.keys(payload).reduce((result, i) => {
          result[i] = payload[i]
          return result
        }, {})
      : item
    )
  )
  const onClearSuppliers = (index) => setSuppliers(
    suppliers.map((item, idx) => index === idx
      ? { inn: '', ogrn: '', displayName: '' }
      : item
    )
  )

  const onChangeDebtors = (payload, index) => setDebtors(
    debtors.map((item, idx) => index === idx
      ? Object.keys(payload).reduce((result, i) => {
          result[i] = payload[i]
          return result
        }, {})
      : item
    )
  )
  const onClearDebtors = (index) => setDebtors(
    debtors.map((item, idx) => index === idx
      ? { inn: '', ogrn: '', displayName: '' }
      : item
    )
  )

  const onChangeContractPbIds = (payload, index) => setContractPbIds(
    contractPbIds.map((item, idx) => index === idx ? payload : item)
  )
  const onClearContractPbIds = (index) => setContractPbIds(
    contractPbIds.map((item, idx) => index === idx ? { id: '', number: '' } : item)
  )

  const onChangeBeginDate = (value) => setBeginDate(value)
  const onChangeEndDate = (value) => setEndDate(value)
  const onChangeIndicative = (value) => setIndicative(value)
  const onChangeRole = (value) => setRole(value)

  const onChangeProducts = (value) => setProducts(
    !!products.find(item => item === value)
      ? products.filter(item => item !== value)
      : [...products, value]
  )

  const onChangeHasOriginals = (value) => setOriginals(
    !!originals.find(item => item === value)
      ? originals.filter(item => item !== value)
      : [...originals, value]
  )

  const onChangeLimitAmount = (e) => setLimitAmount(e.target.value)

  const onAddDebitor = () => setDebtors([...debtors, { 'inn': '', 'ogrn': '' }])
  const onAddSupplier = () => setSuppliers([...suppliers, { 'inn': '', 'ogrn': '' }])
  const onAddContractPbId = () => setContractPbIds([...contractPbIds, ''])

  const onRemoveDebitor = (index) => setDebtors(debtors.filter((_, idx) => index !== idx))
  const onRemoveSupplier = (index) => setSuppliers(suppliers.filter((_, idx) => index !== idx))
  const onRemoveContractPbId = (index) => setContractPbIds(contractPbIds.filter((_, idx) => index !== idx))

  const onSubmit = () => setSubmitted(submitted + 1)

  const onCreateFactoringLimit = () => {
    const onSuccess = () => {
      onGetFactoringView(parsedFilters)
      onClose()
    }
    if (isValid) {
      dispatch(createFactoringLimit(getPayload(), entityType, onSuccess))
    }
  }

  useEffect(() => {
    if (submitted > 0) {
      onCreateFactoringLimit()
    }
  // eslint-disable-next-line
  }, [submitted])

  console.log('payload [CURRENT]:', getPayload())
  console.log('errors', errors)

  return (
    <>
      <H3>Добавить лимит:</H3>
      <Form>
        <Fieldset>
          <RowGrid>
            <Col12>
              <Select2
                label='Вид лимита'
                name='limitType'
                options={limitTypeOptions}
                value={limitType}
                onChange={onChangeLimitType}
                error={submitted > 0 && errors.limitType}
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <TextFieldWithAutoComplete
                classNames={{ container: 'filter-input', input: 'filter-input__input' }}
                name='inn'
                placeholder='Наименование компании'
                label='Наименование компании'
                value={companyName}
                defaultValue={companyName}
                onSelect={onChangeInn}
                onClear={onClearInn}
                payloadKeys={['INN', 'displayName']}
                meta={{
                  touched: true,
                  error: submitted > 0 ? errors.inn : '',
                }}
                withoutLink
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <Label>Роль</Label>
              {roleOptions.map(({ value, label }, index) =>
                <Radio
                  key={index}
                  id={`role-${value}`}
                  name='role'
                  label={label}
                  value={value}
                  checked={role === value}
                  onChange={() => onChangeRole(value)}
                />
              )}
            </Col12>
          </RowGrid>
        </Fieldset>
        {isSupplier &&
          <Fieldset>
            <RowGrid>
              <Col12>
                <Label>Владелец лимита дебитор</Label>
                {indicativeOptions.map(({ value, label }, index) =>
                  <Radio
                    key={index}
                    id={`indicative-${value}`}
                    name='indicative'
                    label={label}
                    value={value}
                    checked={indicative === value}
                    onChange={() => onChangeIndicative(value)}
                  />
                )}
              </Col12>
            </RowGrid>
          </Fieldset>
        }
        <Fieldset>
          <RowGrid>
            <Col6>
              <InputText
                type='date' 
                name='beginDate'
                label='Дата начала действия'
                value={beginDate}
                onChange={onChangeBeginDate}
                error={submitted > 0 && errors?.beginDate}
              />
            </Col6>
            <Col6>
              <InputText
                type='date' 
                name='endDate'
                label='Дата окончания действия'
                value={endDate}
                onChange={onChangeEndDate}
                error={submitted > 0 && errors?.endDate}
              />
            </Col6>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <InputText
                type='text' 
                name='limitAmount'
                label='Сумма'
                value={limitAmount}
                onChange={onChangeLimitAmount}
                error={submitted > 0 && errors?.limitAmount}
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <Label>Продукт</Label>
              {productsOptions.map(({ value, label }, index) =>
                <Checkbox
                  key={index}
                  id={`products-${value}`}
                  name='products'
                  label={label}
                  value={value}
                  checked={products?.find(item => item === value)}
                  onChange={() => onChangeProducts(value)}
                  error={submitted > 0 && errors?.products}
                />
              )}
              {submitted > 0 && errors?.products &&
                <Error>{errors.products}</Error>
              }
            </Col12>
          </RowGrid>
        </Fieldset>
        {showOriginals &&
          <Fieldset>
            <RowGrid>
              <Col12>
                <Label>Документы</Label>
                {originalsOptions.map(({ value, label }, index) =>
                  <Checkbox
                    key={index}
                    id={`originals-${value}`}
                    name='originals'
                    label={label}
                    value={value}
                    checked={originals?.find(item => item === value)}
                    onChange={() => onChangeHasOriginals(value)}
                    error={submitted > 0 && errors?.originals}
                  />
                )}
                {submitted > 0 && errors?.originals &&
                  <Error>{errors.originals}</Error>
                }
              </Col12>
            </RowGrid>
          </Fieldset>
        }
        {isDebtor && showSuppliers &&
          <Fieldset>
            <RowGrid>
              <Col12>
                <Label>Поставщик</Label>
                {suppliers.map(({ displayName }, index) => {
                  const showRemoveButton = suppliers?.length > 1
                  const Column = showRemoveButton ? Col11 : Col12
                  return (
                    <RowGrid key={index}>
                      <Column> 
                        <TextFieldWithAutoComplete
                          classNames={{ container: 'filter-input', input: 'filter-input__input' }}
                          name='suppliers'
                          placeholder='Поставщик'
                          value={displayName}
                          defaultValue={displayName}
                          onSelect={(payload) => onChangeSuppliers(payload, index)}
                          onClear={() => onClearSuppliers(index)}
                          payloadKeys={['INN', 'OGRN', 'displayName']}
                          withoutLink
                        />
                      </Column>
                      {showRemoveButton &&
                        <Col1>
                          <ButtonRemove
                            title='Удалить поставщика'
                            onClick={() => onRemoveSupplier(index)}
                          />
                        </Col1>
                      }
                    </RowGrid>
                  )
                })}
                <ButtonAdd
                  className={classes.addBtn}
                  text='Добавить поставщика'
                  onClick={onAddSupplier}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
        }
        {isSupplier && showDebtors &&
          <Fieldset>
            <RowGrid>
              <Col12>
                <Label>Дебитор</Label>
                {debtors.map(({ displayName }, index) => {
                  const showRemoveButton = debtors?.length > 1
                  const Column = showRemoveButton ? Col11 : Col12
                  return (
                    <RowGrid key={index}>
                      <Column>
                        <TextFieldWithAutoComplete
                          classNames={{ container: 'filter-input', input: 'filter-input__input' }}
                          name='debtors'
                          placeholder='Дебитор'
                          value={displayName}
                          defaultValue={displayName}
                          onSelect={(payload) => onChangeDebtors(payload, index)}
                          onClear={() => onClearDebtors(index)}
                          payloadKeys={['INN', 'OGRN', 'displayName']}
                          withoutLink
                        />
                      </Column>
                      {showRemoveButton &&
                        <Col1>
                          <ButtonRemove
                            title='Удалить дебитора'
                            onClick={() => onRemoveDebitor(index)}
                          />
                        </Col1>
                      }
                    </RowGrid>
                  )
                })}
                <ButtonAdd
                  className={classes.addBtn}
                  text='Добавить дебитора'
                  onClick={onAddDebitor}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
        }
        {showContractPbIds &&
          <Fieldset>
            <RowGrid>
              <Col12>
                <Label>Номер договора факторинга</Label>
                {contractPbIds.map((item, index) => {
                  const showRemoveButton = contractPbIds?.length > 1
                  const Column = showRemoveButton ? Col11 : Col12
                  return (
                    <RowGrid key={index}>
                      <Column>
                        <TextFieldWithAutoComplete
                          classNames={{ container: 'filter-input', input: 'filter-input__input' }}
                          name='contractPbIds'
                          placeholder='Номер договора факторинга'
                          value={item?.number || ''}
                          defaultValue={item?.number || ''}
                          onSelect={(payload) => onChangeContractPbIds(payload, index)}
                          onClear={() => onClearContractPbIds(index)}
                          payloadKeys={['id', 'number']}
                          request={Search.getFactoringContractSuggestions}
                          requestKey='number'
                          requestSubKeys={['displayName', 'supplierInn', 'startDate']}
                          withoutLink
                        />
                      </Column>
                      {showRemoveButton &&
                        <Col1>
                          <ButtonRemove
                            title='Удалить номер договора факторинга'
                            onClick={() => onRemoveContractPbId(index)}
                          />
                        </Col1>
                      }
                    </RowGrid>
                  )
                })}
                <ButtonAdd
                  className={classes.addBtn}
                  text='Добавить номер договора факторинга'
                  onClick={onAddContractPbId}
                />
              </Col12>
            </RowGrid>
          </Fieldset>
        }
      </Form>
      <ButtonWrapper>
        <Button
          text='Создать'
          disabled={submitted > 0 && !isValid}
          onClick={onSubmit}
        />
      </ButtonWrapper>
    </>
  )
}