import React, { PureComponent } from 'react'
import Highlighter from 'react-highlight-words'

class ListItem extends PureComponent {

  handleClick = () => this.props.onClick(this.props.data, this.props.text)

  render() {
    const {
      text,
      searchQuery,
      textToHighlight,
      // id,
      // withoutLink,
    } = this.props

    return (
      <div className='dropdown-item' onClick={this.handleClick}>
        <Highlighter
          className='filter__title'
          highlightClassName='autocomplete__identity'
          searchWords={[searchQuery]}
          autoEscape={true}
          textToHighlight={text}
        />
        {textToHighlight &&
          <Highlighter
            className='filter__inn'
            highlightClassName='autocomplete__identity'
            searchWords={[searchQuery]}
            autoEscape={true}
            textToHighlight={textToHighlight}
          />
        }
      </div>
    )
  }
}

export default ListItem