import React, { forwardRef, useEffect, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Label } from './../index'
import { Icon, Error } from './../../layout'
import { YYYYMMDD } from './../../utils'

import ru from 'date-fns/locale/ru'
import 'react-datepicker/dist/react-datepicker.css'
registerLocale('ru', ru)

const useStyles = createUseStyles({
  inputText: {
    position: 'relative',
    '& .react-datepicker-wrapper': {
      display: 'block',
    },
  },
  helper: {
    position: 'relative',
  },
  datepicker: {
    fill: '#9B9B9B',
    width: 24,
    height: 24,
    display: 'block',
    position: 'absolute',
    top: 7,
    left: 14,
    pointerEvents: 'none',
  },
  input: {
    width: '100%',
    height: 38,
    padding: [9, 16],
    border: [1, 'solid', '#DDD'],
    borderRadius: 16,
    fontSize: 14,
    outline: 'none',
    '&.datepicker': {
      paddingLeft: 48,
    },
    '&:hover, &:focus': {
      outline: 'none',
    },
    '&.error': {
      borderColor: 'red',
    },
  },
  label: {
    textAlign: 'left',
  },
})

export const InputText = forwardRef(({
  className,
  getRef,
  children,
  type,
  name,
  label,
  labelClassName,
  value,
  placeholder,
  disabled,
  error,
  onFocus,
  onChange,
  minDate,
  maxDate,
  autoFocus,
  popperPlacement,
  ...rest
}, ref) => {
  const classes = useStyles()

  const isDate = type === 'date'

  const [date, setDate] = useState(isDate && value ? new Date(value) : '')

  useEffect(() => {
    if (isDate && value) {
      setDate(new Date(value))
    }
  }, [isDate, value])

  const onChangeDate = value => {
    setDate(value)
    onChange(YYYYMMDD(value))
  }

  const datepickerOptions = {
    locale: 'ru',
    dateFormat: ['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy'],
    showPopperArrow: false,
    selected: date,
    minDate,
    maxDate,
    popperModifiers: {
      flip: {
        enabled: false,
      },
      preventOverflow: {
        enabled: true,
        escapeWithReference: false,
      },
    },
    onSelect: onChangeDate,
    onChange: onChangeDate,
    disabled,
  }

  const inputProps = {
    name,
    value,
    placeholder,
    disabled,
    autoFocus,
    autoComplete: 'off',
    ref: getRef,
    onFocus,
    onChange,
  }

  return (
    <div className={classes.inputText}>
      {label && 
        <Label className={classnames(classes.label, labelClassName)} htmlFor={name}>
          {label}
        </Label>
      }
      <div className={classes.helper}>
        {isDate
          ? <>
              <DatePicker
                {...datepickerOptions}
                customInput={
                  <input
                    type='text'
                    className={classnames(classes.input, 'datepicker', className, { error })}
                    {...inputProps}
                  />
                }
              />
              <Icon iconName='datepicker' className={classes.datepicker} />
            </>
          : <input
              type={type}
              className={classnames(classes.input, className, { error })}
              {...inputProps}
              {...rest}
            />
        }
        {children}
        {error && 
          <Error>{error}</Error>
        }
      </div>
    </div>
  )
})