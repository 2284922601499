import axios from 'axios'
import { ENDPOINTS } from '../constants/ENDPOINTS'

interface IConfirmResponse {
  status: {
    data: {
      guid: string
      date: string
    }
    meta: {
      type: string
      date: string
      error: number
      message: string
    }
  }
  error_code: number
}

class OtpService {
  sendCode(phone: string, token: string) {
    return axios.get(`${ENDPOINTS.USE_SEND_PHONE}${phone}&token=${token}`)
  }

  confirmCode(phone: string, code: string, token: string) {
    return axios.get<IConfirmResponse>(`${ENDPOINTS.USE_CHECK_PHONE_SEND_CODE}${phone}&code=${code}&token=${token}`)
  }
}

export const otpService = new OtpService()
