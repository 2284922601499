import React from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import {
  COLOR_VALUES,
  COLOR_VALUES_LIGHT,
  COLOR_VALUES_MIDDLE,
  COLORS_BY_ORDER_STATUS_CATEGORY,
} from '../../../../../config'

const useStyles = createUseStyles({
  root: {
    background: '#eee',
    padding: 30,
    borderRadius: 16,
    position: 'relative',
  },
  item: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    '& + &': {
      borderTop: [1, 'solid', '#ddd'],
    },
    '&.task': {
      background: '#E4E4E4',
      cursor: 'pointer',
    },
  },
  name: {
    fontSize: 16,
    lineHeight: 20/16,
    fontWeight: 500,
    color: '#4a4a4a',
    display: 'inline-block',
    verticalAlign: 'top',
  },
  date: {
    marginLeft: 16,
    fontSize: 16,
    lineHeight: 20/16,
    fontWeight: 500,
    color: '#9b9b9b',
    display: 'inline-block',
    verticalAlign: 'top',
  },
  badge: {
    padding: [4, 12],
    color: '#fff',
    fontSize: 12,
    lineHeight: 16/12,
    fontWeight: 500,
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    display: 'inline-block',
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
  },
})

export const Orders = ({
  orders,
  onOpenTaskDetail,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {orders?.length > 0
        ? orders.map(({ order_id, tasks, orderNumber, createdDate, phases }) => {
          const task = tasks ? tasks[0] : null
          const activePhase = phases[phases.length - 1]
          const { status } = activePhase || {}
          const colorKey = COLORS_BY_ORDER_STATUS_CATEGORY[status]
          const badgeCSS = {
            background: COLOR_VALUES_LIGHT[colorKey],
            borderColor: COLOR_VALUES_MIDDLE[colorKey],
            color: COLOR_VALUES[colorKey],
          }
          return (
            <div
              className={classnames(classes.item, { task })}
              onClick={() => task && onOpenTaskDetail(task.task_id, task.name)}
              key={order_id}
            >
              <div>
                <div className={classes.name}>{orderNumber}</div>
                <div className={classes.date}>от {createdDate}</div>
              </div>
              {activePhase &&
                <div className={classes.badge} style={badgeCSS}>
                  {activePhase.phaseName}
                </div>
              }
            </div>
          )})
        : 'Заявки отсутствуют'
      }
    </div>
  )
}