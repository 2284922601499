import { FC } from 'react'
import { Flex, Spacer, Text, Spinner, Heading } from '@mtsbank/ui-kit'

import { Styled } from './styled'

type LoadingScreenProps = {
    title: string
    description: string
}

export const LoadingScreen: FC<LoadingScreenProps> = ({ title, description }) => {
  return (
    <Styled.Container>
        <Flex alignContent='center' justify='center' direction='column'>
            <div>
                <Spinner />
            </div>
            <Spacer space={20} samespace />
            <Heading 
              as="h4" 
              size="sm" 
              sizemob="sm" 
              fontWeight="medium" 
              align="center"
            >{title}</Heading>
            <Spacer space={10} samespace />
            <Text>{description}</Text>
        </Flex>
    </Styled.Container>
  )
}
