const fetchWrapper = (url, opts) => fetch(url, opts)
  .then((response) => {
    if (response.ok) {
      if (response.status === 204) {
        return {}
      }
      if (response.headers?.get('Content-Type')?.includes('application/json')) {
        return response.json()
      }
      return response
    }

    return response
      .json()
      .catch(() =>
        Promise.reject({
          status: response.status,
          text: 'Request JSON error',
        }),
      )
      .then((json) =>
        Promise.reject({
          status: response.status,
          text: json.detail || json.error_message || json.message,
          type: json.error_type,
          value: json.error_value,
          error: json.error,
        }),
      )
  })
  .catch((error) => {
    if (error instanceof Error) {
      return Promise.reject({ text: error.toString() })
    }

    if (typeof error === 'string' || error instanceof String) {
      return Promise.reject({ text: error })
    }

    if (error && typeof error === 'object' && error.constructor === Object) {
      const { status, text } = error
      return Promise.reject({
        status,
        text,
        type: error.type,
        value: error.value,
        error: error.error,
        message: error.message,
        notAuthenticated: status === 302,
      })
    }

    return Promise.reject({
      text: 'Unknown error',
    })
  })

const getHeaders = () => {
  return {
    'Content-Type': 'application/json',
  }
}

export const get = (url, opts) => fetchWrapper(url, {
  method: 'GET',
  crossDomain: true,
  headers: opts && opts.Authorization
    ? { ...getHeaders(), ...opts }
    : getHeaders(),
  credentials: 'include',
  ...opts,
})

export const post = (url, payload, opts) => fetchWrapper(url, {
  method: 'POST',
  crossDomain: true,
  headers: opts && opts.type === 'formData'
    ? opts && opts.Authorization
      ? { 'Authorization': opts.Authorization }
      : {}
    : getHeaders(),
  body: opts && opts.type === 'formData'
    ? payload
    : JSON.stringify(payload),
  credentials: 'include',
  ...opts,
})

export const patch = (url, payload, opts) => fetchWrapper(url, {
  method: 'PATCH',
  crossDomain: true,
  headers: getHeaders(),
  body: JSON.stringify(payload),
  credentials: 'include',
  ...opts,
})

export const put = (url, payload, opts) => fetchWrapper(url, {
  method: 'PUT',
  crossDomain: true,
  headers: getHeaders(),
  body: JSON.stringify(payload),
  credentials: 'include',
  ...opts,
})

export const _delete = (url, payload, opts) => fetchWrapper(url, {
  method: 'DELETE',
  crossDomain: true,
  headers: getHeaders(),
  body: JSON.stringify(payload),
  credentials: 'include',
  ...opts,
})