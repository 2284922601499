import { useState } from 'react'
import { IDataToSignItem, ISignDoc } from '../../declarations'
import axios from 'axios'
import { ERRORS } from '../../constants'

const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength

  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }

  return btoa(binary)
}

const signAllDoc = async (documents: ISignDoc[], dataToSign: IDataToSignItem[]) => {
  const signedDocuments = []
  for (const doc of documents) {
    const isAlreadySigned = dataToSign.some(signedDoc => signedDoc.filename === doc.name)
    if (isAlreadySigned) {
      continue
    }
    const signedData = await axios.get(doc.url, { responseType: 'arraybuffer' })
    signedDocuments.push({
      content: arrayBufferToBase64(signedData.data),
      filename: doc.name + '.sig',
      fileId: doc.id
    })
  }
  alert('Документы успешно отправлены')
  return signedDocuments
}

export const useSignAllDocWithoutCert = () => {
  const [isSigningAllWithoutCert, setIsSigningAllWithoutCert] = useState<boolean>(false)
  const [signAllWithOutCertError, setSignAllWithOutCertError] = useState<string | null>(null)
  const [sendingData, setSendingData] = useState<IDataToSignItem[] | null>(null)

  const signDocumentsWithoutCert = async (
    documents: ISignDoc[],
    setDataToSign: React.Dispatch<React.SetStateAction<IDataToSignItem[]>>,
    dataToSign: IDataToSignItem[]
  ) => {
    setIsSigningAllWithoutCert(true)
    setSignAllWithOutCertError(null)

    try {
      const signedData = await signAllDoc(documents, dataToSign)
      setSendingData(signedData)
      signedData.map(doc => {
        setDataToSign(prevState => [...prevState, doc])
      })
    } catch (err) {
      setSignAllWithOutCertError(ERRORS.SIGN_ERROR + err)
    } finally {
      setIsSigningAllWithoutCert(false)
    }
  }

  return { isSigningAllWithoutCert, signAllWithOutCertError, signDocumentsWithoutCert }
}
