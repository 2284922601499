import React, {useState} from 'react'
import {createUseStyles} from "react-jss";
import {ModalAlternative} from '../../../containers/ModalAlternative/ModalAlternative'
import {useHistory} from "react-router-dom";
import classnames from "classnames";
import {useDispatch} from "react-redux";
import moment from 'moment'
import 'moment/locale/ru'
import { getFile } from '../../../redux/Files/actions'
moment.locale('ru')

const useStyles = createUseStyles({
  ModalTask: {
    padding: 20,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  ModalTask__title: {
    fontSize: 22,
    textAlign: 'center',
    fontWeight: 500,
    marginBottom: 24,
  },
  ModalTask__body: {
    display: 'flex'

  },
  datepicker: {
    fill: '#9B9B9B',
    width: 24,
    height: 24,
    display: 'block',
    // position: 'absolute',
    // top: 7,
    // left: 14,
    pointerEvents: 'none',
  },
  input: {
    marginTop: 10,
    width: '100%',
    height: 38,
    padding: [9, 16],
    border: [1, 'solid', '#DDD'],
    borderRadius: 16,
    fontSize: 14,
    outline: 'none',
    '&.datepicker': {
      paddingLeft: 48,
    },
    '&:hover, &:focus': {
      outline: 'none',
    },
    '&.error': {
      borderColor: 'red',
    },
  },
  ModalTask__col6: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    margin: 15,
    'label': {
      color: '#000',
      display: 'block',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 18,
      marginBottom: 4
    }
  },

  ModalTask__blockModalButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 40,
    '& > button': {
      color: '#fff',
      borderRadius: 8,
      padding: [9, 35],
      '&:hover': {
        transform: 'scale(1.03)'
      },
      '&:active': {
        transform: 'scale(0.99)'
      }
    }
  },
  ModalTask__buttonCancel: {
    background: '#aaa',
  },
  ModalTask__buttonSubmit: {
    background: '#22C7B5',
    marginLeft: 20,
  },
})

const YYYYMMDD = (date) => date ? moment(date).format('YYYY-MM-DD') : ''

export const ModalReports = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [dateFrom, setDateFrom] = useState(YYYYMMDD(Date.now() - 1209600000))
  const [dateTo, setDateTo] = useState(YYYYMMDD(Date.now()))
  const [loading, setLoading] = useState(false)
  const onCloseModal = () => history.push('/tasks/')

  const valid = dateFrom && dateTo

  const onDownloadFile = () => {
    if (valid && !loading) {
      setLoading(true)
      const endpoint = `/api/doc-template/form/bgStatsAgentsJournal/bg_statsAgentJournal.xlsx`
      const url = `${endpoint}?dateFrom=${dateFrom}&dateTo=${dateTo}&agentLogin=${props.userName}`
      const name = `bg_statsAgentJournal-${dateFrom}-${dateTo}.xlsx`
      const onSuccess = () => setLoading(false)
      const onError = () => setLoading(false)
      dispatch(getFile({ url, name, onSuccess, onError }))
      onCloseModal()
    }
  }
  return (
    <ModalAlternative height='auto' onClose={onCloseModal} classes={{modal: classes.ModalTask}}>
      <h1 className={classes.ModalTask__title}>Статистика</h1>

      <div className={classes.ModalTask__body}>
        <div className={classes.ModalTask__col6}>
          <label htmlFor="dateFrom">Дата от</label>
          <input type="date"
                 id='dateFrom'
                 value={dateFrom}
                 onChange={({target: {value}}) => setDateFrom(value)}
                 className={classnames(classes.input, 'datepicker')}
          />
        </div>
        <div className={classes.ModalTask__col6}>
          <label htmlFor="dateTo">Дата по</label>
          <input type="date"
                 id='dateTo'
                 value={dateTo}
                 onChange={({target: {value}}) => setDateTo(value)}
                 className={classnames(classes.input, 'datepicker')}
          />
        </div>
      </div>

      <div className={classes.ModalTask__blockModalButton}>
        <button className={classes.ModalTask__buttonCancel} onClick={onCloseModal}>Отменить</button>
        <button className={classes.ModalTask__buttonSubmit} onClick={onDownloadFile}>Получить отчёт</button>
      </div>
    </ModalAlternative>
  )
}