import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import { Pagination } from '../../components/Pagination'
import Overlay from '../../components/Overlay'
import { getFactoringShortInfo } from '../../redux/Factoring/actions'
// import Header from '../../containers/Header'
// import { Burger } from '../../components/Burger'
import HeaderFilter from '../../containers/HeaderFilter'
import { Title, ResetFilters } from './Layout'
import { getPaginationSize } from '../../utils/paginationStorage'
import { createSearchString } from '../../utils'
import { getFilterItem } from './getFilterItem'
import { FactoringModal } from './FactoringViewPage/FactoringModal'

const useStyles = createUseStyles({
  wrapper: {
    marginTop: 20,
  },
  filterItem: {
    flex: 1,
    '& + &': {
      marginLeft: 10,
    },
  },
})

const DEFAULT_OFFSET = { offset: 0 }
const STATIC_FILTERS = ['limit', 'offset']

export const FactoringWrapper = ({
  title,
  dataFilters,
  getFilters,
  entityType,
  onRequest,
  requestPayload,
  children,
  withoutPagination,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const SIZE = getPaginationSize()

  const { views, shortInfo, isFetching } = useSelector(state => state?.Factoring || {})

  const { page = 1, totalCount, totalPages } = views

  const filtersInitialState = dataFilters.reduce((result, item) => {
    result[item.name] = item.type === 'select'
      ? item.options[0].id
      : ''
    return result
  }, {})

  const parsed = useMemo(() => {
    return queryString.parse(history.location.search)
  }, [history.location])

  const defaultFilters = useMemo(() => {
    return {
      ...filtersInitialState,
      limit: parsed.limit || SIZE,
      offset: parsed.offset || DEFAULT_OFFSET.offset,
    }
  }, [parsed, filtersInitialState, SIZE])

  const parsedFilters = useMemo(() => {
    return {
      ...filtersInitialState,
      ...parsed,
      limit: parsed.limit || defaultFilters.limit,
      offset: parsed.offset || defaultFilters.offset,
    }
  }, [parsed, filtersInitialState, defaultFilters.limit, defaultFilters.offset])

  const [fixed, setFixed] = useState(false)
  const [filters, setFilters] = useState(parsedFilters)
  const [values, setValues] = useState(parsedFilters)

  useEffect(() => {
    if (history.location?.state?.resetFilters) {
      setFilters(defaultFilters)
      setValues({})
    }
    // eslint-disable-next-line
  }, [entityType, history.location])

  useEffect(() => {
    history.push({
      pathname: history.location.pathname,
      search: createSearchString(filters),
    })
  }, [filters, history])

  useEffect(() => {
    getFilters && getFilters(filters)
  }, [filters, getFilters])

  useEffect(() => {
    if (shortInfo?.showFieldName && shortInfo?.displayName) {
      setValues({
        ...values,
        [shortInfo.showFieldName]: shortInfo.displayName,
      })
    }
    // eslint-disable-next-line
  }, [shortInfo.showFieldName, shortInfo.displayName])

  const onRequestCallback = useCallback((filters) => {
    return entityType
      ? dispatch(onRequest(requestPayload, filters))
      : {}
    // eslint-disable-next-line
  }, [entityType, onRequest, dispatch])

  useEffect(() => {
    onRequestCallback(parsedFilters)
    // eslint-disable-next-line
  }, [onRequestCallback])

  useEffect(() => {
    const { showValue, name } = dataFilters.find(({ showKey, showValue }) =>
      showKey && showValue && values[showKey] === showValue
    ) || {}
    if (parsed.entityId) {
      dispatch(getFactoringShortInfo(showValue, parsed.entityId, name))
    }
    // eslint-disable-next-line
  }, [parsed.entityId])

  const onGetItems = (page, size) => {
    const newFilters = {
      ...filters,
      limit: size,
      offset: (page - 1) * size,
    }
    setFilters(newFilters)
    onRequestCallback(newFilters)
  }

  const isCanResetFilters = Object.keys(filters)
    .filter(item => STATIC_FILTERS.indexOf(item) === -1)
    .some(value => !!values[value])

  const onClearAllFilters = () => {
    setFilters(defaultFilters)
    setValues(defaultFilters)
    onRequestCallback(defaultFilters)
  }

  const pageProps = {
    DEFAULT_OFFSET,
    entityType,
    values,
    title,
    filters,
    dataFilters,
    setFilters,
    setValues,
    onGetFactoringView: onRequestCallback,
  }

  const onScroll = () => {
    if (!fixed && window.pageYOffset > 0) {
      setFixed(true)
    } else if (fixed && window.pageYOffset === 0) {
      setFixed(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    window.addEventListener('resize', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
      window.removeEventListener('resize', onScroll)
    }
  })

  const showPagination = totalCount > 0 && totalPages > 1

  return (
    <>
      {/* <Burger /> */}
      {/* <Header fixed={fixed} burger> */}
        <HeaderFilter>
          {dataFilters.map((item, index) => {
            const FilterItem = getFilterItem({...pageProps, ...item})
            return FilterItem
              ? <div className={classes.filterItem} key={index}>
                  <FilterItem {...pageProps} {...item} />
                </div>
              : null
          })}
          {isCanResetFilters &&
            <ResetFilters onClick={onClearAllFilters} />
          }
        </HeaderFilter>
      {/* </Header> */}
      <div className={classes.wrapper}>
        <Title>{title}</Title>
        {children}
        {showPagination &&
          <Pagination
            page={page}
            pages={totalPages}
            total={totalCount}
            onGetItems={onGetItems}
          />
        }
      </div>
      <FactoringModal
        entityType={entityType}
        parsedFilters={parsedFilters}
        onGetFactoringView={onRequestCallback}
      />
      {isFetching &&
        <Overlay size='big' />
      }
    </>
  )
}