import React, { FC } from 'react'
import { ICompanyData } from '../../declarations'
import { ERRORS, TEXT } from '../../constants'

interface ICompanyInfoProps {
  data?: ICompanyData
  loading: boolean
  error?: string
}

export const CompanyInfo: FC<ICompanyInfoProps> = ({ data, loading, error }) => {
  if (loading) {
    return <p>{TEXT.LOADING_COMPANY_INFO}</p>
  }

  if (error) {
    return <p> {ERRORS.LOADING_ERROS + error}</p>
  }

  if (!data) {
    return <p>{ERRORS.LOADING_DATA_ERROR}</p>
  }

  return (
    <>
      <h1>{data?.fullName}</h1>
      <h3>
        {data?.inn}, {data?.kpp}, {data?.ogrn}
      </h3>
    </>
  )
}
