import React, { FC, useState } from 'react'
import { Styled } from './styled'
import { IDataToSignItem, ISignDoc } from '../../declarations'
import { Certificates } from '../../components'
import { useSignDocument, useCertificates } from '../../hooks'
import { useSignAllDocument } from '../../hooks/useSiganAllDocuments'
import { ERRORS, TEXT } from '../../constants'
import { useSignAllDocWithoutCert } from '../../api'
import { groupDocByType } from './helpers'
import { Divider } from '@mtsbank/ui-kit'

interface ICompanyInfoProps {
  data?: ISignDoc[]
  loading: boolean
  error: string
  dataToSign: IDataToSignItem[]
  setDataToSign: React.Dispatch<React.SetStateAction<IDataToSignItem[]>>
  onSignAllDocs: (cert: string) => void
}

export const Document: FC<ICompanyInfoProps> = ({ data, loading, error, dataToSign, setDataToSign, onSignAllDocs }) => {
  const [selectedCert, setSelectedCert] = useState<string>('')
  const [currentDocument, setCurrentDocument] = useState<ISignDoc | null>(null)
  const [signAllStart, setSignAllStart] = useState(false)
  const { certificates, isLoading: isCertLoading, error: certError, loadCertificates } = useCertificates()
  const { isSigning, signError, sign } = useSignDocument()

  //Отправить документы без подписи
  const { isSigningAllWithoutCert, signAllWithOutCertError, signDocumentsWithoutCert } = useSignAllDocWithoutCert()

  const handleSignButtonClick = (document: ISignDoc) => {
    if (isCertLoading) {
      return
    }
    if (certError) {
      return console.error(certError)
    }
    setCurrentDocument(document)
    loadCertificates()
  }

  const handleCertificateSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCert(e.target.value)
  }

  const handleSignAllButtonClick = () => {
    if (isCertLoading) {
      return
    }
    if (certError) {
      return console.error(certError)
    }
    loadCertificates()
    setSignAllStart(true)
  }

  const handleCertificateSelectionCancel = () => {
    setCurrentDocument(null)
    setSignAllStart(false)
  }

  const handleSignDocument = () => {
    if (!selectedCert || !currentDocument?.url || !currentDocument?.name) {
      console.error(ERRORS.CHOOSE_SERTIFICATE_ERROR)
      return
    }
    if (isSigning) {
      return
    }
    if (signError) {
      return console.error(signError)
    }
    sign(selectedCert, currentDocument!.url, currentDocument!.name, currentDocument!.id, setDataToSign, dataToSign)
  }

  const handleSignAllDocs = () => {
    onSignAllDocs(selectedCert)
  }

  const handleSignWithoutCert = () => {
    if (isSigningAllWithoutCert) {
      return
    }
    if (!data) {
      return console.error(ERRORS.NO_DOCUMENTS_TO_SIGN)
    }
    signDocumentsWithoutCert(data, setDataToSign, dataToSign)
  }

  if (loading) {
    return <p>{TEXT.LOADING_COMPANY_DATA}</p>
  }

  if (error) {
    return <p>{ERRORS.LOADING_ERROS + error}</p>
  }

  if (!data) {
    return <p>{ERRORS.LOADING_DATA_ERROR}</p>
  }

  const groupedDocuments = groupDocByType(data)

  return (
    <>
      {Object.keys(groupedDocuments).map(type => (
        <Styled.DocumentGroup key={type}>
          <Styled.DocumentTitle>{groupedDocuments[type].typeRu}</Styled.DocumentTitle>
          {groupedDocuments[type].documents.map(document => (
            <Styled.LinkWrapper key={document.id}>
              <Styled.DocumentLink href={document.url}>{document.name}</Styled.DocumentLink>
              {currentDocument?.id === document.id ? (
                <Certificates
                  certificates={certificates}
                  handleCertificateSelection={handleCertificateSelection}
                  handleSignButtonClick={handleSignDocument}
                  handleCertificateSelectionCancel={handleCertificateSelectionCancel}
                />
              ) : (
                <Styled.DocumentButton icon="baseX16/ic-edit" onClick={() => handleSignButtonClick(document)} />
              )}
            </Styled.LinkWrapper>
          ))}
        </Styled.DocumentGroup>
      ))}
      {!loading && (
        <>
          <Divider />
          <Styled.LinkWrapper>
            <Styled.DocumentTitle>{TEXT.SIGN_ALL_DOCS}</Styled.DocumentTitle>
            {signAllStart ? (
              <Certificates
                certificates={certificates}
                handleCertificateSelection={handleCertificateSelection}
                handleSignButtonClick={handleSignAllDocs}
                handleCertificateSelectionCancel={handleCertificateSelectionCancel}
              />
            ) : (
              <Styled.DocumentButton icon="baseX16/ic-edit" onClick={() => handleSignAllButtonClick()} />
            )}
          </Styled.LinkWrapper>
          <Divider />
        </>
      )}
      {/* <Styled.LinkWrapper>
        <p>Отправить без подписи</p>
        <Styled.DocumentButton icon="baseX16/ic-edit" onClick={() => handleSignWithoutCert()} />
      </Styled.LinkWrapper> */}
    </>
  )
}
