import { COLORS_BY_ORDER_STATUS_CATEGORY, COLOR_VALUES } from '../config'

export const prepareOrderStatusCategory = ({
  statusItems,
  orderStatusCategory,
}) => orderStatusCategory
  ? orderStatusCategory.map(item => ({
      ...item,
      text: item.name,
      className: COLORS_BY_ORDER_STATUS_CATEGORY[item.key],
      color: COLOR_VALUES[COLORS_BY_ORDER_STATUS_CATEGORY[item.key]],
    }))
  : statusItems