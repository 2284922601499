import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { isPressEscape } from '../../utils'

class Modal extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    preventOutsideClick: PropTypes.bool,
    topPosition: PropTypes.bool,
    centerPosition: PropTypes.bool,
    modalClass: PropTypes.string,
    dialogClass: PropTypes.string,
    contentClass: PropTypes.string,
  }

  static defaultProps = {
    preventOutsideClick: false,
    topPosition: false,
    centerPosition: false,
    modalClass: '',
    dialogClass: '',
    contentClass: '',
  }

  componentDidMount() {
    document.body.classList.add('modal-open')
    this.modal.classList.add('show')
    // document.addEventListener('click', this.onOutsideClick, false)
    document.addEventListener('keyup', this.onEscape, false)
  }

  componentWillUnmount() {
    this.modal.classList.remove('show')
    document.body.classList.remove('modal-open')
    // document.removeEventListener('click', this.onOutsideClick, false)
    document.removeEventListener('keyup', this.onEscape, false)
  }

  onEscape = (e) => {
    if (isPressEscape(e)) {
      const modalCloseElements = document.getElementsByClassName('fz-modal__button-close')
      if (modalCloseElements.length > 0) {
        modalCloseElements[0].click()
      } else {
        this.props.onCloseModal()
      }
    }
  }

  onOutsideClick = (event) => {
    const { preventOutsideClick } = this.props
    if (preventOutsideClick) return
    if (this.content && this.content.contains(event.target)) return
    if (this.close && this.close.contains(event.target)) return
    const href = event.target.getAttribute('href')
    const isDownload = event.target.getAttribute('download')
    if ((href && (href.search('/clients/') !== -1 || href.search('/users/new') !== -1)) || isDownload) return
    this.props.onCloseModal()
  }

  setRef = (item, node) => (this[item] = node)

  render() {
    const {
      children,
      topPosition,
      centerPosition,
      modalClass,
      dialogClass,
      contentClass,
      onCloseModal,
    } = this.props

    return (
      <div className={classnames('modal-custom', modalClass, {'modal-custom--empty': centerPosition})}>
        <div 
          className={classnames('modal', 'fade', 'show', {'bd-example-modal-lg': topPosition})}
          ref={this.setRef.bind(this, 'modal')}
          id='exampleModalCenter'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div className={classnames('modal-dialog', dialogClass, {
              'modal-dialog-centered': centerPosition,
              'modal-lg': topPosition,
            })}
            role='document'
          >
            <div 
              className={classnames('modal-content', contentClass)} 
              ref={this.setRef.bind(this, 'content')}
            >
              {children}
              <button
                type='button'
                className='close'
                aria-label='Close'
                onClick={onCloseModal}
                ref={this.setRef.bind(this, 'close')}
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
          </div>
        </div>
        <div className='modal-backdrop' />
      </div>
    )
  }
}

export default Modal