import * as actionTypes from './actionTypes'

const initialState = {
  isFetching: false,
  error: false,
  errors: {},
  data: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CALCULATE:
      return { 
        ...state, 
        isFetching: true, 
        error: false,
      }
    case actionTypes.CALCULATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          bgtc: action.data,
        },
        errors: {},
      }
    case actionTypes.CALCULATE_ERROR:
      return { 
        ...state, 
        isFetching: false,
        error: action?.error, 
        errors: action?.errors, 
      }
    case actionTypes.RESET_CALCULATE:
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          bgtc: {},
        },
        error: false,
        errors: {},
      }
    default:
      return state
  }
}