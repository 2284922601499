import styled from 'styled-components'
import { Icon } from '@mtsbank/ui-kit'

const DocumentGroup = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`

const LinkWrapper = styled.div`
  font-weight: normal;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const DocumentTitle = styled.label`
  font-size: 18px;
  font-weight: 500;
`
const DocumentLink = styled.a`
  display: block;
  margin-top: 5px;
`

const DocumentButton = styled(Icon)`
  cursor: pointer;
  margin-top: 10px;
`

const ButtonSectionWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
`
export const Styled = { DocumentGroup, DocumentTitle, DocumentLink, DocumentButton, LinkWrapper, ButtonSectionWrapper }
