import React from 'react'
import injectSheet from 'react-jss'

const styles = {
	root: {
    background: '#f4f4f4',
    marginTop: 32,
    borderRadius: 16,
    position: 'relative',
	},
}

const Wrapper = ({ 
  children,
	classes,
}) => <div className={classes.root}>{children}</div>

export default injectSheet(styles)(Wrapper)