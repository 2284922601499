import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import Icon from '../../../layout/Icons'
import Justify from '../Justify'
import Item from '../Item'
import ItemGrey from './../ItemGrey'
import { adaptive_1200, adaptive_1400 } from '../../../config'

const styles = {
  root: {
    padding: [30, 90, 30, 40],
    borderRadius: 16,
    marginBottom: 30,
    [adaptive_1400]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
    [adaptive_1200]: {
      borderRadius: 0,
    },
  },
  logo: {
    backgroundPosition: 'top left',
    backgroundRepeat: 'no-repeat',
    [adaptive_1200]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  icons: {
    display: 'inline-block',
    verticalAlign: 'top',
    [adaptive_1200]: {
      width: '100%',
      marginTop: 30,
      display: 'block',
    },
  },
  item: {
    paddingLeft: 70,
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    [adaptive_1200]: {
      display: 'block',
    },
    '& + &': {
      marginLeft: 90,
      [adaptive_1400]: {
        marginLeft: 20,
      },
      [adaptive_1200]: {
        marginTop: 10,
        marginLeft: 0,
      },
    },
  },
  icon: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: 24,
      height: 24,
      display: 'block',
      fill: '#fff',
    },
  },
  value: {
    color: '#fff',
    fontWeight: 600,
  },
  key: {
    color: '#fff',
  },
}

const Header = ({ 
  className,
  items, 
  classes, 
}) =>
  <header className={classnames(classes.root, className)}>
    <Justify>
      <div className={classnames(classes.logo, 'verify-logo')} />
      <div className={classes.icons}>
        {items.map(({ iconName, key, value }) =>
          <div className={classes.item} key={key}>
            <div className={classes.icon}>
              <Icon iconName={iconName} />
            </div>
            <ItemGrey.Value className={classes.value}>{value}</ItemGrey.Value>
            <Item.Key className={classes.key}>{key}</Item.Key>
          </div>
        )}
      </div>
    </Justify>
  </header>

export default injectSheet(styles)(Header)