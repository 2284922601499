import React, { FC } from 'react'
import { Certificate } from 'crypto-pro'
import { Styled } from './styled'
import { TEXT } from '../../constants'
import { Button } from '@mtsbank/ui-kit'

interface ICertificatesProps {
  certificates?: Certificate[]
  handleCertificateSelection: (e: React.ChangeEvent<HTMLSelectElement>) => void
  handleSignButtonClick: () => void
  handleCertificateSelectionCancel: () => void
}

export const Certificates: FC<ICertificatesProps> = ({
  certificates,
  handleCertificateSelection,
  handleSignButtonClick,
  handleCertificateSelectionCancel
}) => {
  return (
    <>
      <Styled.Select onChange={handleCertificateSelection}>
        <option value="">{TEXT.CHOOSE_CERTIFICATE}</option>
        {certificates?.map((cert, index) => (
          <Styled.Options key={index} value={cert.thumbprint}>
            {`${cert.name}, действует c ${cert.validFrom} по ${cert.validTo}`}
          </Styled.Options>
        ))}
      </Styled.Select>
      <Styled.DocumentButton icon="baseX24/close" onClick={handleCertificateSelectionCancel} />
      <Button colorType="violet" size="sm" onClick={handleSignButtonClick}>
        {TEXT.SIGN}
      </Button>
    </>
  )
}
