import { ISignDoc } from '../../declarations'

interface IGroupedDoc {
  [key: string]: {
    typeRu: string
    documents: ISignDoc[]
  }
}

export const groupDocByType = (documents: ISignDoc[]): IGroupedDoc => {
  return documents.reduce((acc: IGroupedDoc, document) => {
    if (!acc[document.type]) {
      acc[document.type] = {
        typeRu: document.typeRu,
        documents: []
      }
    }
    acc[document.type].documents.push(document)
    return acc
  }, {})
}
