import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import Value from './../Value'
import { Icon } from './../../../layout'

const styles = {
  root: {
    marginTop: 21,
    position: 'relative',
    '& + &': {
      marginTop: 26,
    },
  },
  attachment: {
    marginTop: 16,
    paddingRight: 200,
    position: 'relative',
    '&.long': {
      paddingRight: 310,
    },
  },
  date: {
    marginLeft: 30,
    color: '#000',
    opacity: 0.5,
    fontSize: 14,
    lineHeight: 18/14,
    fontWeight: 500,
    display: 'inline-block',
  },
  links: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  link: {
    paddingLeft: 28,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 18/14,
    color: '#22C7B5',
    display: 'inline-block',
    position: 'relative',
    '&:hover': {
      color: '#22C7B5',
      textDecoration: 'none',
    },
    '& + &': {
      marginLeft: 30,
    },
    '& svg': {
      width: 18,
      height: 18,
      fill: '#22C7B5',
      position: 'absolute',
      left: 0,
      top: 0,
    }
  },
  inline: {
    display: 'inline-block',
  },
}

const Item = ({
  name,
  files,
  classes,
}) => 
  <div className={classes.root}>
    <Value>{name}</Value>
    {files.map(({ name, date, url, signUrl, isSigned }, index) =>
      <div 
        key={`${name}-${index}`}
        className={classnames(classes.attachment, { 
          'long': isSigned, 
        })}
      >
        <Value className={classes.inline}>
          <p>{name}</p>
        </Value>
        <div className={classes.date}>{date}</div>
        <div className={classes.links}>
          <a 
            className={classes.link} 
            href={url} 
            target='_blank' 
            download={isSigned ? name.replace('.sig', '') : name}
            rel='noopener noreferrer'
          >
            <Icon iconName='download' />
            <span>Оригинал</span>
          </a>
          {isSigned &&
            <a 
              className={classes.link}  
              href={signUrl}
              target='_blank' 
              download={name}
              rel='noopener noreferrer' 
            >
              <Icon iconName='download' />
              <span>Подписанный</span>
            </a>
          }
        </div>
      </div>
    )}
  </div>

export default injectSheet(styles)(Item)