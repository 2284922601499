import * as actionTypes from './actionTypes'

const initialState = {
  isFetching: false,
  isFetchingNext: false,
  filters: {},
  order: [],
  tasks: {},
  page: 0,
  more: false,
  amount_min: 0,
  amount_max: 40000000,
  taskUsers: {
    isFetching: false,
    users: [{ value: '' }],
    error: false
  },
  clientName: ''
}

const tasksAsMap = (tasks = []) => {
  return tasks.reduce((map, task) => {
    map[task.task_id] = task
    return map
  }, {})
}

const getTaskListFromOrders = orders => {
  let tasks = []
  const _orders = orders || []
  _orders.forEach(order => {
    tasks = tasks.concat(order.tasks || [])
  })
  return tasks
}

export const _byIds = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.TASKS_SUCCESS: {
      const tasks = getTaskListFromOrders(action.data.order)
      return tasksAsMap(tasks)
    }
    case actionTypes.NEXT_TASKS_SUCCESS: {
      const tasks = getTaskListFromOrders(action.data.order)
      return {
        ...state,
        ...tasksAsMap(tasks)
      }
    }
    case actionTypes.INSERT_TASK: {
      const { tasks } = action.data
      return {
        ...state,
        ...tasksAsMap(tasks)
      }
    }
    default: {
      return state
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TASKS_FETCH:
      return {
        ...state,
        isFetching: true
      }
    case actionTypes.TASKS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.data,
        _byIds: _byIds(state._byIds, action)
      }
    case actionTypes.TASKS_ERROR:
      return {
        ...state,
        isFetching: false
      }
    case actionTypes.NEXT_TASKS_FETCH:
      return {
        ...state,
        isFetchingNext: true
      }
    case actionTypes.NEXT_TASKS_SUCCESS:
      const { order, tasks } = action.data
      return {
        ...state,
        ...action.data,
        isFetchingNext: false,
        order: state.order.concat(order),
        tasks: Object.assign(state.tasks, tasks),
        _byIds: _byIds(state._byIds, action)
      }
    case actionTypes.INSERT_TASK:
      return {
        ...state,
        _byIds: _byIds(state._byIds, action)
      }
    case actionTypes.NEXT_TASKS_ERROR:
      return {
        ...state,
        isFetching: false
      }
    case actionTypes.TASKS_SET_FILTER:
      const { filters } = action.data
      return {
        ...state,
        filters: Object.assign({}, state.filters, filters)
      }
    case actionTypes.TASKS_SET_FILTER_HARD:
      return {
        ...state,
        filters: action.data.filters
      }
    case actionTypes.TASKS_CLEAR_FILTERS:
      return {
        ...state,
        filters: initialState.filters
      }
    case actionTypes.TASK_SET_ACTIVE:
      return {
        ...state,
        activeTaskId: action.data
      }
    case actionTypes.GET_TASK_USERS:
      return {
        ...state,
        taskUsers: {
          ...state.taskUsers,
          isFetching: true
        }
      }
    case actionTypes.GET_TASK_USERS_SUCCESS:
      return {
        ...state,
        taskUsers: {
          ...state.taskUsers,
          users: action.data.users,
          isFetching: false
        }
      }
    case actionTypes.GET_TASK_USERS_ERROR:
      return {
        ...state,
        taskUsers: {
          ...state.taskUsers,
          error: action.error,
          isFetching: false
        }
      }
    case actionTypes.CHANGE_TASK_USERS:
      return {
        ...state,
        taskUsers: {
          ...state.taskUsers,
          isFetching: true
        }
      }
    case actionTypes.CHANGE_TASK_USERS_SUCCESS:
      const updatedOrder = state.order.find(({ tasks }) => tasks && tasks.length && tasks[0].task_id === action.task_id)
      const updatedOrderIndex = state.order.indexOf(updatedOrder)
      updatedOrder.tasks[0].assignee = action.username
      updatedOrder.tasks[0].assigneeFullName = action.username
      const updatedOrders = state.order.map(item => item)
      updatedOrders[updatedOrderIndex] = updatedOrder

      return {
        ...state,
        order: updatedOrders,
        taskUsers: {
          ...state.taskUsers,
          isFetching: false
        }
      }
    case actionTypes.CHANGE_TASK_USERS_ERROR:
      return {
        ...state,
        taskUsers: {
          ...state.taskUsers,
          error: action.error,
          isFetching: false
        }
      }
    case actionTypes.TASKS_SET_FILTER_CLIENT_NAME:
      return {
        ...state,
        clientName: action.data.name
      }
    default:
      return state
  }
}
