import axios from 'axios'
import { ENDPOINTS } from '../constants/ENDPOINTS';
import { ICompanyData, IDataToSignItem } from '../declarations/companyData';

class TaskService {
    getTask(token: string) {
        return axios.get<ICompanyData>(`${ENDPOINTS.USE_COMPANY_DATA}${token}`)
    }

    submitTask(token: string, code: string, phone?: string, signedDocs?: IDataToSignItem[]) {
        return axios.post(`${ENDPOINTS.SUBMIT_TASK}`, {
            token,
            code,
            phone,
            signedDocs,
        });
    }
}

export const taskService = new TaskService();