import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import injectSheet from 'react-jss'
import { changeTasksUser } from '../../../redux/Tasks/actions'
import { Modal } from '../../../layout'
import Overlay from '../../Overlay'
import Dropdown from '../../Dropdown'

const styles = {
  dropdown: {
    background: '#fff',
    width: '100%',
    height: 40,
    marginTop: 20,
    padding: [0, 40, 0, 20],
    borderRadius: 12,
    border: [1, 'solid', '#9b9b9b'],
    fontSize: 16,
    fontWeight: 500,
    outline: 'none',
    textAlign: 'left',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
      position: 'absolute',
      top: '50%',
      right: 10,
      transform: 'translateY(-50%)',
    },
    '&:focus': {
      outline: 'none',
    },
    '& + .dropdown-menu': {
      maxHeight: 205,
      overflow: 'auto',
    },
  },
  action: {
    position: 'absolute',
    bottom: 30,
    right: 30,
    left: 30,
    textAlign: 'right',
  },
  cancel: {
    background: 'none',
    border: 'none',
    color: '#8f8e97',
    fontSize: 16,
    lineHeight: 2.5,
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    '&:hover': {
      color: '#75747e',
    },
  },
  submit: {
    background: '#8f8e97',
    marginLeft: 20,
    padding: [0, 20],
    border: 'none',
    borderRadius: 16,
    color: '#fff',
    fontSize: 16,
    lineHeight: 2.5,
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    '&:hover': {
      background: '#75747e',
    },
  },
}

const Content = ({
  classes,
  taskId,
  onClose,
}) => {
  const dispatch = useDispatch()

  
  const { users, isFetching } = useSelector(state => state?.Tasks?.taskUsers)

  const [username, setUsername] = useState('')

  const _users = users.map(item => {
    item.value = item.fullName
    item.key = item.id
    return item
  })

  const userObject = _users.find(item => item.id === username)
  const userIndex = _users.indexOf(userObject)

  useEffect(() => {
    const selectedUser = _users.find(item => item.selected)
    const selectedUserName = selectedUser ? selectedUser.id : ''
    setUsername(selectedUserName)
  }, [users])

  const onSelect = (name, key, index) => setUsername(key)

  const onChange = () => {
    const payload = {
      username,
      task_id: taskId,
    }
    dispatch(changeTasksUser(payload, onClose))
  }

  const showDropdown = userIndex > -1

  return isFetching
    ? <Overlay /> 
    : <>
        <Modal.H1>Поменять ответственного</Modal.H1>
        {showDropdown &&
          <Dropdown
            name='username'
            toggleClassName={classes.dropdown}
            defaultActive={userIndex}
            list={_users}
            onSelectItem={onSelect}
          />
        }
        <div className={classes.action}>
          <div className={classes.cancel} onClick={onClose}>Отменить</div>
          <div className={classes.submit} onClick={onChange}>Поменять</div>
        </div>
      </>
}

export default injectSheet(styles)(Content)
