import React from 'react'
import PropTypes from 'prop-types'
import StatsInfoItem from '../StatsInfoItem'

const StatsInfoBlock = ({ 
  globalColor, 
  infoBlock, 
  indicator, 
  statusItems, 
}) => 
  <div className='chart-stats__info'>
    {statusItems.map(({ key, text, className }, index) => {
      const subItemKey = indicator === 'count' ? 'amount' : 'count'
      const item = infoBlock.items[key]
      if (!item) {
        return null 
      }
      return (
        <StatsInfoItem
          key={index}
          progressColor={globalColor || className}
          mainCount={item[indicator] || 0}
          subCount={item[subItemKey] || 0}
          sum={infoBlock[`${indicator}Sum`]}
          badge={item[`${indicator}_change`]}
          text={text}
          mainKey={indicator}
        />
      )
    })}
  </div>

StatsInfoBlock.propTypes = {
  infoBlock: PropTypes.shape({
    items: PropTypes.object.isRequired,
    countSum: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
    amountSum: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
    noItems: PropTypes.bool.isRequired,
  }).isRequired,
  indicator: PropTypes.string.isRequired,
  globalColor: PropTypes.string,
}

StatsInfoBlock.defaultProps = {
  globalColor: '',
  statusItems: [],
}

export default StatsInfoBlock