import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import Icon from '../../../layout/Icons'
import { adaptive_1200 } from '../../../config'

const styles = {
  root: {
    backgroundColor: 'rgba(238, 238, 238, 0.5)',
    paddingTop: 17,
    paddingBottom: 14,
    borderRadius: 16,
    position: 'relative',
    '&.icon': {
      paddingLeft: 87,
      paddingRight: 10,
    },
  },
  icon: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    position: 'absolute',
    left: 17,
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'rgba(221, 221, 221, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [adaptive_1200]: {
      top: 16,
      transform: 'none',
    },
    '& svg': {
      width: 20,
      height: 20,
      display: 'block',
      fill: '#B8B8B8',
    },
  },
}

const ItemGrey = ({
  iconName,
  children, 
  classes, 
}) =>
  <div className={classnames(classes.root, { 'icon': iconName })}>
    {iconName &&
      <div className={classes.icon}>
        <Icon iconName={iconName} />
      </div>
    }
    {children}
  </div>

export default injectSheet(styles)(ItemGrey)