import axios from 'axios'
import { SERVER } from '../constants'

export const onCalculate = async (data) => {
  try {
    const { data: { error_code, ...rest } } = await axios({
      method: 'POST',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/widgets/bgtc/calculate`,
      data,
    })
    if (error_code === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: error_code === 5,
      error: rest?.error,
      errors: rest?.errors,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err?.message,
   }
  }
}