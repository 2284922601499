import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import keycloak from '../keycloak'
import { IS_NEW_AUTH, IS_SECURED_JWT } from '../config'
import { User } from '../services/api'

const TOKEN_KEY = 'JWT'

export const setToken = async (token) => IS_NEW_AUTH
  ? IS_SECURED_JWT
    ? await User.setJWT(token)
    : Cookies.set(TOKEN_KEY, token, 365)
  : localStorage.setItem(TOKEN_KEY, token)

export const getToken = () => Cookies.get(TOKEN_KEY)

export const getTokenParsed = () => {
  const JWT = getToken()
  const token = JWT && JWT !== 'undefined' ? jwtDecode(JWT) : false
  return token
}

export const isValidToken = () => {
  const token = getTokenParsed()
  const isExpired = token ? new Date().getTime() > token.exp * 1000 : false
  const isBadJWT = !token || isExpired
  return !isBadJWT
}

export const onLogin = () => keycloak.login()

export const onLogout = async () => {
  keycloak.logout()
  IS_NEW_AUTH
    ? IS_SECURED_JWT
      ? await User.deleteJWT()
      : Cookies.remove(TOKEN_KEY)
    : localStorage.removeItem(TOKEN_KEY)
}