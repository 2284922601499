import axios from 'axios'
import { SERVER } from '../constants'
import { createSearchString } from '../../utils'

export const getFactoringTasks = async ({ taskDefinitionKey, type }) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/gateway/task?taskDefinitionKey=${taskDefinitionKey}&type=${type}`,
      data: { 
        monitoringFilter: {}
      },
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const completeFactoringTask = async (id, type, payload) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/gateway/task/${type}/${id}`,
      data: payload,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringView = async (entityType, filters) => {
  const searchString = createSearchString(filters)
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/view/grid/${entityType?.toUpperCase()}?${searchString}`,
      data: {},
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringShortInfo = async (entityType, companyId) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/view/detail/${entityType?.toUpperCase()}/${companyId}/short`,
      data: {},
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const sendFactoringMessage = async (payload) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/view/comment`,
      data: payload,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringMessages = async (entityType, entityId) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/view/comment/${entityType.toUpperCase()}/${entityId}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const createFactoringEntity = async (payload, entityType) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/view/entity/${entityType.toUpperCase()}`,
      data: payload,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const startFactoringProcess = async (payload, processDefinitionKey) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/gateway/process/start/${processDefinitionKey}`,
      data: payload,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringRefDocuments = async () => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/gateway/ref/document`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringDebtors = async (factoringContractId) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/view/factoring/${factoringContractId}/debtors`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringDuplicateSettings = async ({ entityId, entityType }) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/view/duplicatesettings/${entityType.toUpperCase()}/${entityId}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringPriceMatrix = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/view/factoring/${id}/rates`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringAccountEntityTypes = async () => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/view/ref/account/entitytype`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringRefRateTypes = async () => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/view/ref/rate`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringRefBlockTypes = async () => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/view/ref/block`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringRefOperationTypes = async () => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/view/ref/status/operation`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringRefAccountTypes = async () => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/view/ref/account/type`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringRefOfferingKeys = async () => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/view/ref/offeringkey`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringPaymentOrders = async ({ orderType }) => {
  const orderTypeSubString = orderType.reduce((result, item) => `${result}&orderType=${item}`, '')
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/gateway/task/orders?dateFrom=2021-08-01${orderTypeSubString}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringMatchingsByTaskId = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/gateway/task/${id}/matchings`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringMatchingsByPaymentId = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/gateway/task/matching/${id}`,
    })
    // if (error_code === 0) {
      return {
        isSuccess: true,
        data,
      }
    // }
    // return {
    //   isSuccess: false,
    //   needLogout: error_code === 5,
    //   message: rest.error,
    // }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringSuppliers = async (query = '') => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/view/grid/SUPPLIER?q=${query}`,
    })
    return {
      isSuccess: true,
      data: data?.data || [],
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getFactoringSupplyRegistrySupplyTab = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/view/detail/supplyregistry/${id}/supply`,
    })
    return {
      isSuccess: true,
      data: data || [],
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}