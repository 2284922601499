import React from 'react'
import injectSheet from 'react-jss'
import Icon from './../../../layout/Icons'

const styles = {
  root: {
    display: 'flex',
    minHeight: 'calc(100% - 116px)',
    position: 'relative'
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    '& svg': {
      fill: '#E3E3E3',
      width: 46,
      height: 45,
      display: 'block'
    }
  },
  title: {
    marginTop: 36,
    display: 'inline-block',
    fontSize: 24,
    fontWeight: 600,
    color: '#000'
  },
  text: {
    marginTop: 24,
    textAlign: 'center',
    fontSize: 18,
    lineHeight: 24/18,
    fontWeight: 500,
    color: '#4a4a4a'
  }
}

const EmptyTasksList = ({ classes, message }) =>
  <div className={classes.root}>
    <div className={classes.block}>
      <Icon iconName='face' />
      <div className={classes.title}>
        {message
          ? 'Ничего не найдено'
          : 'Список заявок пуст'
        }
      </div>
      <div className={classes.text}>
        {message || 'Для создания Вашей первой заявки'}
        {!message &&
          <>
            <br />
            используйте знак + в правом нижнем углу.
          </>
        }
      </div>
    </div>
  </div>

export default injectSheet(styles)(EmptyTasksList)
