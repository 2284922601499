import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import { Modal } from '../../index'
import { formattedNumberToGekto } from './../../../utils'

const styles = {
	root: {
    marginTop: 20,
    marginRight: 80,
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    '& p': {
      opacity: 0.5,
    },
  },
}

const Parameters = ({ 
  classes, 
  className, 
  parameters, 
}) => {
  const parametersIsset = parameters && parameters.length

  if (!parametersIsset) {
    return null
  }

  return (
    <Fragment>
      {parameters.map(({ name, attributes }, index) =>
        <Modal.Block iconName='request' title={name} key={index}>
          {attributes.map(({ value, name, type }, index) =>
            <div className={classes.root} key={index}>
              <Modal.Value>
                {type === 'money' 
                  ? formattedNumberToGekto(value)
                  : value
                }
                <p>{name}</p>
              </Modal.Value>
            </div>
          )} 
        </Modal.Block>
      )}
    </Fragment>
  )
}

export default injectSheet(styles)(Parameters)