import axios from 'axios'

import { SERVER } from '../constants'

export const getWidget = async () => {
  try {
    const { data: { error_code: status, ...rest } } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.SPA_ENDPOINT}/stat/summary`,
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 5,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getPeriods = async () => {
  try {
    const { data: { error_code: status, ...rest } } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.SPA_ENDPOINT}/stat/periods`,
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 5,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getEmployeeStats = async (period, username = '') => {
  try {
    const user = username ? `&username=${username}`: ''
    const { data: { error_code: status, ...rest } } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.SPA_ENDPOINT}/stat/summary?period=${period}${user}`,
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 5,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}

export const getCompanyStats = async (period) => {
  try {
    const { data: { error_code: status, ...rest } } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.SPA_ENDPOINT}/stat/company?period=${period}`,
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest,
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 5,
      message: rest.error,
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message,
    }
  }
}
