import React from 'react'
import injectSheet from 'react-jss'
import { adaptive_1200 } from '../../../config'

const styles = {
  root: {
    display: 'inline-block',
    verticalAlign: 'top',
    [adaptive_1200]: {
      display: 'block',
    },
    '& + &': {
      marginLeft: 50,
      [adaptive_1200]: {
        marginTop: 16,
        marginLeft: 0,
      },
    },
  },
}

const Block = ({
  children,
  classes,
}) =>
  <div className={classes.root}>
    {children}
  </div>

export default injectSheet(styles)(Block)