import { useState } from 'react'
import { Certificate } from 'crypto-pro'
import { fetchUserCertificates } from '../api'
import { ERRORS } from '../constants'

export const useCertificates = () => {
  const [certificates, setCertificates] = useState<Certificate[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const loadCertificates = async () => {
    setIsLoading(true)
    setError(null)
    try {
      const certs = await fetchUserCertificates()
      setCertificates(certs)
    } catch (err) {
      setError(ERRORS.FETCH_CERTIFICATE_ERROR)
    } finally {
      setIsLoading(false)
    }
  }

  return { certificates, isLoading, error, loadCertificates }
}
