import * as actionTypes from './actionTypes'

const initialState = {
  isFetching: false,
  error: false,
  data: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REGISTER_AGENT:
      return {
        ...state,
        isFetching: true,
        error: false,
      }
    case actionTypes.REGISTER_AGENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
      }
    case actionTypes.REGISTER_AGENT_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case actionTypes.REGISTER_TOKEN:
      return {
        ...state,
        isFetching: true,
        error: false,
      }
    case actionTypes.REGISTER_TOKEN_SUCCESS:
      return {
        ...state,
        isFetching: false,
      }
    case actionTypes.REGISTER_TOKEN_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    default:
      return state
  }
}