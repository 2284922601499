import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Fieldset, InputText, RowGrid, Col12 } from '../../../layout'
import { formattedNumber } from '../../../utils'
import { Button, ButtonWrapper, Form, H3 } from '../Layout'

const useStyles = createUseStyles({
  items: {
    marginBottom: 30,
  },
  item: {
    fontSize: 14,
    '& + &': {
      marginTop: 8,
    },
    '& > span': {
      width: 100,
      fontWeight: 600,
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
})

const TYPES = {
  'Непогашенные поставки': 'поставке',
  'Комиссии': 'комиссии',
}

export const MatchingPaymentModal = ({
  tab,
  order,
  paymentId,
  onAddDraft,
  onClose,
}) => {
  const classes = useStyles()

  const [matchedAmount, setMatchedAmount] = useState('')
  const [submitted, setSubmitted] = useState(0)

  const payload = {
    paymentId,
    matchedAmount,
    order,
  }

  const errors = {
    ...(matchedAmount?.trim() ? {} : { matchedAmount: 'Не указана зачисляемая сумма' }),
  }

  const isValid = Object.keys(errors)?.length === 0

  const onChangeAccountNumber = (e) => setMatchedAmount(e.target.value)

  const onSubmit = () => setSubmitted(submitted + 1)
  useEffect(() => {
    if (submitted > 0 && isValid) {
      onAddDraft(payload)
      onClose()
    }
  // eslint-disable-next-line
  }, [submitted])

  const INFO = {
    'Непогашенные поставки': ['Поставка:', order?.number],
    'Комиссии': ['Комиссия:', order?.orderType],
  }

  return (
    <>
      <H3>Квитовка по {TYPES[tab]}</H3>
      <Form>
        <div className={classes.items}>
          <div className={classes.item}><span>{INFO[tab][0]}</span> {INFO[tab][1]}</div>
          <div className={classes.item}><span>Сумма:</span> {formattedNumber(order?.amount) || '0'}</div>
          <div className={classes.item}><span>Валюта:</span> RUB</div>
        </div>
        <Fieldset>
          <RowGrid>
            <Col12>
              <InputText
                type='text' 
                name='matchedAmount'
                label='Зачисляемая сумма'
                value={matchedAmount}
                onChange={onChangeAccountNumber}
                error={submitted > 0 && errors?.matchedAmount}
              />
            </Col12>
          </RowGrid>
        </Fieldset>
      </Form>
      <ButtonWrapper>
        <Button
          text='Связать'
          disabled={submitted > 0 && !isValid}
          onClick={onSubmit}
        >
          Связать
        </Button>
      </ButtonWrapper>
    </>
  )
}