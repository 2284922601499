export const CLIENTS_FETCH = 'CLIENTS_FETCH';
export const CLIENTS_SUCCESS = 'CLIENTS_SUCCESS';
export const CLIENTS_ERROR = 'CLIENTS_ERROR';

export const NEXT_CLIENTS_FETCH = 'NEXT_CLIENTS_FETCH';
export const NEXT_CLIENTS_SUCCESS = 'NEXT_CLIENTS_SUCCESS';
export const NEXT_CLIENTS_ERROR = 'NEXT_CLIENTS_ERROR';

export const CLIENTS_SET_FILTER = 'CLIENTS_SET_FILTER';
export const CLIENTS_CLEAR_FILTERS = 'CLIENTS_CLEAR_FILTERS';
