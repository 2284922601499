import React from 'react'
// import { Link } from 'react-router-dom'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import Icon from './../../../layout/Icons'
import { THEME_COLOR, adaptive_1200 } from './../../../config'

const styles = {
  root: {
    // background: '#FF9A8D',
    background: THEME_COLOR,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  logo: {
    backgroundPosition: 'top left',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    left: 70,
    top: 70,
    [adaptive_1200]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative',
      left: 'auto',
      top: 'auto',
      marginTop: 30,
    },
  },
  error: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  message: {
    width: 700,
    marginTop: 60,
    textAlign: 'center',
    color: '#fff',
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 1.6,
    '& a': {
      color: '#fff',
      textDecoration: 'underline',
    },
    [adaptive_1200]: {
      width: 280,
      marginTop: 30,
      fontSize: 16,
      lineHeight: 1.5,
    },
  },
  icon: {
    width: 150,
    height: 150,
    [adaptive_1200]: {
      width: 100,
      height: 100,
    },
  },
}

const Error = ({ classes }) => 
  <div className={classes.root}>
    <div className={classnames(classes.logo, 'verify-logo')} />
    <div className={classes.error}>
      <Icon iconName='sad-face' className={classes.icon} />
      <div className={classes.message}>
          Страница не найдена. 
          <br/>
          <a href='/'>Перейти на главную.</a>
        </div>
    </div>
  </div>

export default injectSheet(styles)(Error)