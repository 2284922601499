import * as actionTypes from './actionTypes'

const initialState = {
  tasks: [],
  views: {},
  shortInfo: {},
  messages: [],
  documents: [],
  rateTypes: [],
  blockTypes: [],
  operationTypes: [],
  accountTypes: [],
  offeringKeys: [],
  duplicateSettings: [],
  priceMatrix: [],
  accountEntityTypes: [],
  supplyContractsByFactoringContractId: [],
  debtorsByFactoringContractId: [],
  paymentOrders: [],
  paymentComissions: [],
  paymentMatchings: [],
  paymentReplications: [],
  suppliers: [],
  supplyRegistrySupplyTab: [],
  isFetching: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FACTORING_TASKS:
      return { 
        ...state, 
        isFetching: true, 
      }
    case actionTypes.GET_FACTORING_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_TASKS_ERROR:
      return { 
        ...state,
        tasks: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_VIEW:
      return { 
        ...state, 
        isFetching: true, 
      }
    case actionTypes.GET_FACTORING_VIEW_SUCCESS:
      const { data, count, entityType, filters: { offset, limit } } = action.data
      return {
        ...state,
        views: {
          items: data,
          entityType,
          totalCount: count,
          totalPages: Math.ceil(count / limit),
          size: limit,
          page: Number(offset) ? (offset / limit + 1) : 1,
        },
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_VIEW_ERROR:
      return { 
        ...state,
        views: {},
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_SHORT_INFO:
      return { 
        ...state, 
        isFetching: true, 
      }
    case actionTypes.GET_FACTORING_SHORT_INFO_SUCCESS:
      return {
        ...state,
        shortInfo: {
          ...action.data,
          showFieldName: action.showFieldName,
        },
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_SHORT_INFO_ERROR:
      return { 
        ...state, 
        shortInfo: {},
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_MESSAGES:
      return { 
        ...state, 
        isFetching: true, 
      }
    case actionTypes.GET_FACTORING_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_MESSAGES_ERROR:
      return { 
        ...state,
        messages: [],
        isFetching: false, 
      }
    case actionTypes.SEND_FACTORING_MESSAGE:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.SEND_FACTORING_MESSAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      }
    case actionTypes.SEND_FACTORING_MESSAGE_ERROR:
      return { 
        ...state, 
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_REF_DOCUMENTS:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_REF_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_REF_DOCUMENTS_ERROR:
      return { 
        ...state,
        documents: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_DUPLICATE_SETTINGS:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_DUPLICATE_SETTINGS_SUCCESS:
      return {
        ...state,
        duplicateSettings: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_DUPLICATE_SETTINGS_ERROR:
      return { 
        ...state,
        duplicateSettings: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_PRICE_MATRIX:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_PRICE_MATRIX_SUCCESS:
      return {
        ...state,
        priceMatrix: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_PRICE_MATRIX_ERROR:
      return { 
        ...state,
        priceMatrix: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_ACCOUNT_ENTITY_TYPES:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_ACCOUNT_ENTITY_TYPES_SUCCESS:
      return {
        ...state,
        accountEntityTypes: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_ACCOUNT_ENTITY_TYPES_ERROR:
      return { 
        ...state,
        priceMatrix: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_REF_RATE_TYPES:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_REF_RATE_TYPES_SUCCESS:
      return {
        ...state,
        rateTypes: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_REF_RATE_TYPES_ERROR:
      return { 
        ...state,
        rateTypes: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_REF_BLOCK_TYPES:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_REF_BLOCK_TYPES_SUCCESS:
      return {
        ...state,
        blockTypes: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_REF_BLOCK_TYPES_ERROR:
      return { 
        ...state,
        blockTypes: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_REF_OPERATION_TYPES:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_REF_OPERATION_TYPES_SUCCESS:
      return {
        ...state,
        operationTypes: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_REF_OPERATION_TYPES_ERROR:
      return { 
        ...state,
        operationTypes: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_REF_ACCOUNT_TYPES:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_REF_ACCOUNT_TYPES_SUCCESS:
      return {
        ...state,
        accountTypes: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_REF_ACCOUNT_TYPES_ERROR:
      return { 
        ...state,
        accountTypes: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_REF_OFFERING_KEYS:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_REF_OFFERING_KEYS_SUCCESS:
      return {
        ...state,
        offeringKeys: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_REF_OFFERING_KEYS_ERROR:
      return { 
        ...state,
        offeringKeys: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_PAYMENT_ORDERS:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_PAYMENT_ORDERS_SUCCESS:
      return {
        ...state,
        paymentOrders: action.isPaymentOrders ? action.data : state.paymentOrders,
        paymentComissions: action.isPaymentOrders ? state.paymentComissions : action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_PAYMENT_ORDERS_ERROR:
      return { 
        ...state,
        paymentOrders: action.isPaymentOrders ? [] : state.paymentOrders,
        paymentComissions: action.isPaymentOrders ? state.paymentComissions : [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_MATCHINGS_BY_TASK_ID:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_MATCHINGS_BY_TASK_ID_SUCCESS:
      return {
        ...state,
        paymentMatchings: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_MATCHINGS_BY_TASK_ID_ERROR:
      return { 
        ...state,
        paymentMatchings: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_MATCHINGS_BY_PAYMENT_ID:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_MATCHINGS_BY_PAYMENT_ID_SUCCESS:
      return {
        ...state,
        paymentReplications: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_MATCHINGS_BY_PAYMENT_ID_ERROR:
      return { 
        ...state,
        paymentReplications: [],
        isFetching: false, 
      }
    case actionTypes.COMPLETE_FACTORING_TASK:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.COMPLETE_FACTORING_TASK_SUCCESS:
      return {
        ...state,
        isFetching: false,
      }
    case actionTypes.COMPLETE_FACTORING_TASK_ERROR:
      return { 
        ...state,
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_SUPPLIERS:
      return { 
        ...state,
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_SUPPLIERS_SUCCESS:
      return {
        ...state,
        suppliers: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_SUPPLIERS_ERROR:
      return { 
        ...state,
        suppliers: [],
        isFetching: false, 
      }
    case actionTypes.GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB:
      return { 
        ...state, 
        isFetching: true,
      }
    case actionTypes.GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB_SUCCESS:
      return {
        ...state,
        supplyRegistrySupplyTab: action.data,
        isFetching: false,
      }
    case actionTypes.GET_FACTORING_SUPPLY_REGISTRY_SUPPLY_TAB_ERROR:
      return { 
        ...state,
        supplyRegistrySupplyTab: {},
        isFetching: false, 
      }
    default:
      return state
  }
}