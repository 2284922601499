import { useState } from 'react'
import useSwr from 'swr'

import { ICompanyData, IDataToSignItem } from '../declarations'
import { ERRORS } from '../constants'
import { taskService } from '../services/TaskService'

export const useTask = (token: string) => {
  const { data, error, ...rest } = useSwr<{ task: ICompanyData; isSuccess: boolean }>(
    ['task-data', token],
    () => taskService.getTask(token).then(data => ({ task: data.data, isSuccess: false })),
    {
      revalidateOnFocus: false,
      errorRetryCount: 3
    }
  )
  return {
    data,
    error: error ? ERRORS.FETCH_DATA_ERRORS : null,
    ...rest
  }
}

export const useSubmitTask = (token: string) => {
  const [isFetching, setIsFetching] = useState(false)
  const { data, mutate } = useTask(token)

  const submitTask = async (code: string, phone?: string, signedDocs?: IDataToSignItem[]) => {
    try {
      setIsFetching(true)
      await taskService.submitTask(token, code, phone, signedDocs)
      mutate({
        task: data!.task,
        isSuccess: true
      })
      setIsFetching(false)
    } catch (error) {
      throw new Error('Ошибки при отправки документов на сервер: ' + error)
    }
  }

  return { isFetching, submitTask }
}
