import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Collapse } from 'reactstrap'
import injectSheet from 'react-jss'
import { Modal } from './../../index'
import { formattedNumber } from './../../../utils'

const styles = {
	root: {
    position: 'relative',
    '& + &': {
      marginTop: 20,
    },
	},
	title: {
    paddingLeft: 62,
    display: 'inline-block',
    cursor: 'pointer',
	},
	icon: {
		width: 50,
		height: 50,
		top: -9,
		cursor: 'pointer',
  },
  name: {
    marginTop: 20,
    paddingRight: 230,
    position: 'relative',
  },
  list: {
    marginTop: 20,
    position: 'relative',
  },
  item: {
    width: '25%',
    display: 'inline-block',
    verticalAlign: 'top',
  },
  right: {
    position: 'absolute',
    top: 0,
    right: 0,
    textAlign: 'right',
  },
  content: {
    width: 'calc(100% + 60px)',
    marginTop: 30,
    marginLeft: -30,
    paddingLeft: 30,
    paddingRight: 30,
  },
  document: {
    paddingRight: 0,
  },
}

const Lot = ({
  lotNumber,
  beneficiaries,
  startAmount,
  finalAmount,
  procAmount,
  bgAmount,
  isRequiredSecurityForGuaranteePeriod,
  isRequiredIndisputableDebiting,
  isIncludedForfeit,
  prepaymentExists,
  documents,
  classes,
  eis,
}) => {
  const [open, setOpen] = useState(true)

  const { showEisNumber } = useSelector(state => state.Settings)

  const flagToText = (boolean) => boolean ? 'Да' : 'Нет'

  return (
    <div className={classes.root}>
      <Modal.H2 
        className={classes.title} 
        icon={{ 
          name: 'arrow-bottom', 
          className: classes.icon, 
        }}
        open={open}
        onClick={() => setOpen(!open)}
      >
        Лот №{lotNumber}
      </Modal.H2>
      <Collapse isOpen={open}>
        {beneficiaries.map(({ displayName, INN, OGRN, bgAmount }) =>
          <div className={classes.name} key={displayName}>
            <div>
              <Modal.Value>{displayName}, ИНН {INN}, ОГРН {OGRN}</Modal.Value>
              <Modal.Title>Наименование бенефициара</Modal.Title>
            </div>
            <div className={classes.right}>
              <Modal.Value>{`${formattedNumber(bgAmount)} ₽`}</Modal.Value>
              <Modal.Title>Гарантия</Modal.Title>
            </div>
          </div>
        )}
        <div className={classes.list}>
          <div className={classes.item}>
            <Modal.Value>{`${formattedNumber(startAmount)} ₽`}</Modal.Value>
            <Modal.Title>НМЦК</Modal.Title>
          </div>
          <div className={classes.item}>
            <Modal.Value>{`${formattedNumber(finalAmount)} ₽`}</Modal.Value>
            <Modal.Title>Предложенная цена</Modal.Title>
          </div>
          <div className={classes.item}>
            <Modal.Value>{procAmount} %</Modal.Value>
            <Modal.Title>% снижения</Modal.Title>
          </div>
          <div className={classes.item}>
            <Modal.Value>{`${formattedNumber(bgAmount)} ₽`}</Modal.Value>
            <Modal.Title>Сумма гарантии</Modal.Title>
          </div>
        </div>
        <div className={classes.list}>
          <div className={classes.item}>
            <Modal.Value>{flagToText(isRequiredSecurityForGuaranteePeriod)}</Modal.Value>
            <Modal.Title>Гарантийный период</Modal.Title>
          </div>
          <div className={classes.item}>
            <Modal.Value>{flagToText(isRequiredIndisputableDebiting)}</Modal.Value>
            <Modal.Title>Бесспорное списание</Modal.Title>
          </div>
          <div className={classes.item}>
            <Modal.Value>{flagToText(isIncludedForfeit)}</Modal.Value>
            <Modal.Title>Неустойки</Modal.Title>
          </div>
          <div className={classes.item}>
            <Modal.Value>{flagToText(prepaymentExists)}</Modal.Value>
            <Modal.Title>Аванс</Modal.Title>
          </div>
        </div>
        {showEisNumber && eis &&
          <div className={classes.list}>
            <div className={classes.item}>
              <Modal.Value>{eis}</Modal.Value>
              <Modal.Title>Номер закупки в ЕИС</Modal.Title>
            </div>
          </div>
        }
        <div className={classes.content}>
          <Modal.Documents
            title='Документы' 
            documents={documents.filter(item => item.lot === lotNumber)} 
            className={classes.document} 
          />
        </div>
      </Collapse>
    </div>
  )
}

export default injectSheet(styles)(Lot)