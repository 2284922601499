import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  h4: {
    fontSize: 15,
    fontWeight: 600,
  },
})

export const H4 = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.h4}>{children}</div>
  )
}