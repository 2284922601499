import React from 'react'
import {
  change,
  // touch,
} from 'redux-form'
import { InputPhone } from '../InputPhone/InputPhone'
import { Error } from '../../layout'

export const FormInputPhone = ({
  input: {
    name,
    value,
    onBlur,
    ...inputProps
  },
  meta: {
    form,
    dispatch,
    touched,
    error,
  },
  ...rest
}) => {
  // const changeFieldValue = (value) => {
  //   dispatch(change(form, name, value))
  //   dispatch(touch(form, name))
  // }

  const onBlurWithSetValue = (event, value) => {
    onBlur(event)
    dispatch(change(form, name, value))
  }

  return (
    <div>
      <InputPhone
        name={name}
        value={value}
        {...rest}
        {...inputProps}
        // onChange={changeFieldValue}
        onBlurWitSetValue={onBlurWithSetValue}
      />
      {touched && error &&
        <Error>{error}</Error>
      }
    </div>
  )
}