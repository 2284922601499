import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { createFactoringRate } from '../../../../redux/Factoring/actions'
import { InputText, Select2, Fieldset, RowGrid, Col6, Col12 } from '../../../../layout'
import TextFieldWithAutoComplete from '../../../../components/TextFieldWithAutoComplete'
import { Search } from '../../../../services/api'
import { Button, ButtonWrapper, Form, H3 } from '../../Layout'

const entityTypesOptions = [
  { name: '', value: '' },
  { name: 'Общебанковский', value: 'COMMON' },
  { name: 'Компания', value: 'CLIENT' },
  { name: 'Договор факторинга', value: 'AGREEMENT_PB' },
]

export const AddRateForm = ({
  parsedFilters,
  onGetFactoringView,
  onClose,
}) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { rateTypes } = useSelector(state => state?.Factoring)

  const typesOptions = [
    { name: '', value: '' },
    ...rateTypes,
  ]

  const [entityType, setEntityType] = useState(entityTypesOptions[0].value)
  const [companyName, setCompanyName] = useState('')
  const [entityId, setEntityId] = useState('')
  const [type, setType] = useState('')
  const [beginDate, setBeginDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [beginPeriod, setBeginPeriod] = useState('')
  const [endPeriod, setEndPeriod] = useState('')
  const [value, setValue] = useState('')
  const [submitted, setSubmitted] = useState(0)

  const isClient = entityType === 'CLIENT'
  const isAgreementPB = entityType === 'AGREEMENT_PB'

  const payload = {
    entityType,
    entityId,
    type,
    beginDate,
    endDate,
    beginPeriod: Number(beginPeriod),
    endPeriod: Number(endPeriod),
    value: Number(value),
  }

  const errors = {
    ...(entityType?.trim() ? {} : { entityType: 'Не выбран тип продукта' }),
    ...(isClient && !entityId?.trim() ? { entityId: 'Не выбран владелец продукта' } : {}),
    ...(isAgreementPB && !entityId?.trim() ? { entityId: 'Не выбран номер договора факторинга' } : {}),
    ...(type?.trim() ? {} : { type: 'Не выбран тип ставки' }),
    ...(beginDate?.trim() ? {} : { beginDate: 'Не указана дата окончания' }),
    ...(endDate?.trim() ? {} : { endDate: 'Не указана дата начала' }),
    ...(beginPeriod?.trim() ? {} : { beginPeriod: 'Не указано начало периода' }), 
    ...(endPeriod?.trim() ? {} : { endPeriod: 'Не указан конец периода' }), 
    ...(value?.trim() ? {} : { value: 'Не указано значение' }),    
  }

  const isValid = Object.keys(errors)?.length === 0

  const onChangeEntityType = (e) => {
    setEntityType(e.target.value)
    onClearEntityId()
  }
  const onChangeType = (e) => setType(e.target.value)
  const onChangeBeginPeriod = (e) => setBeginPeriod(e.target.value)
  const onChangeEndPeriod = (e) => setEndPeriod(e.target.value)
  const onChangeValue = (e) => setValue(e.target.value)

  const onChangeBeginDate = (value) => setBeginDate(value)
  const onChangeEndDate = (value) => setEndDate(value)

  const onChangeEntityId = (payload) => {
    setEntityId(payload.id)
    setCompanyName(payload.displayName)
  }
  const onClearEntityId = () => {
    setEntityId('')
    setCompanyName('')
  }

  const onSubmit = () => setSubmitted(submitted + 1)

  const onCreateFactoringRate = () => {
    const onSuccess = () => {
      onGetFactoringView(parsedFilters)
      onClose()
    }
    if (isValid) {
      dispatch(createFactoringRate(payload, params.entityType, onSuccess))
    }
  }

  useEffect(() => {
    if (submitted > 0) {
      onCreateFactoringRate()
    }
  // eslint-disable-next-line
  }, [submitted])

  console.log('payload [CURRENT]:', payload)
  console.log('errors', errors)

  return (
    <>
      <H3>Добавить ставку:</H3>
      <Form>
        <Fieldset>
          <RowGrid>
            <Col12>
              <Select2
                label='Тип продукта'
                name='entityType'
                options={entityTypesOptions}
                value={entityType}
                onChange={onChangeEntityType}
                error={submitted > 0 && errors.entityType}
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        {isClient &&
          <Fieldset>
            <RowGrid>
              <Col12>
                <TextFieldWithAutoComplete
                  classNames={{ container: 'filter-input', input: 'filter-input__input' }}
                  name='entityId'
                  placeholder='Владелец продукта'
                  label='Владелец продукта'
                  value={companyName}
                  defaultValue={companyName}
                  onSelect={onChangeEntityId}
                  onClear={onClearEntityId}
                  payloadKeys={['id', 'displayName']}
                  meta={{
                    touched: true,
                    error: submitted > 0 ? errors.entityId : '',
                  }}
                  withoutLink
                />
              </Col12>
            </RowGrid>
          </Fieldset>
        }
        {isAgreementPB &&
          <Fieldset>
            <RowGrid>
              <Col12>
                <TextFieldWithAutoComplete
                  classNames={{ container: 'filter-input', input: 'filter-input__input' }}
                  name='entityId'
                  placeholder='Номер договора факторинга'
                  label='Номер договора факторинга'
                  value={companyName}
                  defaultValue={companyName}
                  onSelect={onChangeEntityId}
                  onClear={onClearEntityId}
                  payloadKeys={['id', 'number']}
                  request={Search.getFactoringContractSuggestions}
                  requestKey='id'
                  requestSubKeys={['displayName', 'supplierInn', 'startDate']}
                  meta={{
                    touched: true,
                    error: submitted > 0 ? errors.entityId : '',
                  }}
                  withoutLink
                />
              </Col12>
            </RowGrid>
          </Fieldset>
        }
        <Fieldset>
          <RowGrid>
            <Col12>
              <Select2
                label='Тип ставки'
                name='type'
                options={typesOptions}
                value={type}
                onChange={onChangeType}
                error={submitted > 0 && errors.type}
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col6>
              <InputText
                type='date' 
                name='beginDate'
                label='Дата начала'
                value={beginDate}
                onChange={onChangeBeginDate}
                error={submitted > 0 && errors?.beginDate}
              />
            </Col6>
            <Col6>
              <InputText
                type='date' 
                name='endDate'
                label='Дата окончания'
                value={endDate}
                onChange={onChangeEndDate}
                error={submitted > 0 && errors?.endDate}
              />
            </Col6>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col6>
              <InputText
                type='text' 
                name='beginPeriod'
                label='Начало периода'
                value={beginPeriod}
                onChange={onChangeBeginPeriod}
                error={submitted > 0 && errors?.beginPeriod}
              />
            </Col6>
            <Col6>
              <InputText
                type='text' 
                name='endPeriod'
                label='Конец периода'
                value={endPeriod}
                onChange={onChangeEndPeriod}
                error={submitted > 0 && errors?.endPeriod}
              />
            </Col6>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <InputText
                type='text' 
                name='value'
                label='Значение'
                value={value}
                onChange={onChangeValue}
                error={submitted > 0 && errors?.value}
              />
            </Col12>
          </RowGrid>
        </Fieldset>
      </Form>
      <ButtonWrapper>
        <Button
          text='Создать'
          disabled={submitted > 0 && !isValid}
          onClick={onSubmit}
        />
      </ButtonWrapper>
    </>
  )
}