import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { adaptive_1200 } from '../../../config'

const styles = {
  root: {
    paddingLeft: 40,
    paddingRight: 40,
    [adaptive_1200]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
}

const Content = ({ 
  children, 
  className, 
  classes, 
}) =>
  <div className={classnames(classes.root, className)}>
    {children}
  </div>

export default injectSheet(styles)(Content)
