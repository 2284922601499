import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { createFactoringAccount } from '../../../../redux/Factoring/actions'
import { Fieldset, Select2, InputText, RowGrid, Col12 } from '../../../../layout'
import TextFieldWithAutoComplete from '../../../../components/TextFieldWithAutoComplete'
import { Search } from '../../../../services/api'
import { Button, ButtonWrapper, Form, H3 } from '../../Layout'

export const AddAccountForm = ({
  parsedFilters,
  onGetFactoringView,
  onClose,
}) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { accountTypes, accountEntityTypes } = useSelector(state => state?.Factoring)

  const [accountNumber, setAccountNumber] = useState('')
  const [accountType, setAccountType] = useState('')
  const [entityType, setEntityType] = useState('')
  const [supplierId, setSupplierId] = useState('')
  const [supplierCompanyName, setSupplierCompanyName] = useState('')
  const [factoringContractId, setFactoringContractId] = useState('')
  const [factoringContractNumber, setFactoringContractNumber] = useState('')
  const [supplyId, setSupplyId] = useState('')
  const [supplyNumber, setSupplyNumber] = useState('')
  const [submitted, setSubmitted] = useState(0)

  const accountTypeOptions = [{ name: '', value: '' }, ...accountTypes]
  const accountEntityTypeOptions = [{ name: '', value: '' }, ...accountEntityTypes]

  const isSupplier = entityType === 'D'
  const isFactroringContractId = entityType === 'B'
  const isSupplyId = entityType === 'F'

  const entityIdMapper = {
    D: supplierId,
    B: factoringContractId,
    F: supplyId,
  }

  const payload = {
    accountNumber,
    accountType,
    entityType,
    entityId: entityIdMapper[entityType] || '',
  }

  const errors = {
    ...(accountNumber?.trim() ? {} : { accountNumber: 'Не указан номер счёта' }),
    ...(accountType?.trim() ? {} : { accountType: 'Не указан тип счёта' }),
    ...(entityType?.trim() ? {} : { entityType: 'Не указан тип сущности' }),
    ...(isSupplier && !supplierId?.trim() ? { supplierId: 'Не указан поставщик' } : {}),
    ...(isFactroringContractId && !factoringContractId?.trim() ? { factoringContractId: 'Не указан договор факторинга' } : {}),
    ...(isSupplyId && !supplyId?.trim() ? { supplyId: 'Не указана поставка' } : {}),
  }

  const isValid = Object.keys(errors)?.length === 0

  const onChangeAccountNumber = (e) => setAccountNumber(e.target.value)

  const onChangeAccountType = (e) => setAccountType(e.target.value)
  const onChangeEntityType = (e) => setEntityType(e.target.value)

  const onChangeSupplierId = (payload) => {
    setSupplierId(payload.id)
    setSupplierCompanyName(payload.displayName)
  }
  const onClearSupplierId = () => {
    setSupplierId('')
    setSupplierCompanyName('')
  }

  const onChangeSupplyId = (payload) => {
    setSupplyId(payload.id)
    setSupplyNumber(payload.number)
  }
  const onClearSupplyId = () => {
    setSupplyId('')
    setSupplyNumber('')
  }

  const onChangeFactoringContractId = (payload) => {
    setFactoringContractId(payload.id)
    setFactoringContractNumber(payload.number)
  }
  const onClearFactoringContractId = () => {
    setFactoringContractId('')
    setFactoringContractNumber('')
  }

  const onSubmit = () => setSubmitted(submitted + 1)

  const onCreateFactoringAccount = () => {
    const onSuccess = () => {
      onGetFactoringView(parsedFilters)
      onClose()
    }
    if (isValid) {
      dispatch(createFactoringAccount(payload, params?.entityType, onSuccess))
    }
  }

  useEffect(() => {
    if (submitted > 0) {
      onCreateFactoringAccount()
    }
  // eslint-disable-next-line
  }, [submitted])

  console.log('payload [CURRENT]:', payload)
  console.log('errors', errors)

  return (
    <>
      <H3>Добавить счёт:</H3>
      <Form>
        {isSupplier &&
          <Fieldset>
            <RowGrid>
              <Col12>
                <TextFieldWithAutoComplete
                  classNames={{ container: 'filter-input', input: 'filter-input__input' }}
                  name='supplierId'
                  placeholder='Поставщик'
                  label='Поставщик'
                  value={supplierCompanyName}
                  defaultValue={supplierCompanyName}
                  onSelect={onChangeSupplierId}
                  onClear={onClearSupplierId}
                  payloadKeys={['id', 'displayName']}
                  meta={{
                    touched: true,
                    error: submitted > 0 ? errors.supplierId : '',
                  }}
                  withoutLink
                />
              </Col12>
            </RowGrid>
          </Fieldset>
        }

        {isFactroringContractId &&
          <Fieldset>
            <RowGrid>
              <Col12>
                <TextFieldWithAutoComplete
                  classNames={{ container: 'filter-input', input: 'filter-input__input' }}
                  name='factoringContractId'
                  placeholder='Договор факторинга'
                  label='Договор факторинга'
                  value={factoringContractNumber}
                  defaultValue={factoringContractNumber}
                  onSelect={onChangeFactoringContractId}
                  onClear={onClearFactoringContractId}
                  payloadKeys={['id', 'number']}
                  request={Search.getFactoringContractSuggestions}
                  requestKey='number'
                  requestSubKeys={['displayName', 'supplierInn', 'startDate']}
                  meta={{
                    touched: true,
                    error: submitted > 0 ? errors.factoringContractId : '',
                  }}
                  withoutLink
                />
              </Col12>
            </RowGrid>
          </Fieldset>
        }

        {isSupplyId &&
          <Fieldset>
            <RowGrid>
              <Col12>
                <TextFieldWithAutoComplete
                  classNames={{ container: 'filter-input', input: 'filter-input__input' }}
                  name='supplyId'
                  placeholder='Поставка'
                  label='Поставка'
                  value={supplyNumber}
                  defaultValue={supplyNumber}
                  onSelect={onChangeSupplyId}
                  onClear={onClearSupplyId}
                  payloadKeys={['id', 'supplyContractNumber']}
                  // TODO понять какой запрос тут должен быть/ импортирован не существующий
                  // request={Search.getSupplySuggestions}
                  requestKey='number'
                  requestSubKeys={['supplierName', 'supplyDate']}
                  meta={{
                    touched: true,
                    error: submitted > 0 ? errors.supplyId : '',
                  }}
                  withoutLink
                />
              </Col12>
            </RowGrid>
          </Fieldset>
        }

        <Fieldset>
          <RowGrid>
            <Col12>
              <Select2
                label='Тип сущности'
                name='entityType'
                options={accountEntityTypeOptions}
                value={entityType}
                onChange={onChangeEntityType}
                error={submitted > 0 && errors.entityType}
              />
            </Col12>
          </RowGrid>
        </Fieldset>

        <Fieldset>
          <RowGrid>
            <Col12>
              <InputText
                type='text' 
                name='accountNumber'
                label='Номер счёта'
                value={accountNumber}
                onChange={onChangeAccountNumber}
                error={submitted > 0 && errors?.accountNumber}
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset>
          <RowGrid>
            <Col12>
              <Select2
                label='Тип счёта'
                name='accountType'
                options={accountTypeOptions}
                value={accountType}
                onChange={onChangeAccountType}
                error={submitted > 0 && errors.accountType}
              />
            </Col12>
          </RowGrid>
        </Fieldset>
        <Fieldset><RowGrid><Col12>&nbsp;</Col12></RowGrid></Fieldset>
        <Fieldset><RowGrid><Col12>&nbsp;</Col12></RowGrid></Fieldset>
        <Fieldset><RowGrid><Col12>&nbsp;</Col12></RowGrid></Fieldset>
        <Fieldset><RowGrid><Col12>&nbsp;</Col12></RowGrid></Fieldset>
      </Form>
      <ButtonWrapper>
        <Button
          text='Создать'
          disabled={submitted > 0 && !isValid}
          onClick={onSubmit}
        />
      </ButtonWrapper>
    </>
  )
}