import React, { useState } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Modal, Icon, Checkbox } from './../../../layout'
import { AddMessageForm } from '../Modals'
import { Button, H3, H4 } from '../Layout'
import { formattedNumberRub, formattedDate } from '../../../utils'
import styles from '../styles'

const useStyles = createUseStyles({
  ...styles,
  card: {
    width: '100%',
    margin: [30, 0, 100],
    border: [1, 'solid', '#B6BCC3'],
    display: 'inline-block',
    verticalAlign: 'top',
  },
  header: {
    background: '#eee',
    padding: [10, 15],
    color: '#888',
    fontSize: 12,
    lineHeight: 16/12,
    fontWeight: 600,
    textTransform: 'uppercase',
    borderBottom: [1, 'solid', '#B6BCC3'],
    position: 'sticky',
    top: 0,
  },
  body: {
    maxHeight: 'calc(100% - 37px)',
    overflow: 'auto',
    position: 'relative',
  },
  content: {
    padding: 15,
  },
  close: {
    width: 16,
    height: 16,
    cursor: 'pointer',
    position: 'absolute',
    top: 10,
    right: 15,
    transition: 'opacity 300ms ease',
    '&:hover': {
      opacity: 0.7,
    },
    '& > svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
      fill: '#484848',
    },
  },
  block: {
    // marginTop: 20,
    '& + &': {
      marginTop: 40,
    },
  },
  duplicateSettings: {
    marginTop: 20,
  },
  message: {
    background: '#eee',
    marginTop: 15,
    padding: 15,
    border: [1, 'solid', '#B6BCC3'],
    fontSize: 16,
    lineHeight: 20/16,
  },
  author: {
    marginTop: 15,
    color: '#000',
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'right',
  },
  empty: {
    marginTop: 15,
    fontSize: 14,
  },
  tableWrapper: {
    width: '100%',
    maxHeight: 500,
    marginTop: 10,
    overflowY: 'auto',
    '& table': {
      margin: [10, 0, 30, 0],
      border: [2, 'solid', '#0b1f35'],
    },
    '& th': {
      background: '#eee',
      padding: [6, 12],
      color: '#888',
      fontSize: 12,
      lineHeight: 18/12,
      fontWeight: 600,
      border: [1, 'solid', '#0b1f35'],
      textAlign: 'center',
      whiteSpace: 'nowrap',
      '&:first-child': {
        background: '#ccc',
        width: 200,
      },
    },
    '& td': {
      padding: [6, 12],
      border: [1, 'solid', '#0b1f35'],
      textAlign: 'center',
      whiteSpace: 'nowrap',
      '&:first-of-type:not(:only-of-type)': {
        background: '#eee',
        width: 200,
        color: '#888',
        fontSize: 12,
        lineHeight: 18/12,
        fontWeight: 600,
      },
      '&:only-child': {
        background: '#fff',
      },
    }
  },
})

const TITLES = [
  'За финансирование:',
  'За документы:',
  'За ФУДЗ:',
]

export const Card = ({
  className,
  entityName,
  displayName,
  onClose,
  onGetFactoringMessages,
}) => {
  const classes = useStyles()
  const { entityType } = useParams()

  const {
    messages,
    duplicateSettings,
    priceMatrix,
    supplyRegistrySupplyTab,
  } = useSelector(state => state?.Factoring || {})

  const [openModal, setOpenModal] = useState('')

  const onOpenModal = () => setOpenModal(true)
  const onCloseModal = () => setOpenModal(false)

  const isFactoringContract = entityType === 'factoring_contract' 
  const isSupplyContract = entityType === 'supply_contract'
  const isProduct = entityType === 'product'
  const isSupplyRegistry = entityType === 'supply_registry'

  return (
    <div className={classnames(classes.card, className)}>
      <div className={classes.header}>
        Владелец {entityName}: {displayName}
        <div className={classes.close} onClick={onClose}>
          <Icon iconName='close2' />
        </div>
      </div>
      <div className={classes.body}>
        <div className={classes.content}>
          {(isFactoringContract || isProduct) &&
            <div className={classes.block}>
              <H3>Тарифный план</H3>
              {priceMatrix.map(({ columns, rows, values }, index) =>
                <div className={classes.tableWrapper} key={index}>
                  <H4>{TITLES[index]}</H4>
                  <table>
                    {columns?.length > 0 &&
                      <thead>
                        <tr>
                          <th></th>
                          {columns.map((column, columnIndex) => 
                            <th key={columnIndex}>{column}</th>
                          )}
                        </tr>
                      </thead>
                    }
                    <tbody>
                      {rows?.length > 0
                        ? rows.map((row, rowIndex) => 
                            <tr key={rowIndex}>
                              <td>{row}</td>
                              {values[index]?.length > 0 && values[index].map((value, valueIndex) =>
                                <td key={valueIndex}>{value}</td>
                              )}
                            </tr>
                          )
                        : <tr><td>{values[0]}</td></tr>
                      }
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          }
          {isSupplyContract &&
            <div className={classes.block}>
              <H3>Дедупликация поставок</H3>
              <div className={classes.duplicateSettings}>
                {duplicateSettings.map(({ name, value }, index) => 
                  <Checkbox label={name} checked={value} key={index} disabled />
                )}
              </div>
            </div>
          }
          {isSupplyRegistry &&
            <div className={classes.block}>
              <H3>Поставки</H3>
              <div className={classes.wrapper}>
                <div className={classes.tableWrapper}>
                  <table>
                    <thead>
                      <tr>
                        <th className={classes.th} style={{background: '#eee'}}>ID поставки</th>
                        <th className={classes.th}>Тип документа</th>
                        <th className={classes.th}>№ поставки</th>
                        <th className={classes.th}>№ Реестра</th>
                        <th className={classes.th}>Дата реестра</th>
                        <th className={classes.th}>ГД</th>
                        <th className={classes.th}>Поставщик</th>
                        <th className={classes.th}>Дебитор</th>
                        <th className={classes.th}>Дата</th>
                        <th className={classes.th}>Приёмка</th>
                        <th className={classes.th}>Сумма</th>
                        <th className={classes.th}>Валюта</th>
                        <th className={classes.th}>Отсрочка</th>
                        <th className={classes.th}>Просрочено дней</th>
                        <th className={classes.th}>Статус</th>
                        <th className={classes.th}>Ввод</th>
                        <th className={classes.th}>Продукт</th>
                        <th className={classes.th}>Контракт</th>
                        <th className={classes.th}>Оригиналы</th>
                        <th className={classes.th}>Дата ожидания</th>
                        <th className={classes.th}>Регистрация</th>
                        <th className={classes.th}>Закрыто</th>
                        <th className={classes.th}>Верификация</th>
                        <th className={classes.th}>Уведомление о просрочке платежа дебитором</th>
                        <th className={classes.th}>Дата погаш фин</th>
                        <th className={classes.th}>Последний</th>
                        <th className={classes.th}>Изменение</th>
                        <th className={classes.th}>Закрытый факторинг</th>
                        <th className={classes.th}>Подтвержденная сумма</th>
                        <th className={classes.th}>Дата верификации</th>
                        <th className={classes.th}>Долг по финансированию</th>
                        <th className={classes.th}>Не оплачено продавцом</th>
                        <th className={classes.th}>Не оплачено дебитором</th>
                        <th className={classes.th}>Комиссия за финансирование</th>
                        <th className={classes.th}>Комиссия за ФУДЗ</th>
                        <th className={classes.th}>Создал</th>
                        <th className={classes.th}>Создание</th>
                        <th className={classes.th}>Примечание</th>
                        <th className={classes.th}>Комментарий</th>
                        <th className={classes.th}>Транзит</th>
                      </tr>
                    </thead>
                    <tbody>
                      {supplyRegistrySupplyTab?.map((item, index) => 
                        <tr key={index}>
                          <td className={classes.td}>{item?.id}</td>
                          <td className={classes.td}>{item?.documentType}</td>
                          <td className={classes.td}>{item?.supplyNumber}</td>
                          <td className={classes.td}>{item?.supplyRegistryNumber}</td>
                          <td className={classes.td}>{formattedDate(item?.supplyRegistryDate)}</td>
                          <td className={classes.td}>{item?.factoringContractNumber}</td>
                          <td className={classes.td}>{item?.supplierName}</td>
                          <td className={classes.td}>{item?.debtorName}</td>
                          <td className={classes.td}>{formattedDate(item?.supplyDate)}</td>
                          <td className={classes.td}>{formattedDate(item?.acceptanceDate)}</td>
                          <td className={classes.td}>{formattedNumberRub(item?.sum)}</td>
                          <td className={classes.td}>{item?.currency}</td>
                          <td className={classes.td}>{formattedDate(item?.deferDate)}</td>
                          <td className={classes.td}>{item?.overdueDays}</td>
                          <td className={classes.td}>{item?.status}</td>
                          <td className={classes.td}>{item?.createMethod}</td>
                          <td className={classes.td}>{item?.productType}</td>
                          <td className={classes.td}>{item?.supplyContractNumber}</td>
                          <td className={classes.td}>{item?.hasOriginals}</td>
                          <td className={classes.td}>{formattedDate(item?.waitingDate)}</td>
                          <td className={classes.td}>{formattedDate(item?.registrationDate)}</td>
                          <td className={classes.td}>{item?.closed}</td>
                          <td className={classes.td}>{item?.verification}</td>
                          <td className={classes.td}>{item?.debtorsPaymentDelayNotification}</td>
                          <td className={classes.td}>{formattedDate(item?.finPaymentDate)}</td>
                          <td className={classes.td}>{item?.last}</td>
                          <td className={classes.td}>{item?.changedBy}</td>
                          <td className={classes.td}>{item?.closedFactoring}</td>
                          <td className={classes.td}>{formattedNumberRub(item?.approvedSum)}</td>
                          <td className={classes.td}>{formattedDate(item?.verificationDate)}</td>
                          <td className={classes.td}>{formattedNumberRub(item?.asa)}</td>
                          <td className={classes.td}>{formattedNumberRub(item?.asb)}</td>
                          <td className={classes.td}>{formattedNumberRub(item?.asc)}</td>
                          <td className={classes.td}>{formattedNumberRub(item?.asd)}</td>
                          <td className={classes.td}>{formattedNumberRub(item?.ase)}</td>
                          <td className={classes.td}>{item?.createdBy}</td>
                          <td className={classes.td}>{item?.creation}</td>
                          <td className={classes.td}>{item?.notification}</td>
                          <td className={classes.td}>{item?.comment}</td>
                          <td className={classes.td}>{item?.transit}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          }
          <div className={classes.block}>
            <H3>
              <div>Комментарии:</div>
              <Button
                text='Добавить комментарий'
                onClick={onOpenModal}
              />
            </H3>
            {messages?.length > 0
              ? messages.map(({ authorFullName, dateTime, message }, index) =>
                  <div className={classes.message} key={index}>
                    {message}
                    <div className={classes.author}>
                      {authorFullName},
                      {' '}
                      {new Date(dateTime).toLocaleDateString('ru-RU')}
                      {' '} в {' '}
                      {new Date(dateTime).toLocaleTimeString()}
                    </div>
                  </div>
                )
              : <div className={classes.empty}>Комментарии отсутствуют</div>
            }
          </div>
        </div>
      </div>
      {openModal &&
        <Modal width='600px' height='340px' onClose={onCloseModal}>
          <AddMessageForm
            onGetFactoringMessages={onGetFactoringMessages}
            onClose={onCloseModal}
          />
        </Modal>
      }
    </div>
  )
}