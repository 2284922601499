import axios from 'axios'
import { SERVER } from '../constants'
import { prepareFiltersToQuery } from '../utility'

export const getTasksList = async (filters = {}, limit = 20) => {
  try {
    const query = prepareFiltersToQuery(filters)
    const {
      data: { error_code: status, ...rest }
    } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.SPA_ENDPOINT}/order/list?limit=${limit}${query}`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 5,
      message: rest.error
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message
    }
  }
}

let maskPhone = (phone = '') => {
  const countryCode = phone.substr(0, 1)
  const code = phone.substr(1, 3)
  const first = phone.substr(4, 3)
  const second = phone.substr(7, 2)
  const third = phone.substr(9, 2)

  const codePart = code ? `(${code})` : ''
  const secondPart = second ? `-${second}` : ''
  const thirdPart = third ? `-${third}` : third

  return phone ? `+${countryCode}${codePart}${first}${secondPart}${thirdPart}` : ''
}

const prepareTaskRkoFilters = filters => {
  const { email, inn, phone } = filters

  return { ...(email ? { email } : {}), ...(inn ? { inn } : {}), ...(phone ? { phone: maskPhone(phone) } : {}) }
}

export const getTasksListRko = async (filters = {}) => {
  try {
    const {
      data: { error_code: errorCode, status, message, ...rest }
    } = await axios({
      method: 'POST',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/front-partner/order/get-page`,
      data: { filter: prepareTaskRkoFilters(filters) }
    })

    if (!!errorCode) {
      return {
        isSuccess: false,
        needLogout: errorCode === 5,
        message: rest.error
      }
    }

    if (status && status === 'BAD_REQUEST') {
      return {
        isSuccess: true,
        message
      }
    }

    return {
      isSuccess: true,
      ...rest
    }
  } catch (err) {
    return {
      isSuccess: false,
      message: err.message
    }
  }
}

export const getNextPage = async (page, filters = {}, limit = 20) => {
  try {
    const query = prepareFiltersToQuery(filters)
    const {
      data: { error_code: status, ...rest }
    } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.SPA_ENDPOINT}/order/list?page=${page}&limit=${limit}${query}`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 5,
      message: rest.error
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message
    }
  }
}

export const assignTask = async taskId => {
  try {
    const {
      data: { error_code: status, ...rest }
    } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/task/claim?task_id=${taskId}`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 5,
      message: rest.error
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message
    }
  }
}

export const getNextTask = async taskId => {
  try {
    const {
      data: { error_code: status, ...rest }
    } = await axios({
      method: 'GET',
      params: { taskId },
      url:
        process.env.NODE_ENV === 'development'
          ? `/mocksApi/nextTask.json`
          : `${SERVER.HOST}${SERVER.API_ENDPOINT}/task/getNext`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 2,
      message: rest.error
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message
    }
  }
}

export const getNextProcessTask = async processInstanceId => {
  try {
    const {
      data: { error_code: status, ...rest }
    } = await axios({
      method: 'GET',
      params: { processInstanceId },
      url:
        process.env.NODE_ENV === 'development'
          ? `/mocksApi/nextTask.json`
          : `${SERVER.HOST}${SERVER.API_ENDPOINT}/task/getNext`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 2,
      message: rest.error
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message
    }
  }
}

export const getTaskUsers = async taskId => {
  try {
    const {
      data: { error_code: status, ...rest }
    } = await axios({
      method: 'GET',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/manager/task/getUsers?task_id=${taskId}`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 5,
      message: rest.error
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message
    }
  }
}

export const changeTasksUser = async data => {
  try {
    const {
      data: { error_code: status, ...rest }
    } = await axios({
      data,
      method: 'POST',
      url: `${SERVER.HOST}${SERVER.API_ENDPOINT}/manager/task/change`
    })
    if (status === 0) {
      return {
        isSuccess: true,
        ...rest
      }
    }
    return {
      isSuccess: false,
      needLogout: status === 5,
      message: rest.error
    }
  } catch (err) {
    console.log(err)
    return {
      isSuccess: false,
      message: err.message
    }
  }
}
