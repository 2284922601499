import React from 'react'
import { createUseStyles } from 'react-jss'
import { THEME_COLOR } from '../../../../config'

const useStyles = createUseStyles({
  button: {
    background: THEME_COLOR,
    padding: [17, 30],
    fontSize: 14,
    lineHeight: 1,
    border: 'none',
    borderRadius: 5,
    color: '#fff',
    fontWeight: 600,
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'top',
    '&:disabled': {
      background: 'rgba(80, 78, 91, 0.4)',
      cursor: 'default',
    },
  },
})

export const Button = ({
  text,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <button className={classes.button} {...rest}>{text}</button>
  )
}