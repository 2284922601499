import React from 'react'
import TextFieldWithAutoComplete from '../../components/TextFieldWithAutoComplete'
import TextField from '../../components/TextField'
import Dropdown from '../../components/Dropdown'

export const getFilterItem = ({
  // page props
  DEFAULT_OFFSET,
  entityType,
  values,
  title,
  filters,
  dataFilters,
  setFilters,
  setValues,
  onGetFactoringView,
  
  // filter props
  name,
  type,
  placeholder,
  payloadKey,
  payloadKeyUI,
  options,
  request,
  requestKey,
  requestSubKeys,
  disabledKey,
  showKey,
  showValue,
}) => {
  const onChangeFiltersByAutocomplete = (payload, name, payloadKey, payloadKeyUI) => {
    const newFilters = { ...filters, ...DEFAULT_OFFSET, [name]: payload[payloadKey] }
    const newValues = { ...values, ...DEFAULT_OFFSET, [name]: payload[payloadKeyUI] }
    if (payload[payloadKey] !== values[name]) {
      setFilters(newFilters)
      setValues(newValues)
      onGetFactoringView(newFilters)
    }
  }

  const onClearFiltersByAutocomplete = (name, value) => {
    const newFilters = { ...filters, ...DEFAULT_OFFSET, [name]: value }
    const newValues = { ...values, ...DEFAULT_OFFSET, [name]: value }
    if (value !== values[name]) {
      setFilters(newFilters)
      setValues(newValues)
      onGetFactoringView(newFilters)
    }
  }

  const onChangeFiltersBySelect = (name, value) => {
    const {
      disabledKey,
      name: disabledFilterName,
    } = dataFilters.filter(item => item?.disabledKey).find(item => item?.disabledKey === name) || {}
    let sideEffectFilters = {}
    if (name === disabledKey) {
      sideEffectFilters[disabledFilterName] = ''
    }
    const _value = value === 'all' ? '' : value
    const newFilters = { ...filters, ...DEFAULT_OFFSET, ...sideEffectFilters, [name]: _value }
    const newValues = { ...values, ...DEFAULT_OFFSET, ...sideEffectFilters, [name]: _value }
    if (value !== values[name]) {
      setFilters(newFilters)
      setValues(newValues)
      onGetFactoringView(newFilters)
    }
  }

  const onClearFiltersByInput = (name, value) => onChangeFiltersByInput({ target: { name, value }})

  const onChangeFiltersByInput = (e) => {
    const payload = { [e.target.name]: e.target.value }
    const newFilters =  { ...filters, ...DEFAULT_OFFSET, ...payload }
    const newValues = { ...values, ...DEFAULT_OFFSET, ...payload }
    if (e.target.value !== values[e.target.name]) {
      setFilters(newFilters)
      setValues(newValues)
      onGetFactoringView(newFilters)
    }
  }

  const value = values[name] || ''
  const fieldProps = {
    name,
    placeholder,
    value,
    defaultValue: value,
    disabled: (disabledKey && !values[disabledKey]) || false,
  }
  const activeOptionIndex = options ? options.map(({ id }) => id).indexOf(value) : 0
  const isShow = showKey ? values[showKey] === showValue : true
  const fields = {
    autocomplete: () => <TextFieldWithAutoComplete
      classNames={{ container: 'filter-input', input: 'filter-input__input' }}
      onSelect={(payload, name) => onChangeFiltersByAutocomplete(payload, name, payloadKey, payloadKeyUI)}
      onClear={onClearFiltersByAutocomplete}
      payloadKeys={[payloadKey, payloadKeyUI]}
      request={request}
      requestKey={requestKey}
      requestSubKeys={requestSubKeys}
      withoutLink
      {...fieldProps}
    />,
    text: () => <TextField
      type='text'
      onChange={() => {}}
      onBlur={onChangeFiltersByInput}
      onClear={onClearFiltersByInput}
      isMoney={false}
      {...fieldProps}
    />,
    date: () => <TextField
      type='date'
      onChange={onChangeFiltersByInput}
      onClear={onClearFiltersByInput}
      isMoney={false}
      {...fieldProps}
    />,
    select: () => <Dropdown
      defaultActive={activeOptionIndex}
      list={options}
      onSelectItem={onChangeFiltersBySelect}
      {...fieldProps}
    />
  }

  if (type === 'autocomplete' && !payloadKeyUI) {
    console.warn(`На странице ${title} (${entityType}) у фильтра ${name} отсутствует payloadKeyUI`)
  }
  
  return isShow ? fields[type] : null
}