import React, { useEffect, useState, useMemo } from 'react'
import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import {
  getFactoringTasks,
  getFactoringPaymentOrders,
  getFactoringMatchingsByTaskId,
  getFactoringMatchingsByPaymentId,
  completeFactoringTask,
} from '../../../redux/Factoring/actions'
import { Checkbox, Modal, Icon } from '../../../layout'
import { Toast } from '../../../components/Toast'
import { formattedNumber, formattedDate } from '../../../utils'
import { MatchingPaymentModal } from './MatchingPaymentModal'
import { Button } from '../Layout'
import { FactoringWrapper } from '../FactoringWrapper'
import { useFactoring } from '../useFactoring'

const useStyles = createUseStyles({
  h2: {
    padding: 10,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: 36/24,
    color: '#0B1F35',
  },
  container: {
    marginTop: 20,
    border: [1, 'solid', '#B6BCC3'],
  },
  tableWrapper: {
    maxHeight: 500,
    overflow: 'auto',
  },
  table: {
    marginBottom: 0,
    '& thead': {
      '& th': {
        background: '#eee',
        textAlign: 'left',
        position: 'sticky',
        top: 0,
        zIndex: 2,
        '&:first-child': {
          width: 40,
        },
        '& + th': {
          '& > div': {
            borderLeft: [1, 'solid', '#B6BCC3'],
          },
        },
        '& > div': {
          padding: 10,
          borderTop: [1, 'solid', '#B6BCC3'],
          fontSize: 12,
          lineHeight: 20/12,
          color: '#888',
          fontWeight: 600,
          textTransform: 'uppercase',
          whiteSpace: 'nowrap',
          position: 'relative',
          '&:after': {
            content: '""',
            position: 'absolute',
            bottom: -1,
            left: 0,
            right: 0,
            height: 1,
            background: '#B6BCC3',
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        '&.sticky': {
          '&:first-of-type': {
            '& > td': {
              '& > div': {
                borderTop: [1, 'solid', '#B6BCC3'],
                borderBottom: 'none',
              },
            },
          },
          '& + &': {
            '& > td': {
              borderTop: [1, 'solid', '#B6BCC3'],
              borderBottom: 'none',
            },
          },
          '& > td': {
            position: 'sticky',
            top: 40,
            zIndex: 2,
            '& > div': {
              borderBottom: [1, 'solid', '#B6BCC3'],
            },
          },
          '& + tr:not(.sticky)': {
            '& > td': {
              '& > div': {
                borderTop: 'none',
              },
            },
          },
        },
        '&.draft': {
          '& > td': {
            '& > div': {
              background: '#cce5ff',
              color: '#004085',
            },
          },
        },
        '& + tr': {
          '& > td': {
            '& > div': {
              borderTop: [1, 'solid', '#B6BCC3'],
            },
          },
        },
      },
      '& td': {
        padding: 0,
        whiteSpace: 'nowrap',
        textAlign: 'left',
        verticalAlign: 'top',
        '&:first-child': {
          background: '#eee',
          padding: 0,
        },
        '& + td': {
          '& > div': {
            borderLeft: [1, 'solid', '#B6BCC3'],
          },
        },
        '&.colspan': {
          '& > div': {
            color: '#0B1F35',
            fontSize: 14,
            lineHeight: 20/14,
            fontWeight: 600,
            background: '#F3F4F5',
            padding: [10, 10, 10, 50],
          },
        },
        '& > div': {
          background: '#fff',
          minHeight: 40,
          padding: 10,
          fontSize: 14,
          lineHeight: 20/14,
          color: '#0B1F35',
          whiteSpace: 'nowrap',
          '&.status': {
            background: '#D5F3E2',
            padding: [6, 22],
            borderRadius: 999,
            color: '#A9B563',
            fontWeight: 600,
          },
          '&.verified': {
            background: '#FEF2E6',
            padding: 6,
            color: '#F57C00',
            fontSize: 10,
            lineHeight: 13/10,
            fontWeight: 600,
            textTransform: 'uppercase',
            borderRadius: 999,
            display: 'inline-block',
            verticalAlign: 'top',
          },
          '&.rejected': {
            background: '#FDEAE9',
            padding: 6,
            color: '#FF5C5C',
            fontSize: 10,
            lineHeight: 13/10,
            fontWeight: 600,
            textTransform: 'uppercase',
            borderRadius: 999,
            display: 'inline-block',
            verticalAlign: 'top',
          },
          '&.ellipsis': {
            // maxWidth: 70,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          '&.bold': {
            fontWeight: 600,
          },
          '& > span': {
            display: 'inline-block',
            verticalAlign: 'top',
          },
          '& > svg': {
            width: 12,
            height: 12,
            marginLeft: 5,
            display: 'inline-block',
            verticalAlign: 'top',
          },
        },
      },
    },
  },
  info: {
    background: '#F3F4F5',
    minHeight: 60,
    padding: 10,
    color: '#0B1F35',
    fontSize: 16,
    lineHeight: 20/16,
    borderTop: [1, 'solid', '#B6BCC3'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span': {
      fontWeight: 600,
    },
  },
  checkbox: {
    width: 20,
    height: 20,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  name: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  tabs: {
    padding: 10,
  },
  tab: {
    padding: [10, 20],
    border: [1, 'solid', '#ECECEC'],
    color: '#0b1f35',
    fontSize: 13,
    lineHeight: 20/13,
    fontWeight: 600,
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'top',
    '&.active': {
      background: '#0b1f35',
      color: '#fff',
      '& > span': {
        background: '#fff',
        color: '#0b1f35',
      },
    },
    '& + &': {
      marginLeft: 20,
    },
    '& > span': {
      background: '#0b1f35',
      marginLeft: 8,
      padding: [0, 8],
      color: '#fff',
      orderRadius: 4,
      display: 'inline-block',
      verticalAlign: 'top',
    }
  },
  remove: {
    width: 16,
    height: 16,
    margin: 2,
    cursor: 'pointer',
    '& > svg': {
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
})

const TASKS_HEADS = [
  '№ Платежа',
  'Поставщик',
  'Плательщик',
  'Дата платежа',
  'Платеж',
  'Остаток',
  'Продукт',
  'Ген. договор',
]

const DEFAULT_SUPPLIES_HEADS = [
  '№ Поставки',
  'Поставщик',
  'Дебитор',
  'Дата документа',
  'Рег','Сумма',
  'Сумма квитовки',
  'Руб',
  'Не оплачено',
  'Долг',
  'Контракт',
  'Ввод',
  'Отсрочка',
  'Тип',
  'Дата уведомления',
  '№ уведомления',
  'Дата оплаты',
  'Срок оплаты',
]

const SUPPLIES_HEADS = {
  'Непогашенные поставки': DEFAULT_SUPPLIES_HEADS,
  'Комиссии': DEFAULT_SUPPLIES_HEADS,
  'Репликация квитовок': [
    '№ Поставки',
    'Поставщик',
    'Дебитор',
    'Дата квитовки',
    'Сумма платежа',
    'Тип',
  ]
}

const TABS = [
  'Непогашенные поставки',
  'Комиссии',
  'Репликация квитовок',
]

const ORDER_TYPES = {
  'Непогашенные поставки': [
    'supply',
  ],
  'Комиссии': [
    'financingCommission',
    'supplyCommissionDocProcessing',
    'supplyCommissionFudz',
  ],
}

export const FactoringMatchingPaymentsPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const entityType = 'payment_matching'

  const { dataFilters } = useFactoring({ entityType })

  const {
    tasks,
    paymentOrders,
    paymentMatchings,
    paymentComissions,
    paymentReplications,
  } = useSelector(state => state?.Factoring || {})

  const [taskId, setTaskId] = useState('')
  const [paymentId, setPaymentId] = useState('')
  const [purpose, setPurpose] = useState('')
  const [tab, setTab] = useState(TABS[0])
  const [order, setOrder] = useState(null)
  const [drafts, setDrafts] = useState([...paymentMatchings])
  const [payments, setPayments] = useState([...paymentOrders])
  const [comissions, setComissions] = useState([...paymentComissions])

  const isComissions = tab === 'Репликация квитовок'

  const DATA = {
    'Непогашенные поставки': payments,
    'Комиссии': comissions,
    'Репликация квитовок': paymentReplications,
  }

  const ACTIONS = useMemo(() => ({
    'Непогашенные поставки': () => {
      dispatch(getFactoringPaymentOrders({ orderType: ORDER_TYPES['Непогашенные поставки'] }))
    },
    'Комиссии': () => {
      dispatch(getFactoringPaymentOrders({ orderType: ORDER_TYPES['Комиссии'] }))
    },
    'Репликация квитовок': () => {
      paymentId && dispatch(getFactoringMatchingsByPaymentId(paymentId))
    },
  }), [paymentId, dispatch])

  useEffect(ACTIONS[tab], [ACTIONS, tab])
  useEffect(ACTIONS['Комиссии'], [ACTIONS])
  useEffect(ACTIONS['Репликация квитовок'], [ACTIONS])

  useEffect(() => {
    if (paymentId) {
      dispatch(getFactoringMatchingsByTaskId(paymentId))
    }
  }, [paymentId, dispatch])

  useEffect(() => {
    setDrafts([...paymentMatchings])
  }, [paymentMatchings])

  useEffect(() => {
    const newPaymentOrders = [
      ...paymentOrders.filter(payment => drafts.length > 0
        ? !drafts.find(draft => draft.order.id === payment.id)
        : true
      )
    ]
    setPayments(newPaymentOrders)
  }, [paymentOrders, drafts])

  useEffect(() => {
    const newPaymentComissions = [
      ...paymentComissions.filter(comission => drafts.length > 0
        ? !drafts.find(draft => draft.order.id === comission.id)
        : true
      )
    ]
    setComissions(newPaymentComissions)
  }, [paymentComissions, drafts])

  const onSelectPayment = (payload) => {
    setTaskId(taskId && taskId === payload.taskId ? '' : payload.taskId)
    setPaymentId(paymentId && paymentId === payload.paymentId ? '' : payload.paymentId)
    setPurpose(paymentId && paymentId === payload.paymentId ? '' : payload.purpose)
  }
  const onChangeType = (type) => setTab(type)
  const onSelectOrder = (payload) => setOrder(order?.id && order?.id === payload?.id ? null : payload)
  const onCloseModal = () => setOrder(null)
  
  const onAddDraft = (payload) => {
    setDrafts([...drafts, payload])
    setPayments(payments.filter(item => item?.id !== payload?.order?.id))
    setComissions(comissions.filter(item => item?.id !== payload?.order?.id))
  }
  
  const onRemoveDraft = (payload) => {
    setPayments([payload.order, ...payments])
    setComissions([payload.order, ...comissions])
    setDrafts(drafts.filter(item => item?.order?.id !== payload?.order?.id))
  }

  const onResetSelect = () => {
    setTaskId('')
    setPaymentId('')
    setDrafts([])
  }

  const onSubmit = () => {
    const onSuccess = () => {
      Toast({
        message: `Завершение задачи платежей прошло успешно`,
        type: 'success',
      })
      onResetSelect()
    }
    const onError = (error) => {
      Toast({
        message: error || `Завершение задачи платежей не удалось`,
        type: 'error',
      })
      onResetSelect()
    }
    dispatch(completeFactoringTask(taskId, 'PAYMENT', drafts, onSuccess, onError))
  }

  return (
    <FactoringWrapper
      title='Квитовка платежей'
      dataFilters={dataFilters}
      entityType={entityType}
      onRequest={getFactoringTasks}
      requestPayload={{
        taskDefinitionKey: 'UserTaskPaymentManualMatching',
        type: 'PAYMENT',
      }}
      withoutPagination
    >
      <div className={classes.container}>
        <div className={classes.h2}>Список платежей/задач</div>
        <div className={classes.tableWrapper}>
          <table className={classes.table}>
            <thead>
              <tr>
                <th><div><span>&nbsp;</span></div></th>
                {TASKS_HEADS.map((item, index) => 
                  <th key={index}>
                    <div>
                      <span>{item}</span>
                    </div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {tasks.map((item, index) =>
                <tr key={index}>
                  <td>
                    <div>
                      <Checkbox
                        id={item?.data?.payment?.id}
                        className={classes.checkbox}
                        checked={item?.data?.payment?.id === paymentId}
                        onChange={() => onSelectPayment({
                          taskId: item?.id,
                          paymentId: item?.data?.payment?.id,
                          purpose: item?.data?.payment?.purpose,
                        })}
                        title={item?.data?.payment?.id === paymentId ? 'Снять выбор' : 'Выбрать'}
                      />
                    </div>
                  </td>
                  <td><div>{item?.data?.payment?.number || '-'}</div></td>
                  <td><div>{item.data?.payment?.supplierName || '-'}</div></td>
                  <td><div>{item.data?.payment?.payerName || '-'}</div></td>
                  <td><div>{formattedDate(item.data?.payment?.date)}</div></td>
                  <td><div>{formattedNumber(item.data?.payment?.amount) || '0'} ₽</div></td>
                  <td><div>{formattedNumber(item.data?.payment?.unmatchedAmount) || '0'} ₽</div></td>
                  <td><div>{item.data?.payment?.product || '-'}</div></td>
                  <td><div>{item.data?.payment?.factoringContractNumber || '-'}</div></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={classes.info}>
          <div>
            <span>Назначение платежа:</span> {purpose}
          </div>
        </div>
      </div>
      <div className={classes.container}>
        <div className={classes.tabs}>
          {TABS.map((tabItem, index) =>
            <div
              key={index}
              className={classnames(classes.tab, { 'active': tabItem === tab })}
              onClick={() => onChangeType(tabItem)}
            >
              {tabItem} <span>{DATA[tabItem]?.length}</span>
            </div>
          )}
        </div>
        <div className={classes.tableWrapper}>
          <table className={classes.table}>
            <thead>
              <tr>
                <th><div>&nbsp;</div></th>
                {SUPPLIES_HEADS[tab].map((item, index) => 
                  <th key={index}><div>{item}</div></th>
                )}
              </tr>
            </thead>
            <tbody>
              {!isComissions && 
                <>
                  <tr className='sticky'>
                    <td className='colspan' colSpan={SUPPLIES_HEADS[tab].length + 1}>
                      <div>Черновики</div>
                    </td>
                  </tr>
                  {drafts?.length > 0
                    ? drafts.map((draft, index) => {
                        const topCss = {
                          top: 40 * (index + 2),
                        }
                        return (
                          <tr className='sticky draft' key={index}>
                            <td style={topCss}>
                              <div>
                                <div
                                  className={classes.remove}
                                  onClick={() => onRemoveDraft(draft)}
                                  title='Удалить из черновика'
                                >
                                  <Icon iconName='close2' />
                                </div>
                              </div>
                            </td>
                            <td style={topCss}><div>{draft?.order?.number || '-'}</div></td>
                            <td style={topCss}><div>{draft?.order?.supplierName || '-'}</div></td>
                            <td style={topCss}><div>{draft?.order?.principalName || '-'}</div></td>
                            <td style={topCss}><div>{formattedDate(draft?.order?.supplyDate)}</div></td>
                            <td style={topCss}><div>{'-'}</div></td>
                            <td style={topCss}><div>{formattedNumber(draft?.order?.amount) || '0'} ₽</div></td>
                            <td style={topCss}><div>{formattedNumber(Number(draft?.matchedAmount)) || '0'} ₽</div></td>
                            <td style={topCss}><div>{'-'}</div></td>
                            <td style={topCss}><div>{'-'}</div></td>
                            <td style={topCss}><div>{'-'}</div></td>
                            <td style={topCss}><div>{draft?.order?.supplyContractNumber || '-'}</div></td>
                            <td style={topCss}><div>{'-'}</div></td>
                            <td style={topCss}><div>{formattedDate(draft?.order?.deferDate)}</div></td>
                            <td style={topCss}><div>{draft?.order?.orderType || '-'}</div></td>
                            <td style={topCss}><div>{'-'}</div></td>
                            <td style={topCss}><div>{'-'}</div></td>
                            <td style={topCss}><div>{'-'}</div></td>
                            <td style={topCss}><div>{'-'}</div></td>
                          </tr>
                        )
                      })
                    : <tr className='sticky'>
                        <td colSpan={SUPPLIES_HEADS[tab].length + 1} style={{ top: 80 }}>
                          <div>Черновики отсутствуют</div>
                        </td>
                      </tr>
                  }
                </>
              }
              <tr>
                <td className='colspan' colSpan={SUPPLIES_HEADS[tab].length + 1}>
                  <div>Поставки</div>
                </td>
              </tr>
              {DATA[tab].map((item, index) =>
                <tr key={index}>
                  <td>
                    <div>
                      {!isComissions &&
                        <Checkbox
                          id={item.id}
                          className={classes.checkbox}
                          checked={order?.id === item.id}
                          onChange={() => onSelectOrder(item)}
                          title={order?.id === item.id ? 'Снять выбор' : 'Выбрать'}
                        />
                      }
                    </div>
                  </td>
                  {isComissions
                    ? <>
                        <td><div>{item?.order?.number || '-'}</div></td>
                        <td><div>{'-'}</div></td>
                        <td><div>{item?.order?.principalName || '-'}</div></td>
                        <td><div>{formattedDate(item?.order?.orderDate)}</div></td>
                        <td><div>{item?.matchedAmount || '-'}</div></td>
                        <td><div>{item?.order?.orderType || '-'}</div></td>
                      </>
                    : <>
                        <td><div>{item?.number || '-'}</div></td>
                        <td><div>{item?.supplierName || '-'}</div></td>
                        <td><div>{item?.principalName || '-'}</div></td>
                        <td><div>{formattedDate(item?.supplyDate)}</div></td>
                        <td><div>{'-'}</div></td>
                        <td><div>{formattedNumber(item?.amount) || '0'} ₽</div></td>
                        <td><div>{formattedNumber(Number(item?.matchedAmount)) || '0'} ₽</div></td>
                        <td><div>{'-'}</div></td>
                        <td><div>{'-'}</div></td>
                        <td><div>{'-'}</div></td>
                        <td><div>{item?.supplyContractNumber || '-'}</div></td>
                        <td><div>{'-'}</div></td>
                        <td><div>{formattedDate(item?.deferDate)}</div></td>
                        <td><div>{item?.orderType || '-'}</div></td>
                        <td><div>{'-'}</div></td>
                        <td><div>{'-'}</div></td>
                        <td><div>{'-'}</div></td>
                        <td><div>{'-'}</div></td>
                      </>
                  }
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={classes.info}>
          <div>&nbsp;</div>
          {!isComissions &&
            <Button
              text='Квитировать'
              disabled={!drafts?.length}
              onClick={onSubmit}
            >
              Связать
            </Button>
          }
        </div>
      </div>
      {paymentId && order &&
        <Modal width='600px' height='348px' onClose={onCloseModal}>
          <MatchingPaymentModal
            tab={tab}
            order={order}
            paymentId={paymentId}
            onAddDraft={onAddDraft}
            onClose={onCloseModal}
          />
        </Modal>
      }
    </FactoringWrapper>
  )
}