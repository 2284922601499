import React, { Fragment, useState } from 'react'
import { Collapse } from 'reactstrap'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import Modal from '../index'
import { Icon } from '../../index'
import { formattedDateTime } from '../../../utils'

const styles = {
  root: {
    padding: '10px 0'
  },
  list: {
    position: 'relative',
    '&.activeList': {
      marginTop: -20,
    },
  },
  toggle: {
    paddingLeft: 62,
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'top',
    '&.mt40': {
      marginTop: 40,
    },
  },
  icon: {
    width: 50,
    height: 50,
    top: -9,
    cursor: 'pointer',
  },
  item: {
    background: '#f3f3f3',
    width: 264,
    padding: [18, 20],
    marginRight: 20,
    marginTop: 20,
    borderRadius: 16,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  title: {
    height: 34,
    color: '#000',
    fontSize: 15,
    lineHeight: 17/15,
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    '&.isComplete': {
      opacity: 0.5,
    },
  },
  date: {
    marginTop: 15,
    color: '#B8B8B8',
    fontSize: 12,
    lineHeight: 14/12,
    fontWeight: 500,
  },
  user: {
    marginTop: 25,
    color: '#000',
    fontSize: 12,
    lineHeight: 16/12,
    fontWeight: 500,
    '& svg': {
      width: 16,
      height: 16,
      marginRight: 8,
      fill: '#9498A9',
      display: 'inline-block',
      verticalAlign: 'top',
    },
    '& span': {
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
}

const Activities = ({
  classes,
  items,
}) => {
  const [open, setOpen] = useState(false)

  const isItems = items && items.length

  const active = isItems ? items.filter(item => !item.isComplete) : []
  const completed = isItems ? items.filter(item => item.isComplete) : []

  const isActive = active && active.length
  const isCompleted = completed && completed.length

  const renderItems = (items, custom) => 
    <div className={classnames(classes.list, { 'activeList': custom })}>
      {items.map(({ assignee, title, isComplete, startTime, endTime }, index) =>
        <div className={classes.item} key={`activity-item-${index}`}>
          <div className={classnames(classes.title, { isComplete })}>{title}</div>
          <div className={classes.date}>
            {formattedDateTime(isComplete ? endTime : startTime)}
          </div>
          <div className={classes.user}>
            <Icon iconName='user' />
            <span>{assignee || '—'}</span>
          </div>
        </div>
      )}
    </div>

  if (!isItems) {
    return null
  }

  return (
    <Modal.Block iconName='bar-chart' title='Активности'>
      <Modal.Content>
        <div className={classes.root}>
          {isActive ? 
            renderItems(active, true)
            : null
          }
          {isCompleted ?
            <Fragment>
              <Modal.H2 
                className={classnames(classes.toggle, { 
                  'mt40': isActive, 
                })} 
                icon={{ 
                  name: 'arrow-bottom', 
                  className: classes.icon, 
                }}
                open={open}
                onClick={() => setOpen(!open)}
              >
                {open ? 'Скрыть' : 'Показать'} завершенные
              </Modal.H2>
              <Collapse isOpen={open}>
                {renderItems(completed)}
              </Collapse>
            </Fragment>
            : null
          }
        </div>
      </Modal.Content>
    </Modal.Block>
  )
}

export default injectSheet(styles)(Activities)