import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import { adaptive_1200 } from '../../../config'

const styles = {
  root: {
    backgroundColor: '#fff',
    borderRadius: 16,
    marginBottom: 30,
    paddingBottom: 40,
    overflow: 'hidden',
    [adaptive_1200]: {
      borderRadius: 0,
    },
  },
}

const Item = ({
  children,
  className,
  classes,
}) =>
  <div className={classnames(classes.root, className)}>
    {children}
  </div>

export default injectSheet(styles)(Item)