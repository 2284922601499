import React from 'react'
import Icons from './img.svg'

export default ({
	className,
	iconName,
	style,
}) => 
	<svg className={className} style={style}>
	  <use xlinkHref={`${Icons}#${iconName}`} />
	</svg>