import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch } from 'react-redux'
import { getTaskUsers } from '../../../redux/Tasks/actions'
import { Modal } from '../../../layout'
import Content from './Content'
import ContentWithLogin from './ContentWithLogin'

const useStyles = createUseStyles({
  root: {
    background: '#fff',
    width: '100%',
    height: '100%',
  },
})

export const ModalAssignee = ({
  width,
  height,
  taskId,
  onClose,
  byLogin,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  
  useEffect(() => {
    if (!byLogin){
		  dispatch(getTaskUsers(taskId))
    }
  }, [])

  return (
    <Modal className={classes.root} width={width} height={height} onClose={onClose}>
      {
        byLogin ? 
        <ContentWithLogin taskId={taskId} onClose={onClose}/> :
        <Content taskId={taskId} onClose={onClose} />
      }
    </Modal>
  )
}