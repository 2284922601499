import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { clearErrorContent } from '../../redux/Error/actions'

class SnackBar extends PureComponent {
  static propTypes = {
    content: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.timer = setTimeout(() => this.onClose(), 3000)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  onClose = () => this.props.dispatch(clearErrorContent())

  render() {
    const { content } = this.props

    return (
      <div className='notification'>
        <span className='notification__text'>{content}</span>
        <button type='button' className='notification__reload-link' onClick={this.onClose}>
          <i className='icon icon-close-s' />
        </button>
      </div>
    )
  }
}

const mapStateToProps = ({ Error }) => {
  return {
    content: Error.text,
  }
}

export default connect(mapStateToProps)(SnackBar)