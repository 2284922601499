import { useState } from 'react'
import { signDocument } from '../api'
import { IDataToSignItem } from '../declarations'
import { ERRORS } from '../constants'

export const useSignDocument = () => {
  const [isSigning, setIsSigning] = useState<boolean>(false)
  const [signError, setSignError] = useState<string | null>(null)
  const [signedData, setSignedData] = useState<string | null>(null)

  const sign = async (
    certificateThumbprint: string,
    documentUrl: string,
    documentName: string,
    fileId: string,
    setDataToSign: React.Dispatch<React.SetStateAction<IDataToSignItem[]>>,
    dataToSign: IDataToSignItem[]
  ) => {
    setIsSigning(true)
    setSignError(null)

    const isAlreadySigned = dataToSign.some(signedDoc => signedDoc.filename === documentName)
    if (isAlreadySigned) {
      return
    }

    try {
      const signedData = await signDocument(certificateThumbprint, documentUrl, documentName)
      setSignedData(signedData)
      const dataToPost = {
        content: signedData,
        filename: documentName + '.sig',
        fileId: fileId
      }
      setDataToSign(prevState => [...prevState, dataToPost])
    } catch (err) {
      setSignError(ERRORS.SIGN_ERROR + err)
    } finally {
      setIsSigning(false)
    }
  }

  return { isSigning, signError, signedData, sign }
}
