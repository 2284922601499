import React, { Component } from 'react'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _uniqBy from 'lodash/uniqBy'
import { fetchPeriodsList, fetchEmployeeStat, fetchCompanyStat } from '../../redux/Statistics/actions'
import Dropdown from '../../components/Dropdown'
import StatsToggler from '../../components/StatsToggler'
import StatsInfoBlock from '../../components/StatsInfoBlock'
import Overlay from '../../components/Overlay'
import { adaptive_1200 } from '../../config'


const styles = {
  user: {
    marginRight: 24,
    color: '#fff',
    fontSize: 24, 
    fontWeight: 500, 
    lineHeight: 1.17,
    
    [adaptive_1200]: {
      display: 'block',
      marginRight: 0,
      marginBottom: 10,
      paddingLeft: 12,
      paddingRight: 12,
      fontSize: 16,
    }
  }
}

class UserStatistics extends Component {
  static propTypes = {
    username: PropTypes.string.isRequired,
    fetchStatus: PropTypes.shape({
      periods: PropTypes.bool.isRequired,
      employee: PropTypes.bool.isRequired,
      company: PropTypes.bool.isRequired,
      widget: PropTypes.bool.isRequired,
    }).isRequired,
    periods: PropTypes.array.isRequired,
    employees: PropTypes.array.isRequired,
    companyStats: PropTypes.shape({
      items: PropTypes.object.isRequired,
      countSum: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
      amountSum: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
      noItems: PropTypes.bool.isRequired,
    }).isRequired,
    employeeStats: PropTypes.shape({
      items: PropTypes.object.isRequired,
      countSum: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
      amountSum: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
      noItems: PropTypes.bool.isRequired,
    }).isRequired,
  }

  state = {
    periods: {
      activeCompany: 0,
      activeEmployee: 0,
      list: [{ key: 'default', value: 'месяц' }]
    },
    employees: {
      active: 0,
      list: [{ key: 'default', value: 'Сотрудник' }]
    },
    processes: {
      activeCompany: 0,
      activeEmployee: 0,
    },
    indicator: 'count',
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      periods: nextPeriods,
      employees: nextEmployees,
      username,
    } = nextProps
    const {
      periods: { list: prevPeriodsList },
      employees: { list: prevEmployeesList, active: activeEmployee },
    } = prevState
    const preparedPeriods = UserStatistics.getPreparedPeriods(prevPeriodsList, nextPeriods)

    const needCalcUserPosition = prevEmployeesList[activeEmployee].key !== username
    const preparedEmployees = UserStatistics.getPreparedEmployees(
      prevEmployeesList,
      nextEmployees,
      needCalcUserPosition ? username : ''
    )

    if (Object.keys(preparedPeriods).length && Object.keys(preparedEmployees).length) {
      return {
        periods: preparedPeriods,
        employees: preparedEmployees,
      }
    }
    if (Object.keys(preparedEmployees).length) {
      return { employees: preparedEmployees }
    }
    if (Object.keys(preparedPeriods).length) {
      return { periods: preparedPeriods }
    }

    return {}
  }

  componentDidMount() {
    this.props.dispatch(fetchPeriodsList())
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      periods: {
        activeCompany: prevActiveCompanyPeriod,
        activeEmployee: prevActiveEmployeePeriod
      },
      employees: { active: prevActiveEmployee },
    } = prevState
    const { periods, employees } = this.state

    const {
      activeCompany: nexActiveCompanyPeriod,
      activeEmployee: nextActiveEmployeePeriod
    } = periods
    const { active: nextActiveEmployee } = employees

    if (nexActiveCompanyPeriod !== prevActiveCompanyPeriod) {
      const periodKey = periods.list[nexActiveCompanyPeriod].key
      if (periodKey !== 'default') {
        this.props.dispatch(fetchCompanyStat(periodKey))
      }
    }

    if (nextActiveEmployeePeriod !== prevActiveEmployeePeriod ||
      nextActiveEmployee !== prevActiveEmployee) {
      const periodKey = periods.list[nextActiveEmployeePeriod].key
      const username = employees.list[nextActiveEmployee].key
      if (username !== 'default' && periodKey !== 'default') {
        this.props.dispatch(fetchEmployeeStat(periodKey, username))
      }
    }
  }

  static getPreparedPeriods(prevPeriodsList, nextPeriods) {
    const list = nextPeriods.reduce((result, period) => {
      return result.concat({ key: period.period, value: period.name })
    }, [])
    const uniqList = _uniqBy(prevPeriodsList.concat(list), 'key')
    if (uniqList.length === prevPeriodsList.length) {
      return {}
    }
    return {
      list,
      activeCompany: list.length - 1,
      activeEmployee: list.length - 1,
    }
  }

  static getPreparedEmployees(prevEmployeesList, nextEmployees, username) {
    const needCalculate = !!username
    let active = 0
    const list = nextEmployees.reduce((result, employee, index) => {
      if (needCalculate && username === employee) {
        active = index
      }
      return result.concat({ key: employee, value: employee })
    }, [])
    const uniqList = _uniqBy(prevEmployeesList.concat(list), 'key')
    if (uniqList.length === prevEmployeesList.length) {
      return {}
    }
    return {
      list,
      active: active,
    }
  }

  onSelectDropdown = (name, key, index) => {
    const preparedName = name.split('_')
    this.setState({
      [`${preparedName[0]}`]: Object.assign(
        {},
        this.state[preparedName[0]],
        {
          [`active${preparedName[1] ? preparedName[1] : ''}`]: index
        }
      ),
    })
  }

  onToggleIndicator = (indicator) => this.setState({ indicator })

  getProccessValues = (payload) => {
    const keys = Object.keys(payload)
    const values = Object.values(payload).map((item, index) => {
      return {
        key: keys[index],
        value: item.name,
      }
    })
    values.unshift({
      key: '', 
      value: 'Все процессы',
    })

    return values
  }

  getActiveProccessKey = (payload, index) => {
    const keys = Object.keys(payload)
    return keys[index - 1]
  }

  getProcessStats = (payload, key) => {
    const stats = {...payload.items.process[key]}
    delete stats.name
    return { 
      items: stats,
      countSum: NaN,
      amountSum: NaN,
      noItems: false,
    }
  }

  render() {
    const { periods, employees, processes, indicator } = this.state
    const { 
      classes, 
      fetchStatus, 
      companyStats, 
      employeeStats,
      companyProcesses, 
      employeeProcesses,
      settings, 
      displayName,
    } = this.props

    const companyProcessesValues = this.getProccessValues(companyProcesses)
    const activeCompanyProcess = this.getActiveProccessKey(companyProcesses, processes.activeCompany)
    const _companyStats = activeCompanyProcess
      ? this.getProcessStats(companyStats, activeCompanyProcess)
      : companyStats

    const employeeProcessesValues = this.getProccessValues(employeeProcesses)
    const activeEmployeeProcess = this.getActiveProccessKey(employeeProcesses, processes.activeEmployee)
    const _employeeStats = activeEmployeeProcess
      ? this.getProcessStats(employeeStats, activeEmployeeProcess)
      : employeeStats

    return (
      <div className='modal-content__inner chart-stats'>
        <div className='chart-stats__item'>
          <div className='chart-stats__filter'>
            <StatsToggler
              name='count'
              text='Заявки'
              isActive={indicator === 'count'}
              onClick={this.onToggleIndicator}
            />
            <StatsToggler
              name='amount'
              text='Деньги'
              isActive={indicator === 'amount'}
              onClick={this.onToggleIndicator}
            />
          </div>
          <div className='chart-stats__head'>
            <Dropdown
              name='periods_Employee'
              defaultText='Статистика за'
              defaultActive={periods.activeEmployee}
              list={periods.list}
              disabled={periods.list.length < 2}
              onSelectItem={this.onSelectDropdown}
            />
            <Dropdown
              name='employees'
              defaultText='пользователя'
              defaultActive={employees.active}
              list={employees.list}
              disabled={periods.list.length < 2}
              onSelectItem={this.onSelectDropdown}
            />
            <Dropdown
              name='processes_Employee'
              list={employeeProcessesValues}
              defaultActive={processes.activeEmployee}
              disabled={employeeProcessesValues.length < 2}
              onSelectItem={this.onSelectDropdown}
            />
          </div>
          {fetchStatus.employee
            ? <div className='chart-stats__item chart-stats__item--fetching'>
                <Overlay inverse />
              </div>
            : <div className='chart-stats__body'>
                <StatsInfoBlock
                  infoBlock={_employeeStats}
                  indicator={indicator}
                  statusItems={settings.statusItems}
                />
              </div>
          }
        </div>
        <div className='chart-stats__item'>
          <div className='chart-stats__head'>
            <Dropdown
              name='periods_Company'
              defaultText='Статистика за'
              defaultActive={periods.activeCompany}
              list={periods.list}
              onSelectItem={this.onSelectDropdown}
            />
            <span className={classes.user}>{`агента ${displayName}`}</span>
            <Dropdown
              name='processes_Company'
              list={companyProcessesValues}
              defaultActive={processes.activeCompany}
              disabled={companyProcessesValues.length < 2}
              onSelectItem={this.onSelectDropdown}
            />
          </div>
          {fetchStatus.employee
            ? <div className='chart-stats__item chart-stats__item--fetching'>
                <Overlay inverse />
              </div>
            : <div className='chart-stats__body'>
                <StatsInfoBlock
                  infoBlock={_companyStats}
                  indicator={indicator}
                  globalColor='white'
                  statusItems={settings.statusItems}
                />
              </div>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ User, Statistics, Settings }) => {
  return {
    fetchStatus: Statistics?.fetchStatus,
    periods: Statistics?.periods,
    companyStats: Statistics?.company,
    employeeStats: Statistics?.employee,
    companyProcesses: Statistics?.company?.items?.process || {},
    employeeProcesses: Statistics?.employee?.items?.process || {},
    username: User?.username,
    employees: User?.companyEmployees,
    settings: User?.settings,
    displayName: Settings?.company?.displayName,
  }
}

export default connect(mapStateToProps)(injectSheet(styles)(UserStatistics))