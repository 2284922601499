import React, { useState, useEffect, useRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'
import debounce from 'lodash/debounce'
import { getDadata } from '../../services/api/dadata'
import { Error } from '../../layout'
import { useOutsideClick } from '../../hooks'
import { formattedCard } from '../../utils'

const useStyles = createUseStyles({
  inputWithDadata: {
    position: 'relative',
    '& input:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 1000px #ffffff inset !important',
    },
    '& input:-webkit-autofill:hover': {
      '-webkit-box-shadow': '0 0 0 1000px #ffffff inset !important',
    },
    '& input:-webkit-autofill:focus textarea:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 1000px #ffffff inset !important',
    },
    '& textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus': {
      '-webkit-box-shadow': '0 0 0 1000px #ffffff inset !important',
    },
    '& select:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 1000px #ffffff inset !important',
    },
    '& select:-webkit-autofill:hover': {
      '-webkit-box-shadow': '0 0 0 1000px #ffffff inset !important',
    },
    '& select:-webkit-autofill:focus': {
      '-webkit-box-shadow': '0 0 0 1000px #ffffff inset !important',
    },
  },
  wrapper: {
    backgroundColor: '#f8f8f8',
    marginTop: 8,
    padding: [6, 10],
    fontSize: 16,
    lineHeight: 1.5,
    color: '#262c40',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#e3e3e3',
    position: 'relative',
    '&.disabled': {
      background: '#E6EAF0',
    },
    '&.error': {
      borderColor: '#c84f4f',
      '&:-moz-placeholder': {
        color: '#c84f4f',
      },
      '&::-moz-placeholder': {
        color: '#c84f4f',
      },
      '&:-ms-input-placeholder': {
        color: '#c84f4f',
      },
      '&::-webkit-input-placeholder': {
        color: '#c84f4f',
      },
      '&::-webkit-text-fill-color': {
        color: '#c84f4f',
      },
    },
  },
  label: {
    fontSize: 11,
    lineHeight: 1.2,
    color: '#498dde',
  },
  value: {
    marginTop: 4,
    padding: [6, 0],
    fontSize: 12,
    lineHeight: 1.2,
    // color: '#262c40',
    color: '#777',
    fontFamily: 'Courier New,Courier,monospace',
  },
  inputWrapper: {
    width: '100%',
    display: 'inline-block',
    verticalAlign: 'top',
  },
  input: {
    backgroundColor: '#f8f8f8',
    boxSizing: 'border-box',
    width: '100%',
    padding: [6, 0],
    fontSize: 12,
    lineHeight: 1.5,
    fontWeight: 500,
    color: '#495057',
    display: 'block',
    border: 'none',
    outline: 'none',
    borderWidth: 1,
    borderBottomStyle: 'dotted',
    borderColor: '#498dde',
    // caretColor: '#498dde',
    transition: 'border 250ms ease',
    '&:-moz-placeholder': {
      color: 'rgba(36, 34, 50, 0.6)',
    },
    '&::-moz-placeholder': {
      color: 'rgba(36, 34, 50, 0.6)',
    },
    '&:-ms-input-placeholder': {
      color: 'rgba(36, 34, 50, 0.6)',
    },
    '&::-webkit-input-placeholder': {
      color: 'rgba(36, 34, 50, 0.6)',
    },
    '&::-webkit-text-fill-color': {
      color: 'rgba(36, 34, 50, 0.6)',
    },
    '&:focus': {
      borderBottomStyle: 'solid',
    },
    '&:disabled': {
      background: '#E6EAF0',
      color: '#8b98a7',
    },
    '&.error': {
      color: 'red',
      borderColor: 'red',
    },
  },
  scroll: {
    background: '#fff',
    width: '100%',
    maxHeight: 320,
    padding: [10, 0],
    position: 'absolute',
    left: 0,
    right: 0,
    top: 45,
    border: [1, 'solid', '#ddd'],
    zIndex: 2,
  },
  tooltip: {
    padding: [0, 20],
    fontSize: 14,
    color: '#999',
    position: 'relative',
  },
  list: {
    margin: [10, 0, 0, 0],
    padding: 0,
    listStyle: 'none',
    position: 'relative',
  },
  item: {
    padding: [10, 20],
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      background: '#ececf4',
    },
    '& p': {
      margin: 0,
      color: '#999',
    },
  },
})

export const InputWithDadata = ({
  service,
  value,
  children,
  disabled,
  onSelect,
  meta,
  label,
  DEFAULT_VALUES,
  error,
  ...rest
}) => {
  const classes = useStyles()

  const initialState = value || {}

  const _error = error || meta?.error
  
  const getValue = (value) => {
    switch(true) {
      case service === 'address': return (value && value.result) || ''
      case service === 'bank': return (value && value.bankName) || ''
      case service === 'party': return (value && value.fullName) || ''
      default: return value
    }
  }

  const [query, setQuery] = useState(getValue(value))
  const [focus, setFocus] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [selected, setSelected] = useState(initialState)
  const [flat, setFlat] = useState(initialState && initialState.flat)

  useEffect(() => {
    !focus && query && setQuery(getValue(selected))
    if (!focus && !query) {
      setSelected({})
      service === 'party' && onSelect({
        fullName: DEFAULT_VALUES.fullName,
        inn: DEFAULT_VALUES.inn,
        ogrn: DEFAULT_VALUES.ogrn,
      })
    }
  }, [value, focus])

  useEffect(() => {
    setQuery(typeof value === 'object'
      ? getValue(value)
      : value
    )
  }, [value])

  const onSearch = (query) => {
    getDadata({ query, service })
      .then(r => setSuggestions(r.suggestions))
  }

  const debounceOnSearch = useCallback(
    debounce(query => {
      onSearch && onSearch(query)
    }, 300),
    [],
  )

  useEffect(() => {
    focus && query && debounceOnSearch(query)
  }, [focus, query, debounceOnSearch])

  const onFocus = () => {
    setFocus(true)
    // setQuery(typeof value === 'object'
    //   ? getValue(value)
    //   : value
    // )
  }

  const onBlur = () => {
    if (!window.getSelection().toString()) {
      setFocus(false)
      setSuggestions([])
    }
  }

  const onChange = (e) => {
    if (selected.value || selected.fullName) {
      rest.onChange && rest.onChange(e)
    }
    setQuery(e.target.value)
    debounceOnSearch(e.target.value)
  }

  const onClick = (item) => {
    const PAYLOADS = {
      address: {
        ...item.data,
        result: item.data.flat
          ? `${item.value}, ${item.data.flat}`
          : item.value,
      },
      bank: {
        bankName: item.value,
        bic: item.data.bic,
        corrNumber: item.data.correspondent_account,
        swift: item.data.swift,
      },
      party: {
        fullName: item.value,
        inn: item.data.inn,
        ogrn: item.data.ogrn,
      },
    }
    onSelect(PAYLOADS[service])
    setSelected(PAYLOADS[service])
    setQuery(item.value)
    onBlur()
  }

  const ref = useRef()

  useOutsideClick(ref, onBlur)

  const inputProps = {
    ...rest,
    value: query,
    autoCapitalize: 'off',
    autoCorrect: 'off',
    autoComplete: 'off',
    ref,
    disabled,
    onChange,
    onFocus,
    className: classnames(classes.input, { 'error': _error }),
  }

  const onChangeFlat = (e) => {
    const flat = e.target.value
    setFlat(flat)
    onSelect({ ...selected, flat })
  }

  const inputPropsFlat = {
    id: 'flat',
    name: 'flat',
    type: 'text',
    value: flat,
    autoCapitalize: 'off',
    autoCorrect: 'off',
    autoComplete: 'off',
    onChange: onChangeFlat,
    maxLength: 10,
    disabled,
    className: classes.input,
  }

  const showSuggestions = suggestions && suggestions.length > 0 && focus
  // const showLoader = isFetching && focus

  const LABELS = {
    address: <div className={classes.label}>Адрес до дома включительно (без офиса/квартиры)</div>,
    flat: <div className={classes.label}>№ кв/оф</div>,
    bank: null,
    compnay: null,
  }

  const VALUES = {
    address: <div className={classes.value} title='Адрес одной строкой'>
      {flat
        ? `${selected.result}, ${flat}`
        : selected.result
      }
    </div>,
    bank: <div className={classes.value}>
      {selected && selected.bic && selected.corrNumber
        ? `БИК: ${selected.bic}, К/С: ${formattedCard(selected.corrNumber)}`
        : 'нет информации о банке'
      }
    </div>,
    party: <div className={classes.value}>
      {selected && selected.inn && selected.ogrn
        ? `ИНН: ${selected.inn}, ОГРН: ${selected.ogrn}`
        : 'нет информации о компании'
      }
    </div>,
  }

  const renderLabel = (service) => LABELS[service]
  const renderValue = (service) => VALUES[service]

  const isFlat = service === 'address' && value

  const fullWidth = 100
  const width1 = 80
  const margin = 2
  const width2 = fullWidth - width1 - margin

  const style1 = { width: isFlat ? `${width1}%` : `${fullWidth}%` }
  const style2 = { width: `${width2}%`, marginLeft: `${margin}%` }

  return (
    <div className={classes.root}>
      {label
        ? <label>{label}</label>
        : null
      }
      <div className={classnames(classes.wrapper, { /*'error': _error,*/ disabled })}>
        <div className={classes.inputWrapper} style={style1}>
          {renderLabel(service)}
          <input {...inputProps} />
        </div>
        {isFlat &&
          <div className={classes.inputWrapper} style={style2}>
            {renderLabel('flat')}
            <input {...inputPropsFlat} />
          </div>
        }
        {renderValue(service)}
        {children}
        {showSuggestions &&
          <div className={classes.scroll}>
            <ul className={classes.list}>
              {suggestions.map((item, index) => {
                const { fullName, value } = item || {}
                return (
                  <li
                    key={index}
                    className={classes.item}
                    onClick={() => onClick(item)}
                  >
                    {fullName && <div>{fullName}</div>}
                    {value && <div>{value}</div>}
                  </li>
                )
              })}
            </ul>
          </div>
        }
      </div>
      {_error &&
        <Error>{_error}</Error>
      }
    </div>
  )
}