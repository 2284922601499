import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'

const styles = {
  root: {
    fontSize: 10,
    lineHeight: 18/10,
    fontWeight: 500,
    letterSpacing: '1px',
  },
}

const Key = ({ children, className, classes }) =>
  <div className={classnames(classes.root, className)}>{children}</div>

export default injectSheet(styles)(Key)