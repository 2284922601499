import * as actionTypes from './actionTypes'
import { onVerify } from '../../services/api/verify'

import { setErrorContent} from '../Error/actions'

export const getVerify = (token) => async dispatch => {
  try {
    dispatch({ type: actionTypes.VERIFY })
    const { isSuccess, ...response } = await onVerify(token)
    if (isSuccess) {
      dispatch({ 
        type: actionTypes.VERIFY_SUCCESS, 
        data: response.order, 
      })
    } else {
      dispatch({ 
        type: actionTypes.VERIFY_ERROR,
        error: response.error,
      })
    }
  } catch (err) {
    console.log(err)
    dispatch(setErrorContent(err.message))
    dispatch({ 
      type: actionTypes.VERIFY_ERROR,
      error: err.message,
    })
  }
}