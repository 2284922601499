import React from 'react'
import classnames from 'classnames'
import injectSheet from 'react-jss'
import Value from './../Value'
import { Icon } from './../../../layout'

const styles = {
  root: {
    paddingLeft: 34,
    color: '#9B9B9B',
		fontWeight: 600,
    position: 'relative',
    '& a': {
      color: '#22C7B5',
    },
  },
  icon: {
    width: 24,
    height: 24,
    position: 'absolute',
    left: 0,
    top: 4,
    '&.hasClick': {
      transform: 'rotate(-90deg)',
      transition: 'transform 300ms ease-in-out',
    },
    '&.open': {
      transform: 'rotate(0deg)',
    },
    '& svg': {
      fill: '#96959D',
      width: 'inherit',
      height: 'inherit',
      display: 'block',
    },
  },
}

const H2 = ({ 
  icon,
  children,
  className,
  classes,
  open,
  onClick,
}) => 
  <Value className={classnames(classes.root, className)} onClick={onClick}>
    <div className={classnames(classes.icon, icon.className, { 'hasClick': onClick, open })}>
      <Icon iconName={icon.name} />
    </div>
    {children}
  </Value>

export default injectSheet(styles)(H2)